import React, { Fragment } from 'react';
import { Truck } from 'react-feather';
import Breadcrumb from '../../common/breadcrumbv2';
import Tabla from '../../common/tabla';
import { useNavigate } from 'react-router-dom';
import tools from '../../../js/tools';
import { BtnVer, BtnEliminar } from '../../common/table_buttons'


const Default = (props) => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <Breadcrumb icon={Truck} title={'Vehículos'} parent={['vehículos']} />

            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">

                        <Tabla
                            cargando={false}
                            htmlBtnPag={
                                <button
                                    className="btn btn-purple btn-nuevo"
                                    type="button"
                                    onClick={() => { navigate('/vehiculos/nuevo') }}
                                > Nuevo vehículo
                                </button>}

                            headers={[
                                // {nombre: 'ID', texto: 'ID'},
                                {
                                    nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE', html: (d) => {
                                        return <div >
                                            {d.NOMBRE_VHC}
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'HORA INICIO', tipo: 'html', texto: 'HORA INICIO', html: (d) => {
                                        return <div >
                                            {d.HORA_INICIO_VHC}
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'HORA FIN', tipo: 'html', texto: 'HORA FIN', html: (d) => {
                                        return <div >
                                            {d.HORA_FIN_VHC}
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'CARGA MÁXIMA', tipo: 'html', texto: 'CARGA MÁXIMA', html: (d) => {
                                        return <div>
                                            {d.CAPACIDAD_VHC}
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'FECHA ALTA', tipo: 'html', texto: 'FECHA ALTA', html: (d) => {
                                        return <div>
                                            {d.FECHA_ALTA_VHC}
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'DETALLE', tipo: 'html', texto: 'DETALLE',
                                    html: (d) => {
                                        return <div>
                                            <BtnVer
                                                onClick={() => { navigate(`/vehiculos/${(d.ID_VHC)}`) }}
                                            />
                                        </div>
                                    }
                                }
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'/rt_drivin_vehiculo'}
                            // refresh={esRefresh}
                            body={{}}
                            rowsPerPage={10}
                            paginadorTop={true}
                        >
                        </Tabla>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;