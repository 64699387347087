import React, { Fragment, useState, useEffect, useRef } from 'react';
import Tools from '../../js/tools'
import notie from 'notie'
import { Box } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom';
import SelectFetch from '../common/select-fetch';
import SelectBasic from '../common/select-basic';
import LoaderSeccion from '../../components/common/loader_secciones';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, AccessibilityHelp, Alignment, Autoformat, AutoImage, AutoLink, Autosave, Bold, Essentials, FindAndReplace, Heading, ImageBlock, ImageCaption, ImageInline, ImageInsert,	ImageInsertViaUrl, ImageResize,	ImageStyle, ImageTextAlternative, ImageToolbar, ImageUpload, Indent, IndentBlock, Italic, Link, List, ListProperties, Paragraph, PasteFromOffice, SelectAll, SimpleUploadAdapter, SpecialCharacters, Strikethrough, Table, TableCaption, TableCellProperties, TableColumnResize, TableProperties, TableToolbar,	TextTransformation, Underline, Undo } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import '../../assets/css/custom-ckeditor.css';

const Default = ({ esEditar }) => {
    const editorContainerRef = useRef(null);
	const editorRef = useRef(null);
	const [isLayoutReady, setIsLayoutReady] = useState(false);
    const [editorData, setEditorData] = useState('');

    // const [selectSuc, setSelectSuc] = useState([])
    const [docs, setDocs] = useState()
    const navigate = useNavigate()
    const params = useParams();
    const [loadedData, setLoadedData] = useState('')
    const [fetchValue, setfetchValue] = useState('')
    const [selectedValue, setSelectedValue] = useState(loadedData ? { value: loadedData.clave, label: `${loadedData.label}` } : '')

    const plantilla = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Factura plantilla</title>

    <!-- <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"> -->
    <style>
        * {
            font-family: "Montserrat", sans-serif;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }

        html {}

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: #000;
        }

        p {
            font-size: 20px;
            color: #000;
            line-height: 125%;
        }

        .bold {
            font-weight: 600;
            color: #000;
        }

        .mb-1 {
            margin-bottom: 4px;
        }

        .mb-2 {
            margin-bottom: 6px;
        }

        .mb-3 {
            margin-bottom: 8px;
        }

        .mb-4 {
            margin-bottom: 10px;
        }

        .mb-5 {
            margin-bottom: 12px;
        }

        .container {
            max-width: 1275px;
            /* border: 1px solid black;*/
            height: auto;

        }

        .section-header,
        .section-body,
        .section-footer {
            padding: 10px 10px;
        }

        .group-col {
            display: flex;
            flex-direction: row;
        }

        .divider {
            background-color: #101010;
            height: 6px;
            width: 100%;
            margin-top: 6px;
            margin-bottom: 6px;
        }

        .divider-2 {
            height: 2px;
            background-color: #000;
            width: 100%;
            opacity: 0.1;
            margin-bottom: 4px;
        }

        .list {
            list-style: none;
            padding-left: 0;
            /* 
            margin-top: 8px;
            margin-bottom: 8px; */
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
        }

        .text-start {
            text-align: start;
        }

        .text-center {
            text-align: center;
        }

        .text-end {
            text-align: end;
        }

        .list li {
            margin-bottom: 6px;
        }

        .list.div-1 li {
            width: 100%;
        }

        .list.div-2 li {
            width: 48.999999%;
        }

        .col-1,
        .col-2,
        .col-3,
        .col-4,
        .col-5,
        .col-6,
        .col-7,
        .col-8,
        .col-9,
        .col-10,
        .col-11,
        .col-12 {
            padding: 10px;
        }

        .row {
            display: flex;
            flex-wrap: wrap;
        }

        .col-1 {
            width: 8.33333333%;
        }

        .col-2 {
            width: 16.66666667%;
        }

        .col-3 {
            width: 25%;
        }

        .col-4 {
            width: 33.33333333%;
        }

        .col-5 {
            width: 41.66666667%;
        }

        .col-6 {
            width: 50%;
        }

        .col-7 {
            width: 58.33333333%;
        }

        .col-8 {
            width: 66.66666667%;
        }

        .col-9 {
            width: 75%;
        }

        .col-10 {
            width: 83.33333333%;
        }

        .col-11 {
            width: 91.66666667%;
        }

        .col-12 {
            width: 100%;
        }

        .w-100 {
            width: 100%;
        }

        .table {
            width: 100%;
            /* border: 1px solid #000000; */
            border-collapse: collapse;
        }

        td,
        th {
            vertical-align: middle;
            text-align: start;
        }

        th {
            width: auto;
        }

        .table>thead {
            vertical-align: bottom;
        }

        .table>tbody {
            vertical-align: inherit;
        }

        td p,
        th p {
            font-size: 10px;
            /* width: max-content; */
        }

        .table>thead tr th {
            background-color: #3f3f3f;
            padding: 10px;
            border-right: 1px solid #cecece;
            /*#9040BD*/
        }

        .table>tbody tr td {
            border-left: 1px solid #cecece;
            border-right: 1px solid #cecece;
            border-bottom: 1px solid #cecece;
        }

        .table>thead tr th:last-child,
        .table>tbody tr td:last-child {
            /* border-right: none; */
        }


        .table>tbody tr td {
            padding: 10px;
        }

        .table>thead tr th p {
            color: #fff;
        }

        tbody,
        td,
        tfoot,
        th,
        thead,
        tr {
            border-color: inherit;
            border-style: solid;
            border-width: 0;
        }

        /* 

        td>p{
            width:100px;
        } */

        .amount {
            text-align: end;
        }

        .amount.bold {
            font-weight: 600;
            font-size: 16px !important;
            margin-top: 4px;
        }

        .observations div {
            border-bottom: 1px solid #000000;
            height: 26px;
        }

        .td-text {
            text-align: start;
        }

        .td-num {
            text-align: end;
        }

        .tr-2 {
            background-color: #c6c6c6;
        }

        @media print {

            /* @page {
                    margin:0.6cm;
                { */
            @page {
                size: A4;
                /* DIN A4 standard, Europe */
                margin: 0.2cm;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            .divider,
            .divider-2,
            .table>thead tr th,
            .table>thead tr th p,
            .tr-2 {
                print-color-adjust: exact;
                -webkit-print-color-adjust: exact;
            }

            p {
                font-size: 14px;
            }

            .list li {
                margin-bottom: 2px;
            }

            .paginator {
                margin-top: 100px;
            }

            /* Salto de página */

            .break {
                page-break-before: always;
            }

            .pagination {}

            .section-body {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                /* height: 100vh; */
            }

            .section-body .content {
                height: auto;
            }

            .row.pagination {

                align-items: end !important;
            }
        }
    </style>

    <style media="print">
    </style>
</head>

<body>
    <div class="container" style="height: 100%;">
        <div class="section-header">
            <div class="row">
                <div class="col-6" style="
                display: flex;
                flex-direction: column;
                justify-content: center;">
                    <h1 id="nombre_plantilla">${esEditar ? Tools.capitalizarPrimeraLetra(Tools.urlClear(params.plantilla)) : selectedValue.label ? selectedValue.label : ''}</h1>
                </div>
                <div class="col-6" style="display: flex;
                flex-direction: column;
                align-items: end;">
                    <img src="https://gppack.mx/assets/img/logo-gppack.png"
                        style="height: 60px; margin-bottom: 10px;" alt="">
                    <div>
                        <ul class="list div-1">
                            <li>
                                <p><span class="bold">Fecha:</span> <span id="fecha_plantilla"> ${Tools.formatFechaLargaCompleta()}</span></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-body">
            <div class="content">
                <div class="row">
                    <div class="col-12">
                        <p class="mb-3"><span class="bold">GPPACK Reynosa</span> - Blvd.Las Fuentes 802, Aztlán, C.P. 88740.</p>
                        <br><br>
                    </div>
                    <div class="col-12" id="plantilla">
                        ${editorData}
                    </div>
                </div>
            </div>
        </div>

        <div class="section-body">
            <div class="content">
                <div class="row">
                    <div class="col-4" style="text-align: center;">
                        
                    </div>
                    <div class="col-4" style="text-align: center; font-size: 25px">
                        <h5>Nombre y Firma del remitente</h5>
                        <div style="border-bottom: 1px solid #000; height: 60px;"></div>
                    </div>
                    <div class="col-4" style="text-align: center;">
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

</html>`

	useEffect(() => {
		setIsLayoutReady(true);

		return () => setIsLayoutReady(false);
	}, []);

	const editorConfig = {
		toolbar: {
			items: [ 'undo', 'redo', '|', 'heading', '|', 'bold', 'italic', 'underline', '|', 'link', 'insertImage', 'insertTable', '|', 'alignment', '|', 'bulletedList', 'numberedList', 'outdent', 'indent' ],
			shouldNotGroupWhenFull: true
		},
		plugins: [ AccessibilityHelp, Alignment, Autoformat, AutoImage, AutoLink, Autosave, Bold, Essentials, FindAndReplace, Heading, ImageBlock, ImageCaption, ImageInline, ImageInsert, ImageInsertViaUrl, ImageResize, ImageStyle, ImageTextAlternative, ImageToolbar, ImageUpload, Indent, IndentBlock, Italic, Link, List, ListProperties, Paragraph,  PasteFromOffice, SelectAll, SimpleUploadAdapter, SpecialCharacters, Strikethrough, Table, TableCaption, TableCellProperties, TableColumnResize, TableProperties, TableToolbar, TextTransformation, Underline, Undo ],
		heading: {
			options: [
				{
					model: 'paragraph',
					title: 'Paragraph',
					class: 'ck-heading_paragraph'
				},
				{
					model: 'heading1',
					view: 'h1',
					title: 'Heading 1',
					class: 'ck-heading_heading1'
				},
				{
					model: 'heading2',
					view: 'h2',
					title: 'Heading 2',
					class: 'ck-heading_heading2'
				},
				{
					model: 'heading3',
					view: 'h3',
					title: 'Heading 3',
					class: 'ck-heading_heading3'
				},
				{
					model: 'heading4',
					view: 'h4',
					title: 'Heading 4',
					class: 'ck-heading_heading4'
				},
				{
					model: 'heading5',
					view: 'h5',
					title: 'Heading 5',
					class: 'ck-heading_heading5'
				},
				{
					model: 'heading6',
					view: 'h6',
					title: 'Heading 6',
					class: 'ck-heading_heading6'
				}
			]
		},
		image: {
			toolbar: [ 'toggleImageCaption', 'imageTextAlternative', '|', 'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', '|', 'resizeImage' ]
		},
		link: {
			addTargetToExternalLinks: true,
			defaultProtocol: 'https://',
			decorators: {
				toggleDownloadable: {
					mode: 'manual',
					label: 'Downloadable',
					attributes: {
						download: 'file'
					}
				}
			}
		},
		list: {
			properties: {
				styles: true,
				startIndex: true,
				reversed: true
			}
		},
		menuBar: {
			isVisible: true
		},
		placeholder: 'Ingresa los datos de tu plantilla aquí',
		table: {
			contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
		}
	};

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
    };

    const enviarDatosFormulario = () => {
        let datos = Tools.getForm('formPlantilla', { claveEtiquetas: '.txtVacios', ignore: []});
        console.log(datos);
        
        
        if(datos.formIsEmpty > 0){
            notie.alert({text: `El campo ${datos.labelIsEmpty[datos.keys[0]]} es requerido`, type: 3, time: 4});
            return;
        }

        fetch('../rt_plantillas_nuevo', {
            method: 'POST',
            body: JSON.stringify(datos.data),
            headers: {'Content-Type': 'application/json'}
        }).then((res) => res.json())
        .then((res) => {
            console.log(res);
            if(res.estatus === 'OK'){
                if(res.select.RESULT == 'TIPO EXISTENTE' && res.doc.RESULTADO == 'EXISTENTE'){
                    notie.alert({text: 'Esta plantilla ya ha sido agregada con anterioridad.', type: 'error', time: 4})

                }else if(res.select.RESULT == 'TIPO ACTUALIZADO' && res.doc.RESULTADO == 'AGREGADO'){
                    notie.alert({text: 'La plantilla fue agregada correctamente.', type: 'success', time: 4})
                    setTimeout(() => {
                        navigate('/plantillas')
                    }, 4000);
                }else if(res.select.RESULT == 'TIPO AGREGADO' && res.doc.RESULTADO == 'EXISTENTE'){
                    notie.alert({text: 'Esta plantilla ya ha sido agregada con anterioridad.', type: 'error', time: 4})
                }else if(res.select.RESULT == 'TIPO AGREGADO' && res.doc.RESULTADO == 'AGREGADO'){
                    notie.alert({text: 'La plantilla fue agregada correctamente.', type: 'success', time: 4})
                    setTimeout(() => {
                        navigate('/plantillas')
                    }, 4000);
                }
            }else{
                throw Error(res.estatus);
            }
        }).catch(function(err){
            notie.alert({text: 'No se pudo agregar, inténtelo más tarde', type: 3, time: 5});
        })
    }

    const editarDoc = () => {
        let datos = Tools.getForm('formPlantilla', { claveEtiquetas: '.txtVacios', ignore: []});
        
        if(datos.formIsEmpty > 0){
            notie.alert({text: `El campo ${datos.labelIsEmpty[datos.keys[0]]} es requerido`, type: 3, time: 4});
            return;
        }

        fetch('../rt_plantillas_editar', {
            method: 'POST',
            body: JSON.stringify(datos.data),
            headers: {'Content-Type': 'application/json'}
        }).then((res) => res.json())
        .then((res) => {
            console.log(res);
            if(res.estatus === 'OK'){
                if(res.result.RESULTADO == 'ACTUALIZADO'){
                    notie.alert({text: 'La plantilla fue actualizada correctamente.', type: 'success', time: 4})
                    setTimeout(() => {
                        navigate('/plantillas')
                    }, 4000);
                }else{
                    notie.alert({text: 'No fue posible actualizar la plantilla.', type: 'error', time: 4})
                }
            }else{
                throw Error(res.estatus);
            }
        }).catch(function(err){
            notie.alert({text: 'No se pudo agregar, inténtelo más tarde', type: 3, time: 5});
        })
    }

    useEffect(() => {

        Tools.toolFetch({
            ruta: 'rt_ctg_docs',
            method: 'POST',
            body: {},
        }, (response) => {
            setDocs(response.datos)
        })

        if (esEditar) {
            return Tools.toolFetch({
                ruta: 'rt_plantillas_detalle',
                method: 'POST',
                body: { plantilla: params.plantilla || ''},
            }, (response) => {
                setLoadedData(response.result)

                if (response.estatus === 'ERROR') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }
                // setSelectSuc(datos_suc)
                // setDataLoaded(true)
            })
        }

        // return Promise.all([
        //     fetch('/rt_ctg_gpo', {
        //         method: 'POST',
        //         headers: { "Content-Type": 'application/json' },
        //         body: JSON.stringify({})
        //     }),
        //     fetch('/rt_ctg_docs', {
        //         method: 'POST',
        //         headers: { "Content-Type": 'application/json' },
        //         body: JSON.stringify({})
        //     }),
        // ])
        // .then(async (response) => {
        //     const ctgGpo = await response[0].json()
        //     const ctgDocs = await response[1].json()

        //     setCatalogos(ctgGpo.datos)
        //     setDocs(ctgDocs.datos.ctgDocs)

        //     if (esEditar) {
        //         return Tools.toolFetch({
        //             ruta: 'rt_empresas_df_detalle',
        //             method: 'POST',
        //             body: { empresa: params.empresa || '', aliasDf: params.aliasDf},
        //         }, (response) => {
        //             let datos = response.datos
        //             let datos_suc = response.datos_suc
    
        //             if (response.estatus === 'ERROR') {
        //                 return notie.alert({
        //                     type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        //                     text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
        //                     // stay: Boolean, // optional, default = false
        //                     time: 2, // optional, default = 3, minimum = 1,
        //                     position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        //                 })
        //             }
        //             setSelectSuc(datos_suc)
        //             setDataLoaded(true)
        //         })
        //     }
        // })
    },[])

    return (
        <Fragment>
            {esEditar?<LoaderSeccion status={docs}/>:''}
            <Breadcrumb icon={Box} title={'Plantillas'} parent={['plantillas']} />
            <div className="container-fluid">
                <div className='row'>
                    <form id='formPlantilla' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className="btn btn-regresar" type="button"
                                    onClick={(e) => {
                                        navigate('/plantillas')
                                    }} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVA'} PLANTILLA</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Datos generales de la plantilla</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Tipo de plantilla<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            { esEditar? 
                                                <SelectBasic
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Plantillas"
                                                    placeholder="Seleccionar plantilla"
                                                    id="list_docs"
                                                    name="list_docs"
                                                    tipoValue={1}
                                                    value={loadedData ? loadedData.clave : null}
                                                    datos={docs}
                                                />
                                            :
                                                <SelectFetch
                                                    name={'list_docs'}
                                                    id={'list_docs'}
                                                    ruta='rt_ctg_docs'
                                                    label={'Documentos'}
                                                    clave_cps={docs ? (docs.clave + '-' + docs.label) : ''}
                                                    value={loadedData ? { value: loadedData.clave, label: `${loadedData.label}` } : null}
                                                    onValueChange={(value) => setSelectedValue(value)}
                                                />
                                            }
                                        </div>
                                        <span className="text-danger txtVacios list_docs" style={{ display: 'none' }}><strong>Falta completar (Cargar datos previos)</strong></span>
                                    </div>
                                    {/* <div className="col-md-6 mb-3">
                                        <div className='row'>
                                            <div className="col-md-12 mb-3">
                                                <label className="form-label">Sucursal (Origen)<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <SelectSimple
                                                        id={'list_suc'}
                                                        name={'list_suc'}
                                                        isMulti={true}
                                                        options={catalogos.ctgSuc}
                                                        value={selectSuc}
                                                        onChange={(datos) => {
                                                            setSelectSuc(datos)
                                                        }}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios list_suc" style={{ display: 'none' }}><strong>Falta completar (Sucursal (Origen))</strong></span>
                                            </div>
                                        </div>
                                    </div> */} 
                                </div>
                                <div className='row mb-3'>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Descripción<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <CKEditor data={loadedData ? loadedData.PLANTILLA : editorData} editor={ClassicEditor} config={editorConfig} className={'input-group input-gpack'} style={{backgroundColor: 'red'}} onChange={handleEditorChange} />
                                            <input id='txt_desc' name="txt_desc" value={editorData} hidden />
                                        </div>
                                        <span className="text-danger txtVacios txt_desc" style={{ display: 'none' }}><strong>Falta completar (Descripción)</strong></span>
                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <iframe
                                            title="Webview"
                                            loading="lazy"
                                            style={{ width: "100%", height: '98%', marginTop: 20 }}
                                            srcDoc={plantilla}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            <button
                                                className="btn btn-purple d-flex align-items-center"
                                                type="button"
                                                onClick={(e) => { esEditar ? editarDoc() : enviarDatosFormulario() }}
                                            > { esEditar ? 'Editar documento' : 'Guardar documento'} </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default