import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Box } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom';
import Dropzone from '../common/dropzone';
import SelectFetch from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import LoaderSeccion from '../../components/common/loader_secciones';

const Default = ({ esEditar }) => {
    const [catalogos, setCatalogos] = useState({})
    const [selectSuc, setSelectSuc] = useState([])
    const [prodServ, setProdServ] = useState(null)
    const [selectCostoExtra, setSelectCostoExtra] = useState([])

    const [headers, setHeaders] = useState([])
    const [selectPrecios, setSelectPrecios] = useState([])
    const [headersSuc, setHeadersSuc] = useState([])
    const [selectPreciosSuc, setSelectPreciosSuc] = useState([])
    const [zonas, setZonas] = useState([])
    const [zonasSuc, setZonasSuc] = useState([])
    const [tipoServ, setTipoServ] = useState({})
    const [tiposParametros, setTiposParametros] = useState([])
    const [rows, setRows] = useState([])
    const [refreshPrimeraCarga, setRefreshPrimeraCarga] = useState(true)
    const [img, setImg] = useState({})
    const navigate = useNavigate()
    const params = useParams()

    const uploadImg = (img, datos) => {
        let arrImg = []

        if (img.imgPortada) {
            arrImg = [...arrImg, { img: img.imgPortada, nombre: 1 }]
        }

        if (img.imgDetalle) {
            arrImg.push({ img: img.imgDetalle, nombre: 2 })
        }

        let length = arrImg.length

        let arrRes = [], inputs = {
            ruta: `/rt_upload_img_prod_serv`,
            arr: arrImg,
            index: 0,
            id: datos.ID_PS
        }

        let callback = (datos) => {
            if (datos.index <= length - 1) {
                arrRes.push(datos)
                Tools.subirImg({
                    ...inputs,
                    arr: arrImg,
                    index: datos.index
                }, callback)
            } else {
                if (!esEditar) {
                    setTimeout(() => {
                        navigate('/servicios')
                    }, 3000)
                }

                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: 'Registro realizado exitosamente.',
                    stay: false, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
        }

        Tools.subirImg(inputs, callback)
    }

    const uploadImgTpv = (img, datos) => {
        let arrImg = []

        if (img.imgPortada) {
            arrImg = [...arrImg, { img: img.imgPortada, nombre: 1 }]
        }

        if (img.imgDetalle) {
            arrImg.push({ img: img.imgDetalle, nombre: 2 })
        }

        let length = arrImg.length
        let arrRes = [], inputs = {
            ruta: `${datos.URL_PRO}/rt_upload_img_prod_serv`,
            arr: arrImg,
            index: 0,
            id: datos.ID_PS
        }

        let callback = (datos) => {
            if (datos.index <= length - 1) {
                arrRes.push(datos)
                Tools.subirImg({
                    ...inputs,
                    arr: arrImg,
                    index: datos.index
                }, callback)
            } else {
                if (!esEditar) {
                    setTimeout(() => {
                        navigate('/servicios')
                    }, 3000)
                }

                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: 'Registro realizado exitosamente.',
                    stay: false, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
        }

        Tools.subirImg(inputs, callback)
    }

    const guardar = () => {
        const _prodServ = Tools.getForm('formProdServ', { claveEtiquetas: '.txtVacios', ignore: ['list_costo_extra'] })

        if (_prodServ.formIsEmpty || (!img.imgPortada && !esEditar)) {
            let mensajeP = ''
            if (!img.imgPortada && !esEditar) {
                mensajeP = `${_prodServ.formIsEmpty ? `, ` : ''}Portada`
                setImg((s) => ({ ...s, statusPortada: 'removed', imgPortada: null }))
            }
            let mensaje = `${_prodServ.formIsEmpty ? _prodServ.stringIsEmpty : ''}${mensajeP}`
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${mensaje})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        // if(tipoServ.clave == 1){
        //     if(!(selectPrecios.length === ((headers.length - 1) * (zonas.length - 1)))){
        //         return notie.alert({
        //             type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        //             text: `Falta completar la tabla de precios`,
        //             // stay: Boolean, // optional, default = false
        //             time: 3, // optional, default = 3, minimum = 1,
        //             position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        //         }) 
        //     }
        // }
        Tools.toolFetch({
            ruta: esEditar ? 'rt_prod_serv_editar' : 'rt_prod_serv_nuevo',
            method: 'POST',
            body: {
                selectPrecios: [...selectPrecios, ...selectPreciosSuc],
                selectSuc: selectSuc.map((s) => ({ value: s.value })),
                selectCostoExtra,
                ...(prodServ ? { id_ps: prodServ.ID_PS } : {}),
                ..._prodServ.data,
            },
        }, (response) => {
            if (response.estatus === 'ERROR-PRECIO-0') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El precio mínimo que el producto <strong>${_prodServ.data.txt_nombre}</strong> puede tener es de $0.01`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            if (response.estatus === 'EXISTE') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Producto <strong>${_prodServ.data.txt_nombre}</strong> existente intente con otro nombre diferente`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (!esEditar) {
                uploadImg(img, response.datos)
                uploadImgTpv(img, response.datos)
            } else {
                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: 'Registro realizado exitosamente.',
                    stay: false, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
        })
    }

    const editarProdServ = () => {
        const _prodServ = Tools.getForm('formProdServ', { claveEtiquetas: '.txtVacios', ignore: ['list_costo_extra', 'list_suc'] })

        if (_prodServ.formIsEmpty) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_prodServ.stringIsEmpty})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        Tools.toolFetch({
            ruta: 'rt_prod_serv_editar',
            method: 'POST',
            body: {
                selectPrecios: [...selectPrecios, ...selectPreciosSuc],
                selectSuc: selectSuc.map((s) => ({ value: s.value })),
                selectCostoExtra,
                ...(prodServ ? { id_ps: prodServ.ID_PS } : {}),
                ..._prodServ.data,
            },
        }, (response) => {
            if (response.estatus === 'EXISTE') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Producto <strong>${_prodServ.data.txt_nombre}</strong> existente intente con otro nombre diferente`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (!esEditar) {
                uploadImg(img, response.datos)
                uploadImgTpv(img, response.datos)
            } else {
                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: 'Registro realizado exitosamente.',
                    stay: false, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
        })
    }

    const guardarPrecios = () => {
        if (selectSuc.length == 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (Sucursal)`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if(selectPrecios.length == 0 && selectPreciosSuc.length == 0){
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Para guardar los precios debes agregar por lo menos un precio por Sucursal o por Zonas`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        Tools.toolFetch({
            ruta: 'rt_prod_serv_precios_suc',
            method: 'POST',
            body: {
                selectPrecios: [...selectPrecios, ...selectPreciosSuc],
                selectSuc: selectSuc.map((s) => ({ value: s.value })),
                list_tipo: tipoServ.clave,
                id_ps: prodServ.ID_PS,
            },
        }, (response) => {
            setRefreshPrimeraCarga((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: 'Precios actualizados correctamente.',
                stay: false, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const guardarSucursales = () => {
        const _prodServ = Tools.getForm('formProdServ', {
            claveEtiquetas: '.txtVacios',
            ignore: ['list_grupo', 'list_prod_serv', 'list_suc', 'list_tipo', 'txt_desc', 'txt_nombre'],
        })

        if (_prodServ.formIsEmpty) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_prodServ.stringIsEmpty})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (!selectSuc.length) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar seleccionar (Sucursales)`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        Tools.toolFetch({
            ruta: 'rt_prod_serv_suc_editar',
            method: 'POST',
            body: {
                selectSuc,
                list_tipo: tipoServ.clave,
                id_ps: prodServ.ID_PS,
                ..._prodServ.data
            },
        }, (response) => {
            if (response.estatus === 'ERROR-PRECIO-0') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El precio mínimo que el producto <strong>${_prodServ.data.txt_nombre}</strong> puede tener es de $0.01`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            setRefreshPrimeraCarga((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: 'Se actualizaron correctamente las sucursales.',
                stay: false, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const guardarImg = (tipoImg) => {
        if (tipoImg === 1 && !img.imgPortada && esEditar) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (Portada)`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (tipoImg === 2 && !img.imgDetalle && esEditar) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (Portada)`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (tipoImg === 1) {
            uploadImg({ imgPortada: img.imgPortada }, prodServ)
            // uploadImgTpv({ imgPortada: img.imgPortada }, prodServ)
        } else {
            uploadImg({ imgDetalle: img.imgDetalle }, prodServ)
            // uploadImgTpv({ imgDetalle: img.imgDetalle }, prodServ)
        }
    }

    const renderHeader = (zonas, selectPrecios, setSelectPrecios) => {
        return [
            {
                tipo: 'html',
                nombre: 'RANGO',
                clave: 'RANGO',
                style: { padding: 2 },
                html: (d) => {
                    return <div>
                        {d.ES_EXTRA_PP ?
                            <Fragment>
                                {d.ES_RANGO_PP ?
                                    <Fragment>
                                        Ext. [<strong style={{ color: 'black' }}>{d.VALOR_MINIMO_PP}</strong>,
                                        <strong style={{ color: 'black' }}>{d.VALOR_MAXIMO_PP}</strong>] <strong>+</strong>
                                    </Fragment>
                                    : <Fragment>
                                        Ext. <strong style={{ color: 'black' }}>{d.VALOR_MAXIMO_PP}+</strong>
                                    </Fragment>}
                            </Fragment>
                            : <Fragment>
                                [<strong style={{ color: 'black' }}>{d.VALOR_MINIMO_PP}</strong>,
                                <strong style={{ color: 'black' }}>{d.VALOR_MAXIMO_PP}</strong>]
                            </Fragment>}
                    </div>
                }
            },
            ...zonas.map((zonas) => ({
                tipo: 'html',
                nombre: 'Zona ' + zonas.NOMBRE_CZ,
                clave: 'ID_PP_PC',
                style: { padding: 2 },
                html: (rango) => {
                    if (!selectPrecios) {
                        return
                    }

                    let selectItem = selectPrecios.find((f) => f.zona === zonas.ID_CZ && f.precioParam === rango.ID_PP && f.idSuc == rango.ID_SUC)

                    return <NumericFormat
                        className="form-control text-end"
                        thousandSeparator=","
                        placeholder='0.00'
                        autoComplete='off'
                        value={selectItem ? selectItem.precio : 0}
                        onValueChange={(e) => {
                            let item = { precio: e.floatValue, precioParam: rango.ID_PP, zona: zonas.ID_CZ, idSuc: rango.ID_SUC || 0 }
                            if (selectPrecios.find((f) => f.precioParam === item.precioParam && f.zona === item.zona && f.idSuc === item.idSuc)) {
                                setSelectPrecios((s) => s.map((g) => g.precioParam === item.precioParam && g.zona === item.zona && g.idSuc === item.idSuc ? ({ id: g.id, ...item }) : g))
                            } else {
                                setSelectPrecios((s) => ([...s, item]))
                            }
                        }}
                    />
                }
            }))
        ]
    }

    useEffect(() => {
        setHeaders(renderHeader(zonas, selectPrecios, setSelectPrecios))
    }, [selectPrecios])

    useEffect(() => {
        setHeadersSuc(renderHeader(zonasSuc, selectPreciosSuc, setSelectPreciosSuc))
    }, [selectPreciosSuc])

    useEffect(() => {
        let isSubscribed = true

        if (esEditar) {
            return Promise.all([
                fetch('/rt_prod_serv_detalle', {
                    method: 'POST',
                    headers: { "Content-Type": 'application/json' },
                    body: JSON.stringify({ nombreProdServ: params.id })
                }),
                fetch('/rt_ctg_gpo', {
                    method: 'POST',
                    headers: { "Content-Type": 'application/json' },
                    body: JSON.stringify({})
                }),
            ])
                .then(async (response) => {
                    const detalle = await response[0].json()
                    const ctgGpo = await response[1].json()

                    // Zonas
                    const zonas = ctgGpo.datos.zonas
                    const zonasSuc = ctgGpo.datos.zonasSuc

                    // Parametros precios
                    const tiposParametros = ctgGpo.datos.tiposParametros
                    const parametroPrecios = ctgGpo.datos.parametroPrecios

                    if (!isSubscribed) {
                        return
                    }

                    setHeaders(renderHeader(zonas))
                    setHeadersSuc(renderHeader(zonasSuc))

                    setZonas(zonas)
                    setZonasSuc(zonasSuc)
                    setRows(parametroPrecios)

                    setTiposParametros(tiposParametros)

                    const datos = ctgGpo.datos
                    setCatalogos((s) => ({ ...s, ...datos }))

                    // DETALLE
                    setTipoServ({ clave: detalle.datos.prodServ.TIPO_PS })
                    setSelectPrecios(detalle.datos.precios.map((v) => ({ id: v.ID_PS_PC, precio: v.PRECIO_PC, precioParam: v.ID_PP_PC, zona: v.ID_CZ_PC, idSuc: v.ID_SUC_ORIGEN_PC })) || [])
                    setSelectPreciosSuc(detalle.datos.preciosEnvioSuc.map((v) => ({ id: v.ID_PS_PC, precio: v.PRECIO_PC, precioParam: v.ID_PP_PC, zona: v.ID_CZ_PC, idSuc: v.ID_SUC_ORIGEN_PC })) || [])
                    setProdServ(detalle.datos.prodServ)

                    const preciosSucursales = detalle.datos.preciosSuc.map((v) => ({ value: v.ID_SUC_PRE, label: v.NOMBRE_SUC, precio: v.PRECIO_PRE }))
                    setSelectSuc(preciosSucursales)

                    setSelectCostoExtra(detalle.datos.costoExtra)
                })
        }

        Tools.toolFetch({
            ruta: 'rt_ctg_gpo',
            method: 'POST',
            body: {},
        }, (response) => {
            if (isSubscribed) {
                if (response.estatus === 'ERROR') {
                    return navigate('/clientes')
                }

                const datos = response.datos
                const zonas = datos.zonas
                const zonasSuc = datos.zonasSuc

                // Parametros precios
                const tiposParametros = datos.tiposParametros
                const parametroPrecios = datos.parametroPrecios

                setTiposParametros(tiposParametros)
                setHeaders(renderHeader(zonas, selectPrecios, setSelectPrecios))
                setHeadersSuc(renderHeader(zonasSuc, selectPreciosSuc, setSelectPreciosSuc))

                setZonas(zonas)
                setZonasSuc(zonasSuc)
                setRows(parametroPrecios)
                setCatalogos((s) => ({ ...s, ...datos }))
            }
        })

        return () => isSubscribed = false
    }, [refreshPrimeraCarga])

    return (
        <Fragment>
            {esEditar?<LoaderSeccion status={prodServ}/>:''}
            <Breadcrumb icon={Box} title={'Producto Servicios'} parent={['servicios']} />
            <div className="container-fluid">
                <div className='row'>
                    <form id='formProdServ' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className="btn btn-regresar" type="button"
                                    onClick={(e) => {
                                        navigate('/servicios')
                                    }} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVO'} PRODUCTO SERVICIO</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Datos generales del producto servicio</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Nombre<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control"
                                                autoComplete='off'
                                                id="txt_nombre" name="txt_nombre"
                                                type="text"
                                                placeholder="Nombre"
                                                defaultValue={prodServ ? prodServ.NOMBRE_PS : ''}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Tipo<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Tipo"
                                                placeholder="Seleccionar tipo"
                                                id="list_tipo"
                                                name="list_tipo"
                                                tipoValue={1}
                                                value={prodServ ? prodServ.TIPO_PS : ''}
                                                datos={catalogos.ctgTipos}
                                                onChange={(e) => {
                                                    setTipoServ(e.item)
                                                }}
                                            />
                                        </div>

                                        <span className="text-danger txtVacios list_tipo" style={{ display: 'none' }}><strong>Falta completar (Tipo)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Claves unidades<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Claves unidades"
                                                placeholder="Seleccionar clave unidad"
                                                id="list_clave_ud"
                                                name="list_clave_ud"
                                                value={prodServ ? prodServ.CLAVE_CU_PS : ''}
                                                tipoValue={1}
                                                datos={catalogos.ctgClavesUds}
                                                onChange={() => null}
                                            />
                                        </div>

                                        <span className="text-danger txtVacios list_clave_ud" style={{ display: 'none' }}><strong>Falta completar (Claves unidades)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Grupo<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Grupo"
                                                placeholder="Seleccionar Grupo"
                                                id="list_grupo"
                                                name="list_grupo"
                                                value={prodServ ? prodServ.ID_GPO_PS : ''}
                                                tipoValue={1}
                                                datos={catalogos.ctgGpo}
                                                onChange={() => null}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios list_grupo" style={{ display: 'none' }}><strong>Falta completar (Grupo)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Clave Producto Servicio<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <SelectFetch
                                                id={'list_prod_serv'}
                                                name={'list_prod_serv'}
                                                ruta='rt_ctg_prod_serv'
                                                label={'Producto Servicio'}
                                                clave_cps={prodServ ? prodServ.CLAVE_CPS : ''}
                                                value={prodServ ? { value: prodServ.CLAVE_CPS, label: `${prodServ.CLAVE_CPS} ${prodServ.PROD_SERV_TEXT}` } : null}
                                            />
                                        </div>

                                        <span className="text-danger txtVacios list_prod_serv" style={{ display: 'none' }}><strong>Falta completar (Clave Producto Servicio)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        {tipoServ.clave == 1?
                                            <Fragment>
                                                <div className="col-md-12 mb-3">
                                                    <label className="form-label">Costo extra</label>
                                                    <div className="input-group input-gpack2">
                                                        <SelectSimple
                                                            id={'list_costo_extra'}
                                                            name={'list_costo_extra'}
                                                            isMulti={true}
                                                            options={catalogos.ctgCostoExtra}
                                                            value={selectCostoExtra}
                                                            onChange={(datos) => {
                                                                setSelectCostoExtra(datos)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Fragment> : ''}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">Descripción<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <textarea style={{ width: '100%' }} rows={5} id='txt_desc' name='txt_desc' placeholder='Descripción' defaultValue={prodServ ? prodServ.DESCRIPCION_PS : ''}></textarea>
                                        </div>
                                        <span className="text-danger txtVacios txt_desc" style={{ display: 'none' }}><strong>Falta completar (Descripción)</strong></span>
                                    </div>
                                    {esEditar ? 
                                    <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            <button
                                                className="btn btn-purple d-flex align-items-center"
                                                type="button"
                                                onClick={(e) => editarProdServ()}
                                            >Guardar Producto Servicio</button>
                                        </div>
                                    </div>
                                    : ''}
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Imagenes que seran mostradas en TPV y Sitio</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Portada<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <div className="input-group-prepend"></div>
                                            <Dropzone
                                                initialFiles={prodServ ? '/pro-serv-img/' + prodServ.ID_PS + '/' + prodServ.IMG_PORTADA_URL : null}
                                                fileName={prodServ ? prodServ.IMG_PORTADA_PS : ''}
                                                onImg={(datos) => { setImg((s) => ({ ...s, statusPortada: datos.status, imgPortada: datos.file })) }}
                                            />
                                        </div>
                                        <span className="text-danger" style={{ display: img.statusPortada === 'removed' ? '' : 'none' }}><strong>Falta completar (Portada)</strong></span>
                                        {esEditar ?
                                            <div className="justify-content-end d-flex mt-2">
                                                <button className="btn btn-purple d-flex align-items-center" type="button"
                                                    onClick={(e) => guardarImg(1)} >Guardar Portada</button>
                                            </div>
                                            : ''}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Detalle (Opcional)</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend"></div>
                                            <Dropzone
                                                initialFiles={prodServ ? '/pro-serv-img/' + prodServ.ID_PS + '/' + prodServ.IMG_DETALLE_URL : null}
                                                fileName={prodServ ? prodServ.IMG_DETALLE_PS : ''}
                                                onImg={(datos) => { setImg((s) => ({ ...s, statusDatelle: datos.status, imgDetalle: datos.file })) }}
                                            />
                                        </div>
                                        {esEditar ?
                                            <div className="justify-content-end d-flex mt-2">
                                                <button className="btn btn-purple d-flex align-items-center" type="button"
                                                    onClick={(e) => guardarImg(2)} >Guardar Detalle</button>
                                            </div>
                                            : ''}
                                    </div>

                                    <Fragment>
                                        {tipoServ.clave == 2 || tipoServ.clave == 3 || tipoServ.clave == 4 ?
                                            <Fragment>
                                                <div className="col-md-12 mb-3">
                                                    <h6><strong>Precios por sucursal</strong></h6>
                                                    <hr />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label className="form-label">Selecciona sucursales<span className="text-danger">*</span></label>
                                                    <SelectSimple
                                                        id={'list_suc'}
                                                        name={'list_suc'}
                                                        isMulti={true}
                                                        options={catalogos.ctgSuc}
                                                        value={selectSuc}
                                                        onChange={(datos) => {
                                                            setSelectSuc(datos)
                                                        }}
                                                    />
                                                </div>
                                            </Fragment> : ''}
                                        {tipoServ.clave == 2 || tipoServ.clave == 3 ? selectSuc.map((v, i) =>
                                            <Fragment key={v.label + i + v.value}>
                                                <div className="col-md-4 mb-3">
                                                    <label htmlFor="validationTooltip02">{v.label}<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <NumericFormat
                                                            className="form-control text-end"
                                                            id={`txt_suc${v.value}`} name={`txt_suc${v.value}`}
                                                            placeholder='0.00'
                                                            autoComplete='off'
                                                            data-label={`${v.label}`}
                                                            defaultValue={v.precio}
                                                        />
                                                    </div>
                                                    <span className={`text-danger txtVacios txt_suc${v.value}`} style={{ display: 'none' }}><strong>Falta completar Precio ({v.label})</strong></span>
                                                </div>
                                            </Fragment>
                                        ) : ''}
                                        {tipoServ.clave == 4 ? selectSuc.map((v, i) =>
                                            <Fragment key={v.label + i + v.value} >
                                                <div className="col-md-4 mb-3" hidden>
                                                    <label htmlFor="validationTooltip02">{v.label}<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <NumericFormat
                                                            className="form-control text-end"
                                                            id={`txt_suc${v.value}`} name={`txt_suc${v.value}`}
                                                            placeholder='0.00'
                                                            autoComplete='off'
                                                            data-label={`${v.label}`}
                                                            defaultValue={0.00}
                                                        />
                                                    </div>
                                                    <span className={`text-danger txtVacios txt_suc${v.value}`} style={{ display: 'none' }}><strong>Falta completar Precio ({v.label})</strong></span>
                                                </div>
                                            </Fragment>
                                        ) : ''}
                                    </Fragment>
                                    {/* :''} */}
                                    {tipoServ.clave == 1 ?
                                        <div className="row theme-tab">
                                            <Tabs className="col-sm-12">
                                                <TabList className="tabs tab-title">
                                                    <Tab><div>PRECIOS POR SUCURSAL</div></Tab>
                                                    <Tab><div>PRECIOS POR ZONAS</div></Tab>
                                                </TabList>
                                                <div className="tab-content-cls">
                                                    <div className='row'>
                                                        <div className="col-md-12 mb-3">
                                                            <label className="form-label">Sucursal (Origen)<span className="text-danger">*</span></label>
                                                            <div className="input-group input-gpack2">
                                                                <SelectSimple
                                                                    id={'list_suc'}
                                                                    name={'list_suc'}
                                                                    isMulti={true}
                                                                    options={catalogos.ctgSuc}
                                                                    value={selectSuc}
                                                                    onChange={(datos) => {
                                                                        setSelectSuc(datos)
                                                                    }}
                                                                />
                                                            </div>
                                                            <span className="text-danger txtVacios list_suc" style={{ display: 'none' }}><strong>Falta completar (Sucursal (Origen))</strong></span>
                                                        </div>
                                                    </div>
                                                    <TabPanel>
                                                        <div className='row'>
                                                            <Tabs className="col-sm-12">
                                                                <TabList className="tabs tab-title">
                                                                    {selectSuc.map((v, i) => (
                                                                        <Tab key={'Tab' + i}><div>{v.label}</div></Tab>
                                                                    ))}
                                                                </TabList>
                                                                <div className="tab-content-cls">
                                                                    {selectSuc.map((v, i) => (
                                                                        <TabPanel key={'TabPanel' + i}>
                                                                            {tiposParametros.map((tp, j) => {
                                                                                return rows.length ?
                                                                                    <div
                                                                                        key={'TabPrecios' + v.value + j}
                                                                                        className="col-md-12 mb-3"
                                                                                    >
                                                                                        <h6><strong>{tp.label}</strong></h6>
                                                                                        <TablaPrecios
                                                                                            key={'tabla' + v.value + j}
                                                                                            headers={headersSuc}
                                                                                            rows={rows.filter((pp) => pp.TIPO_VALOR_PP == tp.clave).map((r) => ({ ...r, ID_SUC: v.value }))}
                                                                                        />
                                                                                    </div> : ''
                                                                            })}
                                                                        </TabPanel>
                                                                    ))}
                                                                </div>
                                                            </Tabs>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <div className='row'>
                                                            <Fragment>
                                                                {tiposParametros.map((tp, i) => {
                                                                    return rows.length ?
                                                                        <div
                                                                            key={'tabPrecios' + i}
                                                                            className="col-md-12 mb-3"
                                                                        >
                                                                            <h6><strong>{tp.label}</strong></h6>
                                                                            <TablaPrecios
                                                                                key={'tabla' + i}
                                                                                headers={headers}
                                                                                rows={rows.filter((pp) => pp.TIPO_VALOR_PP == tp.clave).map((r) => ({ ...r, ID_SUC: 0 }))}
                                                                            />
                                                                        </div> : ''
                                                                })}
                                                            </Fragment>
                                                        </div>
                                                    </TabPanel>
                                                </div>
                                            </Tabs>
                                        </div>
                                        : ''}
                                    {!esEditar ? <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            <button
                                                className="btn btn-purple d-flex align-items-center"
                                                type="button"
                                                onClick={(e) => guardar()}
                                            >Guardar Producto Servicio</button>
                                        </div>
                                    </div> : ''}
                                    {esEditar && tipoServ.clave == 1 ?
                                        <div className="col-md-12 mb-3">
                                            <div className="justify-content-end d-flex">
                                                <button
                                                    className="btn btn-purple d-flex align-items-center"
                                                    type="button"
                                                    onClick={(e) => guardarPrecios()}
                                                >Guardar Precios</button>
                                            </div>
                                        </div>
                                        : ''}
                                     {esEditar && (tipoServ.clave == 2 || tipoServ.clave == 3 || tipoServ.clave == 4) ?
                                        <div className="col-md-12 mb-3">
                                            <div className="justify-content-end d-flex">
                                                <button
                                                    className="btn btn-purple d-flex align-items-center"
                                                    type="button"
                                                    onClick={(e) => guardarSucursales()}
                                                >Guardar Precios</button>
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default