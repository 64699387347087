import React from 'react';
import logo from '../../assets/images/logo/logo.png';
import { Mail } from 'react-feather';
import '../../assets/css/notie.css';
import notie from 'notie';
import { textos } from '../../constant/textos';
import Tools from '../../js/tools';
import { useNavigate } from 'react-router-dom';

const Default = (props) => {
    const navigate = useNavigate();

    const onClickLogin = () => {
        let formLogin = Tools.getForm('formLogin', {claveEtiquetas: '.txtVacios'})

        if (formLogin.formIsEmpty) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${formLogin.stringIsEmpty})`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (!Tools.validarCorreo(formLogin.data.correo_usuario)) {
            return notie.alert({ type: 'warning', text: textos.login.correo_incorrecto, time: 3 })
        }

        Tools.toolFetch({
            ruta: 'rt_login',
            method: 'POST',
            body: formLogin.data
        }, (response) => {
            if (response.estatus === 'NO-ENCONTRADO') {
                return notie.alert({ type: 'warning', text: textos.login.usuario_incorrecto, time: 3 })
            }

            notie.alert({
                type: 'success',
                text: `Se ha enviado un código de verificación a tu correo <strong>${formLogin.data.correo_usuario}</strong>`, 
                time: 2
            })

            return setTimeout(() => {
                navigate(`/verificacion/${response.datos}`);
            }, 2500)
        })
    }

    return (
        <div className="page-wrapper login">
            <div className="container-fluid p-0">
                <div className="authentication-main">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="auth-innerright">
                                <div className="authentication-box">
                                    <div className="card mt-4 pt-5">
                                        <div className="text-center">
                                            <img className="img-fluid" src={logo} alt="GPACK" style={{width: '80%'}} />
                                        </div>
                                        <div className="card-body">
                                            <div className="text-center">
                                                {/* <h4>GPACK</h4> */}
                                                <h6>Ingrese con su correo y contraseña</h6>
                                            </div>
                                            <form id='formLogin' className="theme-form">
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        {/* <label className="col-form-label pt-0">Correo</label> */}
                                                        <div className="input-group input-gpack">
                                                            <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03">{/* <Mail /> */}<i className="fa fa-user" aria-hidden="true"></i></span></div>
                                                            <input id='correo_usuario' name='correo_usuario' className="form-control" type="text" required='' placeholder='Correo' />
                                                        </div>
                                                        <span className="text-danger txtVacios correo_usuario" style={{ display: 'none' }}><strong>Falta completar (Correo)</strong></span>
                                                    </div>
                                                    <div className="form-group">
                                                        {/* <label className="col-form-label">Contraseña</label> */}
                                                        <div className='input-group input-gpack'>
                                                            <div className='icon-input'><span className="input-group-text"><i className="fa fa-lock" aria-hidden="true"></i></span></div>
                                                            <input id='txt_contrasenia' name='txt_contrasenia' className="form-control" type="password" required="" placeholder='Contraseña' />
                                                        </div>
                                                    </div>
                                                    <span className="text-danger txtVacios txt_contrasenia" style={{ display: 'none' }}><strong>Falta completar (Contraseña)</strong></span>
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <button id='btn_ingresar' className="btn btn-purple" type="button" onClick={onClickLogin}>Acceder al sistema</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
};

export default Default;