import React, { Fragment } from 'react';
import {NumericFormat} from 'react-number-format';
import { Mail, Phone, Smartphone, User } from 'react-feather'
import Tools from '../../js/tools'
import notie from 'notie'

const Default = ({
    formCliente,
    setFormCliente
}) => {
    const editarCliente = () => {
        const _formCliente = Tools.getForm('formCliente', {claveEtiquetas: '.txtVacios'})   

        if(_formCliente.formIsEmpty){
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_formCliente.stringIsEmpty})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }
           
        Tools.toolFetch({
            ruta: 'rt_cliente_editar',
            method: 'POST',
            body: {
                id_cl: formCliente.ID_CL,
                ..._formCliente.data
            },
        }, (response) => {
            if(response.estatus === 'EXISTE'){
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El cliente ya existe intente con otro nombre de empresa`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            let url = Tools.urlFormat(_formCliente.data.txt_nombre_comercial)
            url = `${window.location.origin}/clientes/${url}`
            window.history.pushState({},'url', url)
            
            setFormCliente((s) => ({...s, ...response.datos}))
            return notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Datos del cliente actualizados correctamente.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const editarCredito = () => {
        const formCredito = Tools.getForm('formCredito', {claveEtiquetas: '.txtVacios'})   

        if(formCredito.formIsEmpty){
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${formCredito.stringIsEmpty})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }
           
        Tools.toolFetch({
            ruta: 'rt_cliente_credito_editar',
            method: 'POST',
            body: {
                id_cl: formCliente.ID_CL,
                ...formCredito.data
            },
        }, (response) => {
            setFormCliente((s) => ({...s, ...response.datos}))
            return notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Crédito actualizado correctamente.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <div className="card">
                {/* <div className=" card-header">
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className={`form theme-form`}>
                                <div className="form-row">
                                    <div className="col-md-12">
                                        <h6 className="mb-3"><strong>CONFIGURACIÓN DEL CRÉDITO</strong></h6>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Limite de crédito<span className="red-text">*</span></label>
                                        <NumericFormat
                                            thousandSeparator={true}
                                            className="form-control text-right"
                                            id="txt_limite_credito" name="txt_limite_credito" displayType='input'
                                            // disabled={!this.props.enviados.direccion}
                                            // defaultValue={txt_limite_credito}
                                            onValueChange={(values) => {
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-12 d-flex justify-content-start align-self-end justify-content-end">
                                        <button className="btn btn-primary btn-lg" type="button"
                                            onClick={()=>null}>Editar Crédito</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='row card-body'>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h6 className="mb-3"><strong>CREDITO CLIENTE</strong></h6>
                        <form className="row" id='formCredito'>
                            <div className={`form theme-form needs-validation tooltip-validation form-row d-flex justify-content-start`}>
                                <div className="col-md-6 mb-4">
                                    <label htmlFor="validationCustomUsername">Línea de crédito<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack">
                                        {formCliente.LINEA_CREDITO != null || formCliente.LINEA_CREDITO != undefined?
                                        <NumericFormat 
                                            className="form-control text-end" 
                                            thousandSeparator="," 
                                            id="linea_credito" name="linea_credito"
                                            placeholder='$0.00'
                                            autoComplete='off'
                                            defaultValue={formCliente.LINEA_CREDITO_TEXT}
                                        />
                                        : ''}
                                    </div>
                                    <span className="text-danger txtVacios linea_credito" style={{display: 'none'}}><strong>Falta completar (Línea de crédito)</strong></span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-start mt-3">
                        <div className="text-left">
                            <button className="btn btn-purple" type="button"
                                onClick={() => editarCredito()}>Editar Crédito</button>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
                        <h6 className="mb-3"><strong>DATOS DEL CLIENTE</strong></h6>
                        <form className="row" id='formCliente'>
                            <div className={`form theme-form needs-validation tooltip-validation form-row`}>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="validationTooltip01">Empresa (Nombre Comercial)<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input 
                                        className="form-control" 
                                        id="txt_nombre_comercial" 
                                        name="txt_nombre_comercial" 
                                        autoComplete='off'
                                        type="text" 
                                        placeholder="Empresa"
                                        defaultValue={formCliente.EMPRESA_CL} 
                                    />
                                    </div>
                                    <span className="text-danger txtVacios txt_nombre_comercial" style={{display: 'none'}}><strong>Falta completar (Empresa)</strong></span>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="validationTooltip02">Nombre de Contacto<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><User /></span></div> */}
                                        <input className="form-control" id="txt_contacto" name="txt_contacto" type="text" 
                                            autoComplete='off'
                                            placeholder="Nombre de Contacto"
                                            defaultValue={formCliente.NOMBRES_CONTACTO}
                                        />
                                    </div>
                                    <span className="text-danger txtVacios txt_contacto" style={{display: 'none'}}><strong>Falta completar (Nombre de Contacto)</strong></span>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="validationTooltip02">Apellidos de Contacto<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><User /></span></div> */}
                                        <input className="form-control" id="txt_apellidos" name="txt_apellidos" type="text" 
                                            autoComplete='off'
                                            placeholder="Apellidos de Contacto"
                                            defaultValue={formCliente.APELLIDOS_CONTACTO}
                                        />
                                    </div>
                                    <span className="text-danger txtVacios txt_apellidos" style={{display: 'none'}}><strong>Falta completar (Apellidos de Contacto)</strong></span>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="validationCustomUsername">Correo Electrónico<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><Mail /></span></div> */}
                                        <input className="form-control" 
                                            autoComplete='off'
                                            id="correo_empresa" 
                                            name="correo_empresa" 
                                            type="email" 
                                            placeholder="Correo Electrónico"
                                            defaultValue={formCliente.CORREO}
                                        />
                                    </div>
                                    <span className="text-danger txtVacios correo_empresa" style={{display: 'none'}}><strong>Falta completar (Correo Electrónico)</strong></span>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <label htmlFor="validationTooltip02">Teléfono<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        {/* <div className="input-group-prepend"><span className="input-group-text"><Phone /></span></div> */}
                                        {formCliente.TELEFONO?
                                        <NumericFormat format="(###) ###-####" mask="_" className="form-control" 
                                            id="tel_empresa" name="tel_empresa"
                                            autoComplete='off'
                                            placeholder='Teléfono'
                                            defaultValue={formCliente.TELEFONO}
                                        />:''}
                                    </div>
                                    <span className="text-danger txtVacios tel_empresa" style={{display: 'none'}}><strong>Falta completar (Teléfono)</strong></span>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <label htmlFor="validationCustomUsername">Celular<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><Smartphone /></span></div> */}
                                        {formCliente.CELULAR?
                                        <NumericFormat format="(###) ###-####" mask="_" className="form-control" 
                                            id="cel_empresa" name="cel_empresa"
                                            placeholder='Celular'
                                            autoComplete='off'
                                            defaultValue={formCliente.CELULAR}
                                        />: ''}
                                    </div>
                                    <span className="text-danger txtVacios cel_empresa" style={{display: 'none'}}><strong>Falta completar (Celular)</strong></span>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end mt-3">
                        <div className="text-left">
                            <button className="btn btn-purple" type="button"
                                onClick={() => editarCliente()}>Editar Datos del Cliente</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <form className="card">
                <div className=" card-header">
                    <div className='row'>
                        <div className="col-md-6 mb-3">
                            <h6 className="mb-3"><strong>CORREO A LOS QUE SE ENVIARA FACTURA</strong></h6>
                        </div>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="validationCustomUsername">Nombre Contacto<span className="red-text">*</span></label>
                                    <div>
                                        <div className="input-group">
                                            <input className="form-control" 
                                                id="txt_nombre_contacto" name="txt_nombre_contacto" 
                                                type="email" placeholder="Nombre Contacto"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="validationCustomUsername">Correo Electrónico<span className="red-text">*</span></label>
                                    <div>
                                        <div className="input-group">
                                            <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><Mail /></span></div>
                                            <input className="form-control" 
                                                id="correo_cliente" name="correo_cliente" type="email" 
                                                placeholder="Correo Electrónico"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form> */}
        </Fragment>
    )
}

export default Default;