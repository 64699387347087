import React, { Fragment } from 'react';
import Tools from '../../../js/tools'
import notie from 'notie'
import { User, Mail, Phone, Smartphone } from 'react-feather';
import { NumericFormat } from 'react-number-format';

const Default = ({
    formCliente,
    formContacto,
    setFormContacto
}) => {
    const guardarContacto = () => {
        const _formContacto = Tools.getForm('formContacto', {claveEtiquetas: '.txtVacios', ignore: ['txt_num_int_rs']})   

        if(_formContacto.formIsEmpty){
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_formContacto.stringIsEmpty})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        Tools.toolFetch({
            ruta: formContacto.form === 'nuevo'? 'rt_clientes_contacto_nuevo': 'rt_clientes_contacto_editar',
            method: 'POST',
            body: {
                id_cl: formCliente.ID_CL,
                id_clc: formContacto.ID_CLC,
                ..._formContacto.data
            },
        }, (response) => {
            if(response.estatus === 'ERROR'){
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if(response.estatus === 'EXISTE'){
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Este contacto ya existe intente con otro correo`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro realizado con exito.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })

            if(formContacto.form === 'nuevo'){
                setFormContacto((s) => ({...s, form: 'tabla'}))
            }
        })
    }

    return (
        <Fragment>
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formContacto' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className="btn btn-regresar" type="button"
                                    onClick={(e) => setFormContacto((s) => ({...s, form: 'tabla'}))} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <h4><strong>{formContacto.form === 'editar'? 'EDITAR': 'NUEVO'} CONTACTO</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <div className='row'>
                                            <div className="col-md-12 mb-2">
                                                <h6><strong>DATOS DE CONTACTO</strong></h6>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationTooltip02">Nombre de Contacto<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><User /></span></div> */}
                                                    <input className="form-control" 
                                                        id="txt_contacto" name="txt_contacto" 
                                                        type="text" 
                                                        autoComplete='off'
                                                        placeholder="Nombre de Contacto"
                                                        defaultValue={formContacto ? formContacto.NOMBRES_CLC : ''} 
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_contacto" style={{display: 'none'}}><strong>Falta completar (Nombre de Contacto)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationTooltip02">Apellidos de Contacto<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><User /></span></div> */}
                                                    <input className="form-control" id="txt_apellidos" name="txt_apellidos" type="text" 
                                                        autoComplete='off'
                                                        placeholder="Apellidos de Contacto"
                                                        defaultValue={formContacto ? formContacto.APELLIDOS_CLC : ''} 
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_apellidos" style={{display: 'none'}}><strong>Falta completar (Apellidos de Contacto)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustomUsername">Correo Electrónico<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><Mail /></span></div> */}
                                                    <input className="form-control" 
                                                        autoComplete='off'
                                                        id="correo_contacto" 
                                                        name="correo_contacto" 
                                                        type="email" 
                                                        placeholder="Correo Electrónico"
                                                        defaultValue={formContacto ? formContacto.CORREO_CLC : ''} 
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios correo_contacto" style={{display: 'none'}}><strong>Falta completar (Correo Electrónico)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="validationTooltip02">Teléfono<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    {/* <div className="input-group-prepend"><span className="input-group-text"><Phone /></span></div> */}
                                                    <NumericFormat format="(###) ###-####" mask="_" className="form-control" 
                                                        id="tel_contacto" name="tel_contacto"
                                                        autoComplete='off'
                                                        placeholder='Teléfono'
                                                        defaultValue={formContacto ? formContacto.TELEFONO_CLC : ''} 
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios tel_contacto" style={{display: 'none'}}><strong>Falta completar (Teléfono)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="validationCustomUsername">Celular</label>
                                                <div className="input-group input-gpack2">
                                                    {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><Smartphone /></span></div> */}
                                                    <NumericFormat format="(###) ###-####" mask="_" className="form-control" 
                                                        id="cel_contacto" name="cel_contacto"
                                                        placeholder='Celular'
                                                        autoComplete='off'
                                                        defaultValue={formContacto ? formContacto.CELULAR_CLC : ''} 
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios cel_contacto" style={{display: 'none'}}><strong>Falta completar (Celular)</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className='card-footer'>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="text-left">
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="justify-content-end d-flex">
                                            <button className="btn btn-purple d-flex align-items-center" type="button"
                                                onClick={(e) => guardarContacto()} >Guardar Contacto</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default