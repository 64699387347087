import React, {useState} from 'react';
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown} from 'reactstrap';

const Default = ({
    title,
    items
}) => {
    const [btnDropleft, setBtnDropleft] = useState(false)
    return (
    <Dropdown 
        className='ml-1 mr-1'
        direction="left" 
        isOpen={btnDropleft} 
        toggle={() => {setBtnDropleft((s) => !s)}}

    >
        <DropdownToggle caret>{title}</DropdownToggle>
        <DropdownMenu>
            {items?items.map((v,i) => <DropdownItem key={i+'dd'} active toggle={false}>{v.element}</DropdownItem>):<DropdownItem>Sin elementos</DropdownItem>}
        </DropdownMenu>
    </Dropdown>)
}

export default Default;