import React, { Fragment, useEffect, useState } from 'react';
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase } from 'react-feather';
import { NumericFormat } from 'react-number-format';
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumbv2'
import LoaderSeccion from '../../components/common/loader_secciones';

const Default = ({
    esPrincipal,
    esEditar
}) => {
    const navigate = useNavigate()
    const params = useParams();
    const [form, setForm] = useState({});

    const guardar = () => {
        let formEmpresa = Tools.getForm('formEmpresa', { claveEtiquetas: '.txtVacios', ignore: ['tel_contacto'] })

        if (formEmpresa.formIsEmpty) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${formEmpresa.stringIsEmpty})`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        Tools.toolFetch({
            ruta: esEditar ? 'rt_empresas_editar' : 'rt_empresas_nueva',
            method: 'POST',
            body: {
                ...formEmpresa.data,
                empresa: params.empresa || ''
            },
        }, (response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (response.estatus === 'EXISTE') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Este contacto ya existe intente con otro correo`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (!esEditar) {
                setTimeout(() => navigate('/empresa'), 2000)
            }

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro realizado con exito.`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    useEffect(() => {
        let isS = true
        if (!esEditar) {
            if (isS) {
                setForm({})
            }
            return
        }

        Tools.toolFetch({
            ruta: 'rt_empresa_detalle',
            method: 'POST',
            body: { empresa: params.empresa || '' },
        }, (response) => {
            let datos = response.datos

            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (isS) {
                setForm(datos)
            }
        })

        return () => isS = false
    }, [esEditar])

    return (
        <Fragment>
            {esPrincipal && esEditar?<LoaderSeccion status={form}/>:''}
            {esPrincipal ? <Breadcrumb icon={Briefcase} title={esEditar ? 'EDITAR EMPRESA' : 'NUEVA EMPRESA'} parent={['empresa']} /> : ''}
            <div className="container-fluid">
                <div className='row'>
                    <form id='formEmpresa' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <div className='row'>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationTooltip02">Nombre comercial<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <input className="form-control"
                                                        id="txt_empresa" name="txt_empresa"
                                                        type="text"
                                                        autoComplete='off'
                                                        placeholder="Nombre de empresa"
                                                        defaultValue={form.NOMBRE_EM}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_empresa" style={{ display: 'none' }}><strong>Falta completar (Nombre comercial)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationTooltip02">Nombre de contacto<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <input className="form-control"
                                                        id="txt_contacto" name="txt_contacto"
                                                        type="text"
                                                        autoComplete='off'
                                                        placeholder="Nombre de contacto"
                                                        defaultValue={form.NOMBRES_CONTACTO_EM}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_contacto" style={{ display: 'none' }}><strong>Falta completar (Nombre de contacto)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationTooltip02">Apellidos de contacto<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <input className="form-control" id="txt_apellidos" name="txt_apellidos" type="text"
                                                        autoComplete='off'
                                                        placeholder="Apellidos de contacto"
                                                        defaultValue={form.APELLIDOS_CONTACTO_EM}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_apellidos" style={{ display: 'none' }}><strong>Falta completar (Apellidos de contacto)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustomUsername">Correo electrónico<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <input className="form-control"
                                                        autoComplete='off'
                                                        id="correo_contacto"
                                                        name="correo_contacto"
                                                        type="email"
                                                        placeholder="Correo electrónico"
                                                        defaultValue={form.CORREO_CONTACTO_EM}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios correo_contacto" style={{ display: 'none' }}><strong>Falta completar (Correo electrónico)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="validationCustomUsername">Celular<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <NumericFormat format="(###) ###-####" mask="_" className="form-control"
                                                        id="cel_contacto" name="cel_contacto"
                                                        placeholder='Celular'
                                                        autoComplete='off'
                                                        onChange={(data) => null}
                                                        value={form.CELULAR_CONTACTO_EM}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios cel_contacto" style={{ display: 'none' }}><strong>Falta completar (Celular)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="validationTooltip02">Teléfono</label>
                                                <div className="input-group input-gpack2">
                                                    {/* <div className="input-group-prepend"><span className="input-group-text"><Phone /></span></div> */}
                                                    <NumericFormat format="(###) ###-####" mask="_" className="form-control"
                                                        id="tel_contacto" name="tel_contacto"
                                                        autoComplete='off'
                                                        placeholder='Teléfono'
                                                        onChange={(data) => null}
                                                        value={form.TELEFONO_CONTACTO_EM}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios tel_contacto" style={{ display: 'none' }}><strong>Falta completar (Teléfono)</strong></span>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="justify-content-end d-flex">
                                                    <button
                                                        className="btn btn-purple d-flex align-items-center"
                                                        type="button"
                                                        onClick={(e) => guardar()} >
                                                        Guardar empresa
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default