import React, { Fragment } from 'react'
import Select from '../common/select-basic'

const Default = ({
    catalogos,
    onChangeRemDc,
    onChangeDesDc,
    formDCOr,
    formDCDes,
    esResumen
}) => {

    return<Fragment>
        <div className='row mb-3'>
            <form id='formRemitente' className="col-sm-12 col-md-6 col-xl-6">
                <div className="row">
                    <div className='col-sm-12 col-md-12 mb-4 mt-5'>
                        <h4><strong>REMITENTE</strong></h4>
                    </div>
                    <div className="col-sm-12 col-md-12">
                        <div className='row'>
                            <div className="col-sm-12 col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Datos de contacto<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <Select
                                        className="form-control form-control-md border border-dark"
                                        data-label="Contacto"
                                        placeholder="Seleccionar contacto"
                                        id="listDatosContOr"
                                        name="listDatosContOr"
                                        tipoValue={1}
                                        disabled={esResumen}
                                        datos={catalogos.ctgDatosContacto}
                                        onChange={onChangeRemDc}
                                        value={formDCOr.listDatosContOr}
                                    />
                                    </div>
                                    
                                    <span className="text-danger Remitente listDatosContOr" style={{ display: 'none' }}><strong>Falta completar (Datos de contacto)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Nombres<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        className="form-control"
                                        type="text"
                                        autoComplete='off'
                                        id="txtNombresOr"
                                        name='txtNombresOr'
                                        placeholder="Nombres"
                                        defaultValue={formDCOr.txtNombresOr}
                                        disabled={esResumen}
                                    />
                                    </div>
                                    
                                    <span className="text-danger Remitente txtNombresOr" style={{ display: 'none' }}><strong>Falta completar (Nombres)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Apellidos<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        className="form-control"
                                        type="text"
                                        autoComplete='off'
                                        id="txtApllidosOr"
                                        name='txtApllidosOr'
                                        placeholder="Apellidos"
                                        defaultValue={formDCOr.txtApllidosOr}
                                        disabled={esResumen}
                                    />
                                    </div>
                                    
                                    <span className="text-danger Remitente txtApllidosOr" style={{ display: 'none' }}><strong>Falta sleccionar (Apellidos)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12S mb-3">
                                <div className="form-group">
                                    <label className="form-label">Correo<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        className="form-control"
                                        type="text"
                                        autoComplete='off'
                                        id="txtCorreoOR"
                                        name='txtCorreoOR'
                                        placeholder="Correo"
                                        defaultValue={formDCOr.txtCorreoOR}
                                        disabled={esResumen}
                                    />
                                    </div>
                                    
                                    <span className="text-danger Remitente txtCorreoOR" style={{ display: 'none' }}><strong>Falta sleccionar (Correo)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Whatsapp<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        className="form-control"
                                        type="text"
                                        autoComplete='off'
                                        id="txtCelularOr"
                                        name='txtCelularOr'
                                        placeholder="Whatsapp"
                                        defaultValue={formDCOr.txtCelularOr}
                                        disabled={esResumen}
                                    />
                                    </div>
                                    
                                    <span className="text-danger Remitente txtCelularOr" style={{ display: 'none' }}><strong>Falta sleccionar (Whatsapp)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Teléfono</label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        className="form-control"
                                        type="text"
                                        autoComplete='off'
                                        id="txtTelOr"
                                        name='txtTelOr'
                                        placeholder="Teléfono"
                                        defaultValue={formDCOr.txtTelOr}
                                        disabled={esResumen}
                                    />
                                    </div>
                                    
                                    <span className="text-danger Remitente txtTelOr" style={{ display: 'none' }}><strong>Falta sleccionar (Teléfono)</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <form id='formDestinatario' className="col-sm-12 col-md-6 col-xl-6">
                <div className="row">
                    <div className='col-sm-12 col-md-12 mb-4 mt-5'>
                        <h4><strong>DESTINATARIO</strong></h4>
                    </div>
                    <div className="col-sm-12 col-md-12">
                        <div className='row'>
                            <div className="col-sm-12 col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Datos de contacto<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <Select
                                        className="form-control form-control-md border border-dark"
                                        data-label="Contacto"
                                        placeholder="Seleccionar contacto"
                                        id="listDatosContDes"
                                        name="listDatosContDes"
                                        disabled={esResumen}
                                        tipoValue={1}
                                        datos={catalogos.ctgDatosContacto}
                                        onChange={onChangeDesDc}
                                        value={formDCDes.listDatosContDes}
                                    />
                                    </div>
                                    
                                    <span className="text-danger Destinatario listDatosContDes" style={{ display: 'none' }}><strong>Falta completar (Datos de contacto)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Nombres<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        className="form-control"
                                        type="text"
                                        autoComplete='off'
                                        id="txtNombresDes"
                                        name='txtNombresDes'
                                        placeholder="Nombres"
                                        defaultValue={formDCDes.txtNombresDes}
                                        disabled={esResumen}
                                    />
                                    </div>
                                    
                                    <span className="text-danger Destinatario txtNombresDes" style={{ display: 'none' }}><strong>Falta completar (Nombres)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Apellidos<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        className="form-control"
                                        type="text"
                                        autoComplete='off'
                                        id="txtApllidosDes"
                                        name='txtApllidosDes'
                                        placeholder="Apellidos"
                                        defaultValue={formDCDes.txtApllidosDes}
                                        disabled={esResumen}
                                    />
                                    </div>
                                    
                                    <span className="text-danger Destinatario txtApllidosDes" style={{ display: 'none' }}><strong>Falta sleccionar (Apellidos)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Correo<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        className="form-control"
                                        type="text"
                                        autoComplete='off'
                                        id="txtCorreoDes"
                                        name='txtCorreoDes'
                                        placeholder="Correo"
                                        defaultValue={formDCDes.txtCorreoDes}
                                        disabled={esResumen}
                                    />
                                    </div>
                                    
                                    <span className="text-danger Destinatario txtCorreoDes" style={{ display: 'none' }}><strong>Falta sleccionar (Correo)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Whatsapp<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        className="form-control"
                                        type="text"
                                        autoComplete='off'
                                        id="txtCelularDes"
                                        name='txtCelularDes'
                                        placeholder="Whatsapp"
                                        defaultValue={formDCDes.txtCelularDes}
                                        disabled={esResumen}
                                    />
                                    </div>
                                    
                                    <span className="text-danger Destinatario txtCelularDes" style={{ display: 'none' }}><strong>Falta sleccionar (Whatsapp)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Teléfono</label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        className="form-control"
                                        type="text"
                                        autoComplete='off'
                                        id="txtTelDes"
                                        name='txtTelDes'
                                        placeholder="Teléfono"
                                        defaultValue={formDCDes.txtTelDes}
                                        disabled={esResumen}
                                    />
                                    </div>
                                    
                                    <span className="text-danger Destinatario txtTelDes" style={{ display: 'none' }}><strong>Falta sleccionar (Teléfono)</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </Fragment>
}

export default Default
