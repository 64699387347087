import React, { Fragment, useEffect, useState } from 'react';
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase, Tool } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumbv2'
import Select from '../common/select-basic'
import Dropzone from '../common/dropzone';
import tools from '../../js/tools';
import LoaderSeccion from '../../components/common/loader_secciones';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import SelectSimple from '../common/select-simple';

const Default = ({
    esPrincipal,
    esEditar,
    setSelected
}) => {
    const navigate = useNavigate();
    const params = useParams();
    const [form, setForm] = useState({});
    const [catalogos, setCatalogos] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const [refreshPrimeraCarga, setRefreshPrimeraCarga] = useState(true)
    const [selectSuc, setSelectSuc] = useState([])

    const onSelectEstado = (item) => {
        Tools.toolFetch({
            ruta: 'rt_ctg_cd',
            method: 'POST',
            body: item
        }, (response) => {
            setCatalogos((s) => ({
                ...s,
                ctgDfCd: response.datos
            }))

            setForm((s) => ({
                ...s,
                ID_EDO_DF: item.clave
            }))
        })
    }

    const guardar = () => {
        let formEmpresaDf = Tools.getForm('formEmpresaDf', {claveEtiquetas: '.txtVacios', ignore: ['txt_num_int_rs']})

        if (formEmpresaDf.formIsEmpty || (!form.fileCert || !form.fileKey)) {
            let mensajeP = ''
            if (!form.fileCert) {
                mensajeP += `${formEmpresaDf.formIsEmpty ? `, ` : ''}Archivo .cer`
                setForm((s) => ({ ...s, statusCert: 'removed', fileCert: null }))
            }

            if (!form.fileKey) {
                mensajeP += `${formEmpresaDf.formIsEmpty ? `, ` : ''}Archivo .key`
                setForm((s) => ({ ...s, statusKey: 'removed', fileKey: null }))
            }

            let mensaje = `${formEmpresaDf.formIsEmpty ? formEmpresaDf.stringIsEmpty : ''}${mensajeP}`
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${mensaje})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (formEmpresaDf.formIsEmpty) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${formEmpresaDf.stringIsEmpty})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        let formImg = new FormData(document.getElementById('formEmpresaDf'))
        console.log(params)
        console.log(params.params)
        console.log(params.params?.empresa)
        formImg.append('empresa', params.params?.empresa || '')
        formImg.append('cert', form.fileCert)
        formImg.append('key', form.fileKey)

        fetch('/rt_empresas_df_nueva', {
            method: 'POST',
            body: formImg
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (response.estatus === 'EXISTE') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Este contacto ya existe intente con otro correo`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (response.estatus === 'ARCHIVOS') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Falta cargar los archivos .cer y .key`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (response.estatus === 'ARCHIVOS-INCORRECTOS') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Los archivos no coinciden, asegurate de subir ambos archivos de forma correcta .cer y .key`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (!esEditar) {
                setTimeout(() => {
                    if(setSelected){
                        setSelected('df')
                        navigate(`/empresa/${tools.urlFormat(params.empresa)}`)
                    }else{
                        navigate('/empresa/datos-fiscales')
                    }
                }, 2000)
            }

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro realizado con exito.`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const guardarDfDir = () => {
        let formEmpresaDf = Tools.getForm('formEmpresaDf', {claveEtiquetas: '.txtVacios', ignore: ['txt_num_int_rs']})

        if (formEmpresaDf.formIsEmpty) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${formEmpresaDf.stringIsEmpty})`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }
        
        formEmpresaDf.data.empresa = params.empresa || ''

        fetch('/rt_empresas_df_editar', {
            method: 'POST',
            headers: {"Content-Type":'application/json'},
            body: JSON.stringify({
                aliasDf: params.aliasDf,
                ...formEmpresaDf.data
            })
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro realizado con exito.`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const guardarCred = () => {
        let formEmpresaDf = Tools.getForm('formEmpresaDf', {claveEtiquetas: '.txtVacios', ignore: ['txt_num_int_rs']})
        let mensaje = ''

        if (!formEmpresaDf.data.txt_clave || 
            !formEmpresaDf.data.txt_llave || 
            !formEmpresaDf.data.txt_usr
        ) {
            mensaje = `${!formEmpresaDf.data.txt_usr.length?'Usuario, ':''}${!formEmpresaDf.data.txt_clave.length?'Clave, ':''}${!formEmpresaDf.data.txt_llave.length?'Llave, ':''}` 
            mensaje = mensaje.length?mensaje.substring(0, mensaje.length - 2):''
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${mensaje})`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        formEmpresaDf.data.empresa = params.empresa || ''

        fetch('/rt_empresas_df_cred_editar', {
            method: 'POST',
            headers: {"Content-Type":'application/json'},
            body: JSON.stringify({
                aliasDf: params.aliasDf,
                ...formEmpresaDf.data
            })
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro realizado con exito.`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const guardarCert = () => {
        if (!form.fileCert) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta subir (Archivo Cert.cer)`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        let formImg = new FormData(document.getElementById('formEmpresaDf'))
        formImg.append('empresa', params.empresa || '')
        formImg.append('aliasDf', params.aliasDf)
        formImg.append('cert', form.fileCert)

        fetch('/rt_empresas_df_cert_editar', {
            method: 'POST',
            body: formImg
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
    
            if (response.estatus === 'ARCHIVOS-INCORRECTOS') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El archivo no coincide, asegurate de subir el archivo .cer`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Archivo Cert.cer actualizado con exito.`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const guardarKey = () => {
        if (!form.fileKey) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta subir (Archivo Key.key)`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        let formImg = new FormData(document.getElementById('formEmpresaDf'))
        formImg.append('empresa', params.empresa || '')
        formImg.append('aliasDf', params.aliasDf)
        formImg.append('key', form.fileKey)

        fetch('/rt_empresas_df_key_editar', {
            method: 'POST',
            body: formImg
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
    
            if (response.estatus === 'ARCHIVOS-INCORRECTOS') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El archivo no coincide, asegurate de subir el archivo .key`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Archivo Key.key actualizado con exito.`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const guardardatosGen = () => {
        let formEmpresaDf = Tools.getForm('formEmpresaDf', {claveEtiquetas: '.txtVacios'})
        let mensaje = ''

        if (!formEmpresaDf.data.txt_folio || 
            !formEmpresaDf.data.txt_serie 
        ) {
            mensaje = `${!formEmpresaDf.data.txt_folio.length?'Folio, ':''}${!formEmpresaDf.data.txt_serie.length?'Serie, ':''}` 
            mensaje = mensaje.length?mensaje.substring(0, mensaje.length - 2):''
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${mensaje})`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        formEmpresaDf.data.empresa = params.empresa || ''

        fetch('/rt_empresas_df_dg_editar', {
            method: 'POST',
            headers: {"Content-Type":'application/json'},
            body: JSON.stringify({
                aliasDf: params.aliasDf,
                ...formEmpresaDf.data
            })
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.estatus.RESULT === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            if (response.estatus.RESULT === 'ERROR-MAX') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: response.estatus.DETALLE,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            setRefreshPrimeraCarga((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro realizado con exito.`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const guardardatosSuc = () => {
        let formEmpresaDf = Tools.getForm('formEmpresaDf', {claveEtiquetas: '.txtVacios'})
        let mensaje = ''

        if (!formEmpresaDf.data.list_suc
        ) {
            mensaje = `${!formEmpresaDf.data.list_suc.length?'Sucursal, ':''}` 
            mensaje = mensaje.length?mensaje.substring(0, mensaje.length - 2):''
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${mensaje})`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        formEmpresaDf.data.empresa = params.empresa || ''

        fetch('/rt_empresas_df_suc_editar', {
            method: 'POST',
            headers: {"Content-Type":'application/json'},
            body: JSON.stringify({
                aliasDf: params.aliasDf,
                selectSuc,
                ...formEmpresaDf.data
            })
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            setRefreshPrimeraCarga((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro realizado con exito.`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    function validarNumeros(input) {
        input.target.value = input.target.value.replace(/[^0-9]/g, '');
    }

    function validarLetras(input) {
        input.target.value = input.target.value.replace(/[^a-zA-Z]/g, '');
    }

    const handleSelect = (tabId) => {
        document.getElementById('txt_folio').value = ''
        document.getElementById('txt_serie').value = ''
        let formEmpresaDf = Tools.getForm('formEmpresaDf', {claveEtiquetas: '.txtVacios', ignore: ['txt_folio, txt_serie']})
        formEmpresaDf.data.tabId = tabId
        fetch('/rt_empresas_df_folio', {
            method: 'POST',
            headers: {"Content-Type":'application/json'},
            body: JSON.stringify({aliasDf: params.aliasDf, ...formEmpresaDf.data})
        })
        .then((response) => response.json())
        .then((response) => {
            document.getElementById('txt_folio').value = response.datos.folio
            document.getElementById('txt_serie').value = response.datos.serie
        })

    };

    useEffect(() => {
        if (dataLoaded && !initialLoadComplete) {
            setInitialLoadComplete(true);
            handleSelect("I")
        }
    }, [dataLoaded, initialLoadComplete])

    useEffect(() => {
        fetch('/rt_ctg_df', {
            method: "POST",
            body: JSON.stringify({}),
            headers: { "Content-Type": "application/json" }
        })
        .then((response) => response.json())
        .then((response) => {
            setCatalogos(response.datos)
        })

        if (esEditar) {
            return Tools.toolFetch({
                ruta: 'rt_empresas_df_detalle',
                method: 'POST',
                body: { empresa: params.empresa || '', aliasDf: params.aliasDf},
            }, (response) => {
                let datos = response.datos
                let datos_suc = response.datos_suc

                if (response.estatus === 'ERROR') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }
                onSelectEstado({clave: datos.ID_EDO_EDF})
                setForm(datos)
                setSelectSuc(datos_suc)
                setDataLoaded(true)
            })
        }
    }, [refreshPrimeraCarga])

    return (
        <Fragment>
            {esPrincipal && esEditar?<LoaderSeccion status={form}/>:''} 
            {esPrincipal?<Breadcrumb icon={Briefcase} title={'Empresa'} parent={['datos_fiscales']} />:''}
            {/* className="container-fluid " */}
            <div>
                <div className='row'>
                    <form id='formEmpresaDf' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className="btn btn-regresar" type="button"
                                    onClick={(e) => { 
                                        if(setSelected){
                                            setSelected('df')
                                            navigate(`/empresa/${params.empresa}`)
                                        }else{
                                            navigate('/empresa/datos-fiscales')
                                        }
                                    }} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <h4><strong>{esEditar?'EDITAR DATOS FISCALES':'NUEVOS DATOS FISCALES'}</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <div className='row'>
                                            <div className="col-md-12 mb-2">
                                                <h6><strong>DATOS DE FACTURACIÓN</strong></h6>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationTooltip02">Alias<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <div className="input-group-prepend"></div>
                                                    <input className="form-control"
                                                        autoComplete='off'
                                                        id="txt_alias" name="txt_alias"
                                                        type="text"
                                                        placeholder="Alias"
                                                        defaultValue={form ? form.ALIAS_EDF : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_alias" style={{ display: 'none' }}><strong>Falta completar (Alias)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationTooltip02">Razón Social<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <div className="input-group-prepend"></div>
                                                    <input className="form-control"
                                                        autoComplete='off'
                                                        id="txt_rs" name="txt_rs"
                                                        type="text"
                                                        placeholder="Razón Social"
                                                        defaultValue={form ? form.RAZON_SOCIAL_EDF : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_rs" style={{ display: 'none' }}><strong>Falta completar (Razón Social)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationTooltip02">R.F.C<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <div className="input-group-prepend"></div>
                                                    <input className="form-control"
                                                        autoComplete='off'
                                                        id="txt_rfc" name="txt_rfc"
                                                        type="text"
                                                        placeholder="R.F.C"
                                                        defaultValue={form ? form.RFC_EDF : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_rfc" style={{ display: 'none' }}><strong>Falta completar (R.F.C)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Regimen Fiscal<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Regimen Fiscal"
                                                    placeholder="Seleccionar Regimen Fiscal"
                                                    id="list_regimen_fiscal"
                                                    name="list_regimen_fiscal"
                                                    value={form.REGIMEN_FISCAL_EDF}
                                                    datos={catalogos.ctgDfRegimenFiscal}
                                                    onChange={(datos) => {
                                                        setForm((s) => ({...s, REGIMEN_FISCAL_EDF: datos.item.clave}))
                                                    }}
                                                />
                                                </div>
                                                <span className="text-danger txtVacios list_regimen_fiscal" style={{ display: 'none' }}><strong>Falta completar (Regimen Fiscal)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Uso CFDI<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Uso CFDI"
                                                    placeholder="Seleccionar Uso CFDI"
                                                    id="list_uso_cfdi"
                                                    name="list_uso_cfdi"
                                                    tipoValue={1}
                                                    value={form.USO_CFDI_EDF}
                                                    datos={catalogos.ctgDfUsoCfi}
                                                    onChange={() => null}
                                                />
                                                </div>
                                                <span className="text-danger txtVacios list_uso_cfdi" style={{ display: 'none' }}><strong>Falta completar (Uso CFDI)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Correo<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><Mail /></span></div> */}
                                                    <input id='correo_rs' name='correo_rs'
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Correo"
                                                        defaultValue={form ? form.CORREO_EDF : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios correo_rs" style={{ display: 'none' }}><strong>Falta completar (Correo)</strong></span>
                                            </div>
                                            <div className="col-md-12 mb-2">
                                                <h6><strong>DIRECCIÓN FISCAL</strong></h6>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Estado<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Estado"
                                                    placeholder="Seleccionar estado"
                                                    id="list_estado"
                                                    name="list_estado"
                                                    value={form.ID_EDO_EDF}
                                                    datos={catalogos.ctgEdo}
                                                    onChange={(datos) => {
                                                        setForm((s) => ({...s, ID_EDO_EDF: datos.item.clave}))
                                                        onSelectEstado(datos.item)
                                                    }}
                                                />
                                                </div>
                                                <span className="text-danger txtVacios list_estado" style={{ display: 'none' }}><strong>Falta sleccionar (Estado)</strong></span>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Ciudad<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Ciudad"
                                                        placeholder="Seleccionar ciudad"
                                                        id="list_ciudad"
                                                        name="list_ciudad"
                                                        value={form.ID_CD_EDF}
                                                        datos={catalogos.ctgDfCd}
                                                        onChange={({ item }) => {
                                                            setForm((s) => ({...s, ID_CD_EDF: item.clave}))
                                                        }}
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios list_ciudad" style={{ display: 'none' }}><strong>Falta sleccionar (Ciudad)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Colonia<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <div className="input-group-prepend"></div>
                                                    <input id='txt_colonia_rs' name='txt_colonia_rs'
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Colonia"
                                                        defaultValue={form ? form.COlONIA_EDF : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_colonia_rs" style={{ display: 'none' }}><strong>Falta completar (Código Postal)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Calle<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <div className="input-group-prepend"></div>
                                                    <input id='txt_calle_rs' name='txt_calle_rs'
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Calle"
                                                        defaultValue={form ? form.CALLE_EDF : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_calle_rs" style={{ display: 'none' }}><strong>Falta completar (Calle)</strong></span>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label className="form-label">Número. Ext<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <div className="input-group-prepend"></div>
                                                    <input id='txt_num_ext_rs' name='txt_num_ext_rs'
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Numero Exterior"
                                                        defaultValue={form ? form.NUM_EXT_EDF : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_num_ext_rs" style={{ display: 'none' }}><strong>Falta completar (Número. Ext)</strong></span>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label className="form-label">Número. Int</label>
                                                <div className="input-group input-gpack2">
                                                    <div className="input-group-prepend"></div>
                                                    <input id='txt_num_int_rs' name='txt_num_int_rs'
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Numero Interior"
                                                        defaultValue={form ? form.NUM_INT_EDF : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_num_int_rs" style={{ display: 'none' }}><strong>Falta completar (Número. Int)</strong></span>
                                            </div>
                                            <div className="col-sm-12 col-md-4 mb-3">
                                                <div className="form-group m-0">
                                                    <label className="form-label">C.P<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                    <div className="input-group-prepend"></div>
                                                        <input id="txt_cp" name='txt_cp'
                                                            autoComplete='off'
                                                            className="form-control" placeholder="Código postal" type="text"
                                                            defaultValue={form ? form.CP_EDF : ''}
                                                        />
                                                    </div>
                                                </div>
                                                <span className="text-danger txtVacios txt_cp" style={{ display: 'none' }}><strong>Falta completar (C.P)</strong></span>
                                            </div>
                                            <div className="col-sm-12 col-md-12 mb-3">
                                                <div className="form-group m-0">
                                                    <label className="form-label">Comentarios<span className="text-danger">*</span></label>
                                                    <textarea id="txt_comentarios" name='txt_comentarios' rows={5}
                                                        autoComplete='off'
                                                        className="form-control" placeholder="Comentarios"
                                                        defaultValue={form.COMENTARIOS_EDF}
                                                    ></textarea>
                                                </div>
                                                <span className="text-danger txtVacios txt_cp" style={{ display: 'none' }}><strong>Falta completar (C.P)</strong></span>
                                            </div>
                                            {esEditar?
                                            <div className="col-md-12">
                                                <div className="justify-content-end d-flex mt-3">
                                                    <button className="btn btn-purple d-flex align-items-center" type="button"
                                                            onClick={(e) => guardarDfDir()} >Guardar datos fiscales y dirección</button>
                                                </div>
                                            </div>
                                            :''}
                                            <div className="col-md-12 mb-2">
                                                <h6><strong>CREDENCIALES</strong></h6>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className="form-group m-0">
                                                    <label className="form-label">Usuario<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack">
                                                        <input id="txt_usr" name='txt_usr'
                                                        autoComplete='off'
                                                        className="form-control" placeholder="Usuario" type="text"
                                                        defaultValue={form ? form.USUARIO_EDF : ''}
                                                    />
                                                    </div>
                                                    
                                                </div>
                                                <span className="text-danger txtVacios txt_usr" style={{ display: 'none' }}><strong>Falta completar (Usuario)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className="form-group m-0">
                                                    <label className="form-label">Clave<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack">
                                                        <input id="txt_clave" name='txt_clave'
                                                        autoComplete='off'
                                                        className="form-control" placeholder="Clave" type="password"
                                                        defaultValue={form ? form.CLAVE_EDF : ''}
                                                    />
                                                    </div>
                                                </div>
                                                <span className="text-danger txtVacios txt_clave" style={{ display: 'none' }}><strong>Falta completar (Clave)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className="form-group m-0">
                                                    <label className="form-label">Llave<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack">
                                                        <input id="txt_llave" name='txt_llave'
                                                        autoComplete='off'
                                                        className="form-control" placeholder="Llave" type="password"
                                                        defaultValue={form ? form.LLAVE_EDF : ''}
                                                    />
                                                    </div>
                                                    
                                                </div>
                                                <span className="text-danger txtVacios txt_llave" style={{ display: 'none' }}><strong>Falta completar (Llave)</strong></span>
                                            </div>
                                            {esEditar?
                                            <div className="col-md-12">
                                                <div className="justify-content-end d-flex mt-3">
                                                    <button className="btn btn-purple d-flex align-items-center" type="button"
                                                            onClick={(e) => guardarCred()} >Guardar credenciales</button>
                                                </div>
                                            </div>
                                            :''}
                                            <div className="col-md-12 mb-3">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group m-0">
                                                            <label className="form-label">Cert<span className="text-danger">*</span></label>
                                                            <Dropzone
                                                                inputContent={'Da click o arrastra el archivo .cer'}
                                                                initialFiles={form?form.ARCHIVO_CERT:null}
                                                                fileName={form?form.CERT_ARCHIVO_EDF:null}
                                                                onImg={(datos) => { 
                                                                    setForm((s) => ({...s, statusCert: datos.status, fileCert: datos.file}))
                                                                }}
                                                            />
                                                        </div>
                                                        <span className="text-danger txtVacios txt_cp" style={{ display: 'none' }}><strong>Falta completar (C.P)</strong></span>
                                                        {esEditar?
                                                        <div className="col-md-12">
                                                            <div className="justify-content-end d-flex mt-3">
                                                                <button className="btn btn-purple d-flex align-items-center" type="button"
                                                                        onClick={(e) => guardarCert()} >Guardar archivo .cer</button>
                                                            </div>
                                                        </div>
                                                        :''}
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group m-0">
                                                            <label className="form-label">Key<span className="text-danger">*</span></label>
                                                            <Dropzone
                                                                inputContent={'Da click o arrastra el archivo .key'}
                                                                initialFiles={form?form.ARCHIVO_KEY:null}
                                                                fileName={form?form.KEY_ARCHIVO_EDF:null}
                                                                onImg={(datos) => {
                                                                    setForm((s) => ({...s, statusKey: datos.status, fileKey: datos.file}))
                                                                }}
                                                            />
                                                        </div>
                                                        <span className="text-danger txtVacios txt_cp" style={{ display: 'none' }}><strong>Falta completar (C.P)</strong></span>
                                                        {esEditar?
                                                        <div className="col-md-12">
                                                            <div className="justify-content-end d-flex mt-3">
                                                                <button className="btn btn-purple d-flex align-items-center" type="button"
                                                                        onClick={(e) => guardarKey()} >Guardar archivo .key</button>
                                                            </div>
                                                        </div>
                                                        :''}
                                                    </div>
                                                </div>
                                            </div>
                                            {esEditar?
                                            <>
                                                <div className="col-md-12 mb-2">
                                                    <h6><strong>SUCURSALES</strong></h6>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className='row'>
                                                        <div className="col-md-12 mb-3">
                                                            <label className="form-label">Sucursal (Origen)<span className="text-danger">*</span></label>
                                                            <div className="input-group input-gpack2">
                                                                <SelectSimple
                                                                    id={'list_suc'}
                                                                    name={'list_suc'}
                                                                    isMulti={true}
                                                                    options={catalogos.ctgSuc}
                                                                    value={selectSuc}
                                                                    onChange={(datos) => {
                                                                        setSelectSuc(datos)
                                                                    }}
                                                                />
                                                            </div>
                                                            <span className="text-danger txtVacios list_suc" style={{ display: 'none' }}><strong>Falta completar (Sucursal (Origen))</strong></span>
                                                        </div>
                                                        {esEditar?
                                                        <div className="col-md-12">
                                                            <div className="justify-content-end d-flex mt-3">
                                                                <button className="btn btn-purple d-flex align-items-center" type="button"
                                                                        onClick={(e) => guardardatosSuc()} >Guardar sucursales</button>
                                                            </div>
                                                        </div>
                                                        :''}
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <h6><strong>DATOS GENERALES DE LA FACTURA</strong></h6>
                                                </div>
                                                <div className="col-md-12 mb-2">              
                                                    <div className="row theme-tab">
                                                        <Tabs className="col-md-12">
                                                            <TabList className="tabs tab-title">
                                                                <Tab onClick={() => handleSelect("I") }><div>Factura de Ingreso</div></Tab>
                                                                <Tab onClick={() => handleSelect("E") }><div>Factura de Egreso</div></Tab>
                                                                <Tab onClick={() => handleSelect("CP")}><div>Carta Porte</div></Tab>
                                                            </TabList>
                                                            <TabPanel id="panelI">
                                                                <div className="row">  
                                                                    <div className="col-md-6 mb-3">
                                                                        <div className="form-group m-0">
                                                                            <label className="form-label">Folio<span className="text-danger">*</span></label>
                                                                            <div className="input-group input-gpack">
                                                                                <input id="txt_folio" name='txt_folio'
                                                                                    autoComplete='off'
                                                                                    className="form-control" placeholder="Folio" type="text"
                                                                                    defaultValue={form ? form.FOLIO_DG : ''} onChange={validarNumeros}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <span className="text-danger txtVacios txt_folio" style={{ display: 'none' }}><strong>Falta completar (Folio)</strong></span>
                                                                    </div>
                                                                    <div className="col-md-6 mb-3">
                                                                        <div className="form-group m-0">
                                                                            <label className="form-label">Serie<span className="text-danger">*</span></label>
                                                                            <div className="input-group input-gpack">
                                                                                <input id="txt_serie" name='txt_serie'
                                                                                    autoComplete='off'
                                                                                    className="form-control" placeholder="Serie" type="text"
                                                                                    defaultValue={form ? form.SERIE_DG : ''} onChange={validarNumeros}
                                                                                />
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        <span className="text-danger txtVacios txt_serie" style={{ display: 'none' }}><strong>Falta completar (Serie)</strong></span>
                                                                    </div>
                                                                    <div className="col-md-6 mb-3" hidden>
                                                                        <div className="form-group m-0">
                                                                            <label className="form-label">Columna<span className="text-danger">*</span></label>
                                                                            <div className="input-group input-gpack">
                                                                                <input id="txt_col" name='txt_col'
                                                                                    autoComplete='off'
                                                                                    className="form-control" placeholder="Columna" type="text"
                                                                                    defaultValue={"I"}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <span className="text-danger txtVacios txt_col" style={{ display: 'none' }}><strong>Falta completar (Columna)</strong></span>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel id="panelE">
                                                                <div className="row">  
                                                                    <div className="col-md-6 mb-3">
                                                                        <div className="form-group m-0">
                                                                            <label className="form-label">Folio<span className="text-danger">*</span></label>
                                                                            <div className="input-group input-gpack">
                                                                                <input id="txt_folio" name='txt_folio'
                                                                                    autoComplete='off'
                                                                                    className="form-control" placeholder="Folio" type="text"
                                                                                    defaultValue={form ? '' : ''} onChange={validarNumeros}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <span className="text-danger txtVacios txt_folio" style={{ display: 'none' }}><strong>Falta completar (Folio)</strong></span>
                                                                    </div>
                                                                    <div className="col-md-6 mb-3">
                                                                        <div className="form-group m-0">
                                                                            <label className="form-label">Serie<span className="text-danger">*</span></label>
                                                                            <div className="input-group input-gpack">
                                                                                <input id="txt_serie" name='txt_serie'
                                                                                    autoComplete='off'
                                                                                    className="form-control" placeholder="Serie" type="text"
                                                                                    defaultValue={form ? '' : ''} onChange={validarLetras}
                                                                                />
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        <span className="text-danger txtVacios txt_serie" style={{ display: 'none' }}><strong>Falta completar (Serie)</strong></span>
                                                                    </div>
                                                                    <div className="col-md-6 mb-3" hidden>
                                                                        <div className="form-group m-0">
                                                                            <label className="form-label">Columna<span className="text-danger">*</span></label>
                                                                            <div className="input-group input-gpack">
                                                                                <input id="txt_col" name='txt_col'
                                                                                    autoComplete='off'
                                                                                    className="form-control" placeholder="Columna" type="text"
                                                                                    defaultValue={"E"}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <span className="text-danger txtVacios txt_col" style={{ display: 'none' }}><strong>Falta completar (Columna)</strong></span>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel id="panelCP">
                                                                <div className="row">  
                                                                    <div className="col-md-6 mb-3">
                                                                        <div className="form-group m-0">
                                                                            <label className="form-label">Folio<span className="text-danger">*</span></label>
                                                                            <div className="input-group input-gpack">
                                                                                <input id="txt_folio" name='txt_folio'
                                                                                    autoComplete='off'
                                                                                    className="form-control" placeholder="Folio" type="text"
                                                                                    defaultValue={form ? '' : ''} onChange={validarNumeros}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <span className="text-danger txtVacios txt_folio" style={{ display: 'none' }}><strong>Falta completar (Folio)</strong></span>
                                                                    </div>
                                                                    <div className="col-md-6 mb-3">
                                                                        <div className="form-group m-0">
                                                                            <label className="form-label">Serie<span className="text-danger">*</span></label>
                                                                            <div className="input-group input-gpack">
                                                                                <input id="txt_serie" name='txt_serie'
                                                                                    autoComplete='off'
                                                                                    className="form-control" placeholder="Serie" type="text"
                                                                                    defaultValue={form ? '' : ''} onChange={validarLetras}
                                                                                />
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        <span className="text-danger txtVacios txt_serie" style={{ display: 'none' }}><strong>Falta completar (Serie)</strong></span>
                                                                    </div>
                                                                    <div className="col-md-6 mb-3" hidden>
                                                                        <div className="form-group m-0">
                                                                            <label className="form-label">Columna<span className="text-danger">*</span></label>
                                                                            <div className="input-group input-gpack">
                                                                                <input id="txt_col" name='txt_col'
                                                                                    autoComplete='off'
                                                                                    className="form-control" placeholder="Columna" type="text"
                                                                                    defaultValue={"CP"}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <span className="text-danger txtVacios txt_col" style={{ display: 'none' }}><strong>Falta completar (Columna)</strong></span>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                        </Tabs>
                                                    </div>
                                                    {esEditar?
                                                    <div className="col-md-12">
                                                        <div className="justify-content-end d-flex mt-3">
                                                            <button className="btn btn-purple d-flex align-items-center" type="button"
                                                                    onClick={(e) => guardardatosGen()} >Guardar datos generales</button>
                                                        </div>
                                                    </div>
                                                    :''}
                                                </div>
                                            </>
                                            :''}
                                            
                                            {!esEditar?
                                            <div className="col-md-12 mt-2">
                                                <div className="justify-content-end d-flex">
                                                    <button className="btn btn-purple d-flex align-items-center" type="button"
                                                            onClick={(e) => guardar()} >Guardar datos fiscales</button>
                                                </div>
                                            </div>
                                            :''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default