import React, { Fragment, useEffect } from 'react';
import { User, Mail } from 'react-feather';
import { NumericFormat } from 'react-number-format';
import { Phone, Smartphone, ChevronRight, ChevronLeft } from 'react-feather'
// import Map from '../../common/mapbox'
import Select from '../../common/select-basic'
import Tools from '../../../js/tools'
import notie from 'notie'

const Default = ({
    state,
    setState,
    formDireccion,
    setFormDireccion,
    catalogos,
    setCatalogos,
    disabledInputs
}) => {
    const onSelectEstado = ({ item }) => {
        Tools.toolFetch({
            ruta: 'rt_ctg_cd',
            method: 'POST',
            body: item
        }, (response) => {
            setCatalogos((s) => ({
                ...s,
                ctgDirCd: response.datos
            }))
        })
    }

    const validarForm = () => {
        let _formDireccion = guardarForm()

        if(_formDireccion.formIsEmpty){
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_formDireccion.stringIsEmpty})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        setState((s) => ({
            ...s,
            siguienteFormulario: 3
        }))
    }

    const guardarForm = (omitir = false) => {
        const _formDireccion = Tools.getForm('formDireccion', {claveEtiquetas: '.txtVacios', ignore: ['txt_num_int', 'txt_referencia', 'txt_latitud', 'txt_longitud', 'cel_contacto']})   
        setFormDireccion({omitir, ..._formDireccion.data})
        return _formDireccion
    }

    useEffect(() => {
        if(!setFormDireccion){
            return
        }

        if(setFormDireccion.list_estado){
            onSelectEstado({clave: formDireccion.list_estado})
        }
    }, [])

    return (
        <Fragment>
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formDireccion' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-center">
                                <h4><strong>NUEVA DIRECCIÓN DE ENTREGA</strong></h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <h6 className="mb-3"><strong>DIRECCIÓN</strong></h6>
                                        <div className='row'>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Alias de dirección<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <input
                                                        className="form-control" 
                                                        type="text"
                                                        autoComplete='off'
                                                        id="txt_alias"
                                                        name='txt_alias' 
                                                        placeholder="Alias de dirección (Casa, Empresa, etc)"
                                                        defaultValue={formDireccion ? formDireccion.txt_alias : ''}
                                                        disabled={disabledInputs ? disabledInputs : false} 
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_alias" style={{display: 'none'}}><strong>Falta completar (Alias de dirección)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Estado<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <Select
                                                        disabled={disabledInputs ? disabledInputs : false}
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Estado"
                                                        placeholder="Seleccionar estado"
                                                        id="list_estado"
                                                        name="list_estado"
                                                        value={formDireccion.list_estado}
                                                        datos={catalogos.ctgEdo}
                                                        onChange={onSelectEstado}
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios list_estado" style={{display: 'none'}}><strong>Falta sleccionar (Estado)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Ciudad<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <Select
                                                        disabled={disabledInputs ? disabledInputs : false}
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Ciudad"
                                                        placeholder="Seleccionar ciudad"
                                                        id="list_ciudad"
                                                        name="list_ciudad"
                                                        value={formDireccion.list_ciudad}
                                                        datos={catalogos.ctgDirCd}
                                                        onChange={({ item }) => {
                                                            setFormDireccion((s) => ({
                                                                ...s,
                                                                list_ciudad: item.clave
                                                            }))
                                                        }}
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios list_ciudad" style={{display: 'none'}}><strong>Falta sleccionar (Ciudad)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Calle<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <input 
                                                        id="txt_calle_numero" name='txt_calle_numero' 
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Calle"
                                                        defaultValue={formDireccion ? formDireccion.txt_calle_numero : ''}
                                                        disabled={disabledInputs ? disabledInputs : false} />
                                                    </div>
                                                    
                                                    <span className="text-danger txtVacios txt_calle_numero" style={{display: 'none'}}><strong>Falta sleccionar (Calle)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-2 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Número Ext.<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <input 
                                                        id="txt_num_ext" name='txt_num_ext' 
                                                        autoComplete='off'
                                                        className="form-control" type="text" 
                                                        placeholder="Número Ext"
                                                        defaultValue={formDireccion ? formDireccion.txt_num_ext : ''}
                                                        disabled={disabledInputs ? disabledInputs : false} 
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_num_ext" style={{display: 'none'}}><strong>Falta sleccionar (Número Ext)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-2 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Número Int.</label>
                                                    <div className="input-group input-gpack2">
                                                        <input id="txt_num_int" name='txt_num_int' 
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Número Int"
                                                        defaultValue={formDireccion ? formDireccion.txt_num_int : ''}
                                                        disabled={disabledInputs ? disabledInputs : false} 
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_num_int" style={{display: 'none'}}><strong>Falta sleccionar (Número Int)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-2 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">C.P<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <input id="txt_cp" name='txt_cp' 
                                                        autoComplete='off'
                                                        className="form-control" placeholder="Código postal" type="number"
                                                        defaultValue={formDireccion ? formDireccion.txt_cp : ''}
                                                        disabled={disabledInputs ? disabledInputs : false} />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_cp" style={{display: 'none'}}><strong>Falta sleccionar (C.P)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Colonia<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <input id="txt_colonia" name='txt_colonia' 
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Colonia"
                                                        defaultValue={formDireccion ? formDireccion.txt_colonia : ''}
                                                        disabled={disabledInputs ? disabledInputs : false} />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_colonia" style={{display: 'none'}}><strong>Falta sleccionar (Colonia)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Referencia</label>
                                                    <div className="input-group input-gpack2">
                                                        <input id="txt_referencia" name='txt_referencia' 
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Referencia"
                                                        defaultValue={formDireccion ? formDireccion.txt_referencia : ''}
                                                        disabled={disabledInputs ? disabledInputs : false} />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_referencia" style={{display: 'none'}}><strong>Falta sleccionar (Referencia)</strong></span>
                                                </div>
                                            </div>
                                        </div>
                                        <h6 className="mb-3 mt-4"><strong>DATOS DE GEOLOCALIZACIÓN</strong></h6>
                                        <div className='row'>
                                            <div className="col-sm-12 col-md-12">
                                                <div className='row'>
                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Latitud</label>
                                                            <div className="input-group input-gpack2">
                                                                <input id="txt_latitud" name='txt_latitud' 
                                                                autoComplete='off'
                                                                className="form-control" type="text" placeholder="Latitud"
                                                                defaultValue={formDireccion ? formDireccion.txt_latitud : ''}
                                                                disabled={disabledInputs ? disabledInputs : false}
                                                            />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Longitud</label>
                                                            <div className="input-group input-gpack2">
                                                                <input id="txt_longitud" name='txt_longitud' 
                                                                autoComplete='off'
                                                                className="form-control" type="text" placeholder="Longitud"
                                                                defaultValue={formDireccion ? formDireccion.txt_longitud : ''}
                                                                disabled={disabledInputs ? disabledInputs : false}
                                                            />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h6 className="mb-3 mt-4"><strong>DATOS DE CONTACTO</strong></h6>
                                        <div className='row'>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationTooltip02">Nombre de Contacto<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><User /></span></div> */}
                                                    <input className="form-control" 
                                                        id="txt_contacto" name="txt_contacto" 
                                                        type="text" 
                                                        autoComplete='off'
                                                        placeholder="Nombre de Contacto"
                                                        defaultValue={formDireccion ? formDireccion.txt_contacto : ''} 
                                                        disabled={disabledInputs ? disabledInputs : false} 
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_contacto" style={{display: 'none'}}><strong>Falta completar (Nombre de Contacto)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationTooltip02">Apellidos de Contacto<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><User /></span></div> */}
                                                    <input className="form-control" id="txt_apellidos" name="txt_apellidos" type="text" 
                                                        autoComplete='off'
                                                        placeholder="Apellidos de Contacto"
                                                        defaultValue={formDireccion ? formDireccion.txt_apellidos : ''} 
                                                        disabled={disabledInputs ? disabledInputs : false} 
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_apellidos" style={{display: 'none'}}><strong>Falta completar (Apellidos de Contacto)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustomUsername">Correo Electrónico<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><Mail /></span></div> */}
                                                    <input className="form-control" 
                                                        autoComplete='off'
                                                        id="correo_contacto" 
                                                        name="correo_contacto" 
                                                        type="email" 
                                                        placeholder="Correo Electrónico"
                                                        defaultValue={formDireccion ? formDireccion.correo_contacto : ''} 
                                                        disabled={disabledInputs ? disabledInputs : false} 
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios correo_contacto" style={{display: 'none'}}><strong>Falta completar (Correo Electrónico)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="validationTooltip02">Teléfono<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    {/* <div className="input-group-prepend"><span className="input-group-text"><Phone /></span></div> */}
                                                    <NumericFormat format="(###) ###-####" mask="_" className="form-control" 
                                                        id="tel_contacto" name="tel_contacto"
                                                        autoComplete='off'
                                                        placeholder='Teléfono'
                                                        defaultValue={formDireccion ? formDireccion.tel_contacto : ''} 
                                                        disabled={disabledInputs ? disabledInputs : false} 
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios tel_contacto" style={{display: 'none'}}><strong>Falta completar (Teléfono)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="validationCustomUsername">Celular</label>
                                                <div className="input-group input-gpack2">
                                                    {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><Smartphone /></span></div> */}
                                                    <NumericFormat format="(###) ###-####" mask="_" className="form-control" 
                                                        id="cel_contacto" name="cel_contacto"
                                                        placeholder='Celular'
                                                        autoComplete='off'
                                                        defaultValue={formDireccion ? formDireccion.cel_contacto : ''} 
                                                        disabled={disabledInputs ? disabledInputs : false} />
                                                </div>
                                                <span className="text-danger txtVacios cel_contacto" style={{display: 'none'}}><strong>Falta completar (Celular)</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {disabledInputs === undefined ?
                            <div className="card">
                                <div className='card-footer'>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <div className="text-left">
                                                <button className="btn btn-back d-flex align-items-center" type="button"
                                                    onClick={(e) => { 
                                                        guardarForm()
                                                        setState((s) => ({...s, siguienteFormulario: 1}))
                                                    }} ><ChevronLeft /> Atrás</button>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="justify-content-end d-flex">
                                                <button className="btn btn-omitir" type="button"
                                                    onClick={() => {
                                                        guardarForm(true)
                                                        setState((s) => ({...s, siguienteFormulario: 3}))
                                                    }} 
                                                    style={{ marginRight: 10 }}>Omitir</button>

                                                <button className="btn btn-purple d-flex align-items-center" type="button"
                                                    onClick={(e) => validarForm()} >Siguiente <ChevronRight /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : ''}
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default