import React, { Fragment, useState } from 'react';
import {useNavigate} from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Clipboard } from 'react-feather';

const Default = (props) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const navigate = useNavigate()

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_prod_serv_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            console.log(response)
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Clipboard} title={'Solicitudes'} parent={['solicitudes']} />
            <div className="card" >
                <div className="card-body">
                    <div className='row'>
                        <div className="col-xl-12">
                        <Table
                            noRutaDinamica={true}
                            cargando={false}
                            headers={[
                                {
                                    nombre: 'SOLICITUD', tipo: 'html', texto: 'NOMBRES_CONTACTO_CL', html: (d) => {
                                        return <div>#{d.ID_SOL}</div>
                                    }
                                },
                                {
                                    nombre: 'CLIENTE', tipo: 'html', texto: 'NOMBRES_CONTACTO_CL', html: (d) => {
                                        return <div>{d.NOMBRES_CONTACTO_CL} {d.APELLIDOS_CONTACTO_CL}</div>
                                    }
                                },
                                {
                                    nombre: 'CORREO', tipo: 'html', texto: 'CORREO_CL', html: (d) => {
                                        return <div>{d.CORREO_CL}</div>
                                    }
                                },
                                {
                                    nombre: 'TOTAL', tipo: 'html', texto: 'TOTAL_SOL', html: (d) => {
                                        return <div>${d.TOTAL_TEXT}</div>
                                    }
                                },
                                {
                                    nombre: 'FECHA ALTA', tipo: 'html', texto: 'FECHA_ALTA_TEXT', html: (d) => {
                                        return <div>{d.FECHA_ALTA_TEXT}</div>
                                    }
                                },
                                {
                                    nombre: 'DETALLE', tipo: 'html', texto: 'Detalle',
                                    html: (d) => {
                                        return <div>
                                        <BtnVer
                                            onClick={() => {
                                                navigate(`/solicitudes/${d.ID_SOL}`)
                                            }} 
                                        />
                                        <BtnEliminar
                                            onClick={() => {
                                                notie.confirm({
                                                    text: `Se eliminara <strong>${d.NOMBRE_PS}</strong> ¿estas de acuerdo?`,
                                                    submitText: 'Si, eliminar',
                                                    cancelText: 'No, Cancelar',
                                                    cancelCallback: function (value) {
                                                    },
                                                    submitCallback: function (value) {
                                                        eliminar({ id_ps: d.ID_PS })
                                                    },
                                                })
                                            }} 
                                        />
                                        </div>
                                    }
                                }
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'/rt_solicitudes'}
                            refresh={esRefresh}
                            body={{}}
                            rowsPerPage={20}
                            paginadorTop={true}
                        >
                        </Table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;