import React, {Fragment} from 'react'
import { Check, ChevronLeft } from 'react-feather'
import DatosEmpresa from './form_cliente'
import DatoDireccion from './form_direccion'
import DatoFiscales from './form_datos_fiscales'
import {useNavigate} from "react-router-dom";
import Tools from '../../../js/tools'
import notie from 'notie'

const Default = ({
    state,
    setState,

    catalogos,
    setCatalogos,

    formEmpresa,
    setFormEmpresa,
    
    formDireccion,
    setFormDireccion,
    
    formDF,
    setFormDF
}) => {
     const navigate = useNavigate();

     const onClickGuardar = () => {
        Tools.toolFetch({ 
            ruta: 'rt_clientes_nuevo',
            method: 'POST',
            body: {
                formEmpresa,
                formDireccion,
                formDF
            },
        }, (response) => {
            if(response.estatus === 'SIN-SESION'){
                setTimeout(() => {
                    window.open("/login", "_blank");
                }, 3000)
                
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Se termino la sesión, redirigiendo al login...`,
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if(response.estatus === 'ERROR'){
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if(response.estatus === 'EXISTE'){
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El cliente que intenta registrar ya existe, intente con otro nombre de empresa.`,
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro realizado con exito, redirigiendo a sección de clientes...`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })

            setTimeout(() => {
                navigate('/clientes')
            }, 3000)
        })
    }

    return (
    <Fragment>
        <div className="container-fluid">
        {/* DATOS EMPRESA */}
        <DatosEmpresa 
            state={state}
            setState={setState}
            formEmpresa={formEmpresa}
            setFormEmpresa={setFormEmpresa}
            disabledInputs={true}
        />
        {/* DIRECCIONES DE ENTREGA */}
        {!formDireccion.omitir? 
         <DatoDireccion 
            state={state}
            setState={setState}
            formDireccion={formDireccion}
            setFormDireccion={setFormDireccion}
            disabledInputs={true}
            catalogos={catalogos}
            setCatalogos={setCatalogos}
        />
        : ''}
        {!formDF.omitir? 
        <DatoFiscales 
            state={state}
            setState={setState}
            formDF={formDF}
            setFormDF={setFormDF}
            disabledInputs={true}
            catalogos={catalogos}
            setCatalogos={setCatalogos}
        />
        : ''}
               
        <form className='row'>
            <div className='col-lg-12'>
                <div className="card">
                    <div className='card-footer'>
                        <div className='row'>
                            <div className="col-md-6">
                                <div className="text-left">
                                    <button className="btn btn-back d-flex align-items-center" type="button" 
                                        onClick={(e) => {
                                            setState((s) => ({...s, siguienteFormulario: 3}))
                                        }} ><ChevronLeft /> Atrás</button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="col-md-12 d-flex justify-content-end">
                                    <button className="btn btn-purple d-flex align-items-center" type="button"
                                        onClick={() => onClickGuardar()} >Finalizar <Check /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    </Fragment> )
}

export default Default