import React, { Fragment , useState , useEffect } from 'react';

const Loader = ({status = false}) => {
    const [show, setShow] = useState(true);
    useEffect(() => {
        if(status == '' || status == null || status == undefined || status == 0 || status == false){
            return
        }

        if(Array.isArray(status)){
            if(status.length == 0){
                return
            }
        }

        if(typeof status == 'object'){
            if(Object.keys(status).length == 0){
                return
            }
        }

        const timeout = setTimeout(() => {
            setShow(false)
          }, 1000);
        
        return() => {
            clearTimeout(timeout)
        }
        
    },[status]);
    return (
        <Fragment>
            <div className={`loader-wrapper ${show ? '' : 'loderhide'}`} style={{zIndex: 1}}>
                <div className="loader bg-white">
                    <div className="whirly-loader" style={{textAlign: 'center'}}></div>
                </div>
            </div>
        </Fragment>
    );
};

export default Loader;