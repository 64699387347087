import React, { Fragment } from 'react';
import { Truck } from 'react-feather';
import Breadcrumb from '../../common/breadcrumbv2';
import Tabla from '../../common/tabla';
import { useNavigate } from 'react-router-dom';
import tools from '../../../js/tools';
import { BtnVer, BtnEliminar } from '../../common/table_buttons'
import notie from 'notie'


const Default = (props) => {
    const navigate = useNavigate();


    return (
        <Fragment>
            <Breadcrumb icon={Truck} title={'Conductores'} parent={['conductores']}/>

            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">

                    <Tabla 
                        cargando={false}
                        htmlBtnPag={
                            <button
                                className="btn btn-purple btn-nuevo"
                                type="button"
                                onClick={() => {navigate('/conductores/nuevo')}}
                            > Nuevo conductor
                            </button>}
                        headers={[
                            // {nombre: 'ID', texto: 'ID'},
                            {nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE', html: (d) => {
                                return <div >
                                    {d.NOMBRE_DRV} 
                                </div>
                            }},
                            {nombre: 'CORREO', tipo: 'html', texto: 'CORREO', html: (d) => {
                                return <div >
                                    {d.CORREO_DRV} 
                                </div>
                            }},
                            {nombre: 'PERFIL', tipo: 'html', texto: 'PERFIL', html: (d) => {
                                return <div >
                                    {d.PERFIL_DRV} 
                                </div>
                            }},
                            {nombre: 'ALTA', tipo: 'html', texto: 'ALTA', html: (d) => {
                                return <div>
                                    {d.FECHA_ALTA_DRV} 
                                </div>
                            }},
                            {
                                nombre: 'DETALLE', tipo: 'html', texto: 'DETALLE',
                                html: (d) => {
                                    return <div>
                                        <BtnVer
                                            onClick={() => {navigate(`/conductores/${(d.ID_DRV)}`)}}
                                        />
                                    </div>
                                }
                            }   
                        ]}
                        totalField={'NR'} /* NR = número de resultados totales */
                        path={'/rt_drivin_conductor'}
                        // refresh={esRefresh}
                        body={{}}
                        rowsPerPage={10}
                        paginadorTop={true}
                    >
                    </Tabla>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;