import React, { Fragment, useState, useEffect } from 'react';
import Tools from '../../js/tools';
import notie from 'notie';
import EnvioTipoServicio from './envios_tipo_servicio';
import EnvioOrigenDestino from './envios_origen_destino';
import EnvioDatosContacto from './envios_datos_contacto';
import EnvioInfoCarga from './envios_info_carga';
import EnvioPagos from './envios_pagos';

const Default = ({
    servicio,
    setServicio,
    selectCliente,
    setSelectCliente,
    catalogos, 
    setCatalogos,
    onChangeCliente,
    idEditar
}) => {
    let objetoOrigen = {claveEtiquetas: 'FormOrigen', ignore: ['txtNumIntOr', 'txtReferenciaOr', 'txtLongitudOr', 'txtLatitudOr', 'listDirOr', 'txtAliasOr']}
    let objetoDestino = {claveEtiquetas: 'FormDestino', ignore: ['txtNumIntDes', 'txtReferenciaDes', 'txtLongitudDes', 'txtLatitudDes', 'listDirDes', 'txtAliasDes']}
    let objetoRemitente = {claveEtiquetas: 'Remitente', ignore: ['txtTelOr', 'listDatosContOr']}
    let objetoDestinatario = {claveEtiquetas: 'Destinatario', ignore: ['txtTelDes', 'listDatosContDes']}
    let objetoCarga = {claveEtiquetas: 'Carga'}

    const [formDirOr, setFormDirOr] = useState({})
    const [formDirDes, setFormDirDes] = useState({})
    const [formDCOr, setFormDCOr] = useState({})
    const [formDCDes, setFormDCDes] = useState({})
    const [formCarga, setFormCarga] = useState({})
    const [precio, setPrecio] = useState({})

    const [selectTipoServ, setSelectTipoServ] = useState({})
    const [selectGrupos, setSelectGrupos] = useState({})
    const [sigPaso, setSigPaso] = useState({
        indice: 1,
        pasos: [
            {numero: 1, etiqueta: 'Tipo servicio'},
            {numero: 2, etiqueta: 'Origen - Destino'},
            {numero: 3, etiqueta: 'Datos de contacto'},
            {numero: 4, etiqueta: 'Información de carga'},
            {numero: 5, etiqueta: 'Resumen'},
        ]
    })

    const onSelectEstado = (item, tipo) => {
        Tools.toolFetch({
            ruta: 'rt_ctg_cd',
            method: 'POST',
            body: item
        }, (response) => {
            if(tipo === 'origen'){
                setFormDirOr((s) => ({...s, listEstadoOr: item.clave}))
                setCatalogos((s) => ({
                    ...s,
                    ctgCdOr: response.datos
                }))
            }

            if(tipo === 'destino'){
                setFormDirDes((s) => ({...s, listEstadoDes: item.clave}))
                setCatalogos((s) => ({
                    ...s,
                    ctgCdDes: response.datos
                }))
            }
        })
    }

    const onChangeSucOr = (datos) => {
        let item = datos.item
        onSelectEstado({clave: item.ID_EDO_SUC || item.ID_EDO_DIR}, 'origen')
        setFormDirOr((s)=>({
            ...s,
            listDirOr: item.clave,
            listSucOr: item.ID_SUC,
            listEstadoOr: item.ID_EDO || item.ID_EDO_SUC,
            listCiudadOr: item.ID_CD || item.ID_CD_SUC,
            txtCalleOr: item.CALLE_DIR || item.CALLE_SUC,
            txtNumExtOr: item.NUM_EXT_DIR || item.NUM_EXT_SUC,
            txtNumIntOr: item.NUM_INT_DIR || item.NUM_INT_SUC,
            txtCpOr: item.CP_DIR || item.CP_SUC,
            txtColoniaOr: item.COLONIA_DIR || item.COLONIA_SUC,
            txtReferenciaOr: item.REFERENCIA_DIR || item.REFERENCIA_SUC,
            txtLatitudOr: item.LATITUD_DIR || item.LATITUD_SUC,
            txtLongitudOr: item.LONGITUD_DIR || item.LONGITUD_SUC,
            txtAliasOr: item.ALIAS_DIR
        }))
        setTimeout(() => {
            Tools.getForm('formOrigen', objetoOrigen)
        }, 1000)
    }

    const onChangeSucDes = (datos) => {
        let item = datos.item
        onSelectEstado({clave: item.ID_EDO_SUC || item.ID_EDO_DIR}, 'destino')
        setFormDirDes((s) => ({
            ...s,
            listDirDes: item.clave,
            listSucDes: item.ID_SUC,
            txtAliasDes: item.ALIAS_DIR,
            listEstadoDes: item.ID_EDO || item.ID_EDO_SUC,
            listCiudadDes: item.ID_CD || item.ID_CD_SUC,
            txtCalleDes: item.CALLE_DIR || item.CALLE_SUC,
            txtNumExtDes: item.NUM_EXT_DIR || item.NUM_EXT_SUC,
            txtNumIntDes: item.NUM_INT_DIR || item.NUM_INT_SUC,
            txtCpDes: item.CP_DIR || item.CP_SUC,
            txtColoniaDes: item.COLONIA_DIR || item.COLONIA_SUC,
            txtReferenciaDes: item.REFERENCIA_DIR || item.REFERENCIA_SUC,
            txtLatitudDes: item.LATITUD_DIR || item.LATITUD_SUC,
            txtLongitudDes: item.LONGITUD_DIR || item.LONGITUD_SUC,
        }))
        setTimeout(() => {
            Tools.getForm('formDestino', objetoDestino)
        }, 1000)
    }

    const onChangeRemDc = (datos) => {
        let item = datos.item
        setFormDCOr((s) => ({
            ...s,
            listDatosContOr: item.clave,
            txtNombresOr: item.NOMBRES_CLC,
            txtApllidosOr: item.APELLIDOS_CLC,
            txtCorreoOR: item.CORREO_CLC,
            txtCelularOr: item.CELULAR_CLC,
            txtTelOr: item.TELEFONO_CLC
        }))
        setTimeout(() => {
            Tools.getForm('formRemitente', objetoRemitente)
        }, 100)
    }

    const onChangeDesDc = (datos) => {
        let item = datos.item
        setFormDCDes((s) => ({
            ...s,
            listDatosContDes: item.clave,
            txtNombresDes: item.NOMBRES_CLC,
            txtApllidosDes: item.APELLIDOS_CLC,
            txtCorreoDes: item.CORREO_CLC,
            txtCelularDes: item.CELULAR_CLC,
            txtTelDes: item.TELEFONO_CLC
        }))
        setTimeout(() => {
            Tools.getForm('formDestinatario', objetoDestinatario)
        }, 100)
    }

    const onChangeTipoEnvio = (datos) => {
        setFormDirOr({})
        setFormDirDes({})
        setSelectTipoServ(datos.item)
    }

    const onChangePesoVol = (datos) => {
        let item = datos.item
        setFormCarga((s) => ({
            ...s,
            listPesoVol: item.clave
        }))
    }

    const validarOrigenDestino = async (direccion, sigPaso) => {
        if(!(sigPaso === 2)){
            return false
        }

        let formOrigen = Tools.getForm('formOrigen', objetoOrigen)
        let formDestino = Tools.getForm('formDestino', objetoDestino)
        let strOrDes = ''

        setFormDirOr((s) => ({...s, ...formOrigen.data}))
        setFormDirDes((s) => ({...s, ...formDestino.data}))

        if(direccion === -1){
            return false
        }

        if(formOrigen.formIsEmpty){
            strOrDes += `Origen (${formOrigen.stringIsEmpty})`
        }

        if(formDestino.formIsEmpty){
            strOrDes += `${strOrDes.length?', ':''}Destino (${formDestino.stringIsEmpty})`
        }

        if(strOrDes.length){
            notie.alert({ type: 'warning', text: `Falta completar ${strOrDes}`, time: 2 })
            return true
        }

        let cobertura = await new Promise((resolve, reject) => {
            Tools.toolFetch({
                ruta: 'rt_envio_or_des',
                method: 'POST',
                body: {
                    txtCpOr: formOrigen.data.txtCpOr,
                    txtCpDes: formDestino.data.txtCpDes
                }
            }, (response) => {
                if(response.estatus === 'SIN-COBERTURA'){
                    notie.alert({ type: 'warning', text: `Sin cobertura para el Origen y Destino.`, time: 2 })
                    return resolve(true)
                }

                setSelectGrupos(response.datos)
                resolve(false)
            })
        })

        return cobertura
    }

    const validarRD = (direccion, sigPaso) => {
        if(!(sigPaso === 3)){
            return false
        }

        let formRemitente = Tools.getForm('formRemitente', objetoRemitente)
        let formDestinatario = Tools.getForm('formDestinatario', objetoDestinatario)
        let strOrDes = ''

        setFormDCOr((s) => ({...s, ...formRemitente.data}))
        setFormDCDes((s) => ({...s, ...formDestinatario.data}))

        if(direccion === -1){
            return false
        }

        if(formRemitente.formIsEmpty){
            strOrDes += `Remitente (${formRemitente.stringIsEmpty})`
        }

        if(formDestinatario.formIsEmpty){
            strOrDes += `${strOrDes.length?', ':''}Destinatario (${formDestinatario.stringIsEmpty})`
        }

        if(strOrDes.length){
            notie.alert({ type: 'warning', text: `Falta completar ${strOrDes}`, time: 2 })
            return true
        }

        return false
    }

    const validarInfoCarga = async (direccion, sigPaso) => {
        if(!(sigPaso === 4)){
            return false
        }

        let _formCarga = Tools.getForm('formCarga', objetoCarga)
        if(direccion === -1){
            return false
        }

        if(_formCarga.formIsEmpty){    
            notie.alert({ type: 'warning', text: `Falta completar (${_formCarga.stringIsEmpty})`})
            return true
        }

        let cobertura = await new Promise((resolve, reject) => {
            Tools.toolFetch({
                ruta: 'rt_envio_precios',
                method: 'POST',
                body: {
                    idPs: servicio.datos.ID_PS,
                    cobZona: selectGrupos.cobZona,
                    txtPesoReal: formCarga.txtPesoReal,
                    txtPesoVol: formCarga.txtPesoVol
                }
            }, (response) => {
                let datos = response.datos
                if(response.estatus === 'NO-PRECIO'){
                    notie.alert({ type: 'warning', text: `No hay precios asignados para este servicio`})
                    return resolve(true)
                }

                let _precio = {
                    cantidad: 1,
                    precio: datos.montos.SUBTOTAL,
                    txtSubtotal: datos.montos.SUBTOTAL,
                    txtIva: datos.montos.IVA,
                    txtTotal: datos.montos.TOTAL
                }

                Tools.local.addObjectJson('precio', _precio)
                setPrecio(_precio)

                resolve(false)
            })
        })

        return cobertura
    }

    const limpiarLocalStorage = () => {
        localStorage.removeItem('servicio')
        localStorage.removeItem('selectTipoServ')
        localStorage.removeItem('formDirOr')
        localStorage.removeItem('formDirDes')
        localStorage.removeItem('formDCOr')
        localStorage.removeItem('formDCDes')
        localStorage.removeItem('formCarga')
        localStorage.removeItem('precio')
        localStorage.removeItem('sigPaso')
        localStorage.removeItem('catalogos')
        localStorage.removeItem('nuevoEnvio')
        localStorage.removeItem('selectGrupos') 
        localStorage.removeItem('idEditar')       
        // localStorage.removeItem('selectCliente')
    }

    const onEnviar = () => {
        notie.confirm({
            text: `Servicio listo para agregar al carrito ¿Esta de acuerdo?`,
            submitText: 'Si, Agregar',
            cancelText: 'No, Cancelar',
            cancelCallback: function () {
            },
            submitCallback: function () {
                let item = {
                    servicio,
                    selectTipoServ,
                    selectCliente,
                    formDirOr,
                    formDirDes,
                    formDCOr,
                    formDCDes,
                    formCarga:{...formCarga, ...selectGrupos},
                    precio,
                    nuevoEnvio: 1
                }

                limpiarLocalStorage()

                setTimeout(() => {
                    window.location.reload('.')
                }, 2000)

                if((idEditar !== null)){
                    let _envio = Tools.local.getObjectJson('envio')
                    _envio[idEditar] = item
                    Tools.local.addObjectJson('envio', _envio)
                    
                    notie.alert({ type: 'success', text: `Se actualizo el servicio producto`, time: 2 })
                    return
                }

                notie.alert({ type: 'success', text: `Servicio agregado al carrito`, time: 2 })

                Tools.local.addItemObjectJson('envio', item)
            }
        })
    }

    const onClickSig = async (direccion) => {
        let sig = sigPaso.indice + direccion

        if(sig < 1){
            return
        }

        if(sig > sigPaso.pasos.length){
            onEnviar()
            return
        }

        // 1 - Validar tipo de envío
        if(direccion > 0 && sigPaso.indice === 1 && !selectTipoServ.clave){
            notie.alert({ type: 'warning', text: 'Debe de seleccionar tipo de servicio.', time: 2 })
            return
        }else{
            Tools.local.addObjectJson('catalogos', catalogos)
            Tools.local.addObjectJson('selectTipoServ', selectTipoServ)
            Tools.local.addObjectJson('servicio', servicio)
            Tools.local.addObjectJson('selectGrupos', selectGrupos)
            Tools.local.addObjectJson('selectCliente', selectCliente)
            Tools.local.addObjectJson('nuevoEnvio', 1)
        }

        // 2 - Validación de Origen - Destino
        let VOD = await validarOrigenDestino(direccion, sigPaso.indice)
        if(VOD){
            return
        }else{
            Tools.local.addObjectJson('formDirOr', formDirOr)
            Tools.local.addObjectJson('formDirDes', formDirDes)
        }

        // 3 - Validación de Remitente - Destinatario
        let RD = validarRD(direccion, sigPaso.indice)
        if(RD){
            return
        }else{
            Tools.local.addObjectJson('formDCOr', formDCOr)
            Tools.local.addObjectJson('formDCDes', formDCDes)
        }

        // 4 - Validación de Carga
        let IC = await validarInfoCarga(direccion, sigPaso.indice)
        if(IC){
            return
        }else{
            Tools.local.addObjectJson('formCarga', formCarga)
        }

        Tools.local.addObjectJson('sigPaso', {...sigPaso, indice: sig})

        setSigPaso((s) => ({...s, indice: sig}))
    }

    const init = () => {
        let _selectTipoServ =Tools.local.getObjectJson('selectTipoServ')
        let _formDirOr = Tools.local.getObjectJson('formDirOr')
        let _formDirDes = Tools.local.getObjectJson('formDirDes')
        let _formDCOr = Tools.local.getObjectJson('formDCOr')
        let _formDCDes = Tools.local.getObjectJson('formDCDes')
        let _formCarga = Tools.local.getObjectJson('formCarga')
        let _precio = Tools.local.getObjectJson('precio')
        let _sigPaso = Tools.local.getObjectJson('sigPaso')
        let _catalogos = Tools.local.getObjectJson('catalogos')
        let _servicio = Tools.local.getObjectJson('servicio')
        let _selectGrupos = Tools.local.getObjectJson('selectGrupos')
        let _selectCliente = Tools.local.getObjectJson('selectCliente')

        if(_selectGrupos){
            setSelectGrupos(_selectGrupos)            
        }

        if(_servicio){
            setServicio(_servicio)            
        }

        if(_catalogos){
            setCatalogos(_catalogos)
        }

        if(_sigPaso){
            setSigPaso(_sigPaso)
        }

        if(_selectCliente){
            onChangeCliente(_selectCliente)
        }

        if(_selectTipoServ){
            setSelectTipoServ(_selectTipoServ)
        }

        if(_formDirOr){
            setFormDirOr(_formDirOr)
            onSelectEstado({clave: _formDirOr.listEstadoOr}, 'origen')
        }

        if(_formDirDes){
            setFormDirDes(_formDirDes)
            onSelectEstado({clave: _formDirDes.listEstadoDes}, 'destino')
        }

        if(_formDCOr){
            setFormDCOr(_formDCOr)
        }

        if(_formDCDes){
            setFormDCDes(_formDCDes)
        }

        if(_formCarga){
            setFormCarga(_formCarga)
        }
        
        if(_precio){
            setPrecio(_precio)
        }
    }

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_ctg_envio',
            method: 'POST',
            body: {
            }
        }, (response) => {
            init()
            setCatalogos((s) => ({...s, ...response.datos}))
        })
    }, [])

    return (
        <Fragment>
            {/* Pasos */}
            <div className="col-sm-12 mb-3">
                <div className="u-pearls-lg row d-flex justify-content-center">
                    {sigPaso.pasos.map((v) => (
                        <div key={v.numero+v.etiqueta} className={`u-pearl ${v.numero === sigPaso.indice?'current':(v.numero > sigPaso.indice?'':'done')} col-2`}><span className="u-pearl-number">{v.numero}</span><span className="u-pearl-title">{v.etiqueta}</span></div>
                    ))}
                </div>
            </div>
            {/* Resumen */}
            {sigPaso.indice === 5?
            <EnvioPagos
                precio={precio}
            />
            :''}
            {/* Cliente */}
            {sigPaso.indice === 1 || sigPaso.indice === 5?
            <EnvioTipoServicio 
                catalogos={catalogos}
                onChangeCliente={onChangeCliente}
                onChangeTipoEnvio={onChangeTipoEnvio}
                selectCliente={selectCliente}
                setSelectCliente={setSelectCliente}
                selectTipoServ={selectTipoServ}
                esResumen={sigPaso.indice === 5}
                idEditar={idEditar}
            />
            :''}
            {/* Origen - Destino */}
            {sigPaso.indice === 2 || sigPaso.indice === 5?
            <EnvioOrigenDestino 
                catalogos={catalogos}
                onSelectEstado={onSelectEstado}
                formDirOr={formDirOr}
                formDirDes={formDirDes}
                onChangeSucOr={onChangeSucOr}
                onChangeSucDes={onChangeSucDes}
                selectTipoServ={selectTipoServ}
                setFormDirOr={setFormDirOr}
                setFormDirDes={setFormDirDes}
                esResumen={sigPaso.indice === 5}
            />
            :''}
            {/* Datos de contacto */}
            {sigPaso.indice === 3 || sigPaso.indice === 5?
            <EnvioDatosContacto
                catalogos={catalogos}
                onChangeRemDc={onChangeRemDc}
                onChangeDesDc={onChangeDesDc}
                formDCOr={formDCOr}
                formDCDes={formDCDes}
                esResumen={sigPaso.indice === 5}
            />
            :''}
            {/* Peso volumetrico */}
            {sigPaso.indice === 4 || sigPaso.indice === 5?
            <EnvioInfoCarga
                catalogos={catalogos}
                formCarga={formCarga}
                setFormCarga={setFormCarga}
                onChangePesoVol={onChangePesoVol}
                esResumen={sigPaso.indice === 5}
            />
            :''}
            {/* Envíar */}
            <Fragment>
                <div className='row mb-3 mt-5'>
                    <div className='col-sm-12 col-md-12 d-flex justify-content-end'>
                        <button type="button" className="btn btn-regresar me-auto" onClick={() => onClickSig(-1)}><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                        <button type="button" className="btn btn-back me-3"
                        onClick={() => {
                            limpiarLocalStorage()
                            window.location.reload('.')
                        }}>Cancelar</button>
                        <button type="button" className="btn btn-purple" onClick={() => onClickSig(1)}>{sigPaso.pasos.length === sigPaso.indice?(idEditar !== null?'Actualizar Envío':'Realizar Envío'):'Siguiente'}</button>
                    </div>
                </div>
            </Fragment>
        </Fragment>
    )
}

export default Default;