
import React, { Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumbv2'
import Table from '../common/tabla'
import { Briefcase } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import Tools from '../../js/tools'
import notie from 'notie'
import tools from '../../js/tools';

const Default = ({
    esPrincipal,
    setSelected
}) => {
    const [esRefresh, setEsRefresh] = useState(true)
    const navigate = useNavigate()
    const params = useParams();

    const eliminar = (id) => {
        Tools.toolFetch({
            ruta: 'rt_empresas_df_eliminar',
            method: 'POST',
            body: {id}
        }, (response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió un detalle en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro eliminado con exito`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return <Fragment>
        {esPrincipal?<Breadcrumb icon={Briefcase} title={'DATOS FISCALES'} parent={['empresa','datos-fiscales']} />:''}
        <div className="card">
            <div className="col-xl-12 col-md-12">
                {/* <div className="card-header d-flex justify-content-center p-3">
                    <h4><strong>DATOS FISCALES</strong></h4>
                </div> */}
                <div className="mt-3">
                    <Table
                        noRutaDinamica={true}
                        cargando={false}
                        htmlBtnPag={
                            <button
                                className="btn btn-purple btn-nuevo"
                                type="button"
                                onClick={() => {
                                    if(setSelected){
                                        navigate(`/empresa/${params.empresa}/datos-fiscales/nuevo`)
                                        setSelected('df-form')
                                    }else{
                                        navigate('/empresa/datos-fiscales/nuevo')
                                    }
                                }}
                            > Nuevo registro
                            </button>}
                        headers={[
                            {
                                nombre: 'DATOS FISCALES', tipo: 'html', texto: 'ALIAS_EDF', html: (d) => {
                                    return <div>
                                        <strong>{d.ALIAS_EDF}</strong>
                                    </div>
                                }
                            },
                            {
                                nombre: 'CORREO', tipo: 'html', texto: 'CORREO_EDF', html: (d) => {
                                    return <div>
                                        {d.CORREO_EDF}
                                    </div>
                                }
                            },
                            {
                                nombre: 'RAZON SOCIAL', tipo: 'html', texto: 'RAZON_SOCIAL_EDF', html: (d) => {
                                    return <div>
                                        {d.RAZON_SOCIAL_EDF}
                                    </div>
                                }
                            },
                            {
                                nombre: 'RFC', tipo: 'html', texto: 'RFC_EDF', html: (d) => {
                                    return <div>
                                        {d.RFC_EDF}
                                    </div>
                                }
                            },
                            {
                                nombre: 'DETALLE', tipo: 'html', texto: 'Detalle',
                                html: (d) => {
                                    return <div>
                                        <BtnVer
                                            onClick={() => {
                                                if(setSelected){
                                                    setSelected('df-form-editar')
                                                    navigate(`/empresa/${params.empresa}/datos-fiscales/${Tools.urlFormat(d.ALIAS_EDF)}`)
                                                }else{
                                                    navigate(`/empresa/datos-fiscales/${Tools.urlFormat(d.ALIAS_EDF)}`)
                                                }
                                            }}
                                        />
                                        <BtnEliminar
                                            onClick={() => {
                                                notie.confirm({
                                                    text: `Se eliminara <strong>${d.ALIAS_EDF}</strong> ¿estas de acuerdo?`,
                                                    submitText: 'Si, eliminar',
                                                    cancelText: 'No, cancelar',
                                                    cancelCallback: function (value) {
                                                    },
                                                    submitCallback: function (value) {
                                                        eliminar(d.ID_EDF)
                                                    },
                                                })
                                            }}
                                        />
                                    </div>
                                }
                            }
                        ]}
                        totalField={'NR'}
                        path={'/rt_empresas_df'}
                        refresh={esRefresh}
                        body={{empresa: params.empresa}}
                        rowsPerPage={20}
                        paginadorTop={true}
                    >
                    </Table>
                </div>
            </div>
        </div>
    </Fragment>
}

export default Default