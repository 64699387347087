import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import Tools from '../../js/tools'
import Table from '../common/tabla'
import Breadcrumb from '../common/breadcrumbv2';
import { CreditCard } from 'react-feather';
import notie from 'notie'

const Default = (props) => {
    const navigate = useNavigate()
    const [esRefresh, setEsRefresh] = useState(true)

    const onClickEliminar = (d) => {
        notie.confirm({
            text: `Se eliminara <strong>${d.EMPRESA_CL}</strong> ¿estas de acuerdo?`,
            submitText: 'Si, eliminar',
            cancelText: 'No, cancelar',
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_cliente_eliminar',
                    method: 'POST',
                    body: { id: d.ID_CL },
                }, (response) => {  
                    if (response.estatus === 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = false
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }
                    
                    setEsRefresh((s) => !s)
                })
            },
            cancelCallback: function (value) {  
            },
        })
    }

    const onClickReporte = (tipo) => {
        let filename = ''
        fetch('/rt_generar_reporte_empresas', {
            method: "POST",
            body: JSON.stringify({ tipo_reporte: tipo }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(async (res) => {
                if (res.status !== 200) {
                    return { estado: 0, ...(await res.json()) }
                } else {
                    filename = res.headers.get('filename')
                    return { estado: 1, blob: await res.blob() }
                }
            }).then((response) => {
                if (response.estado) {
                    let url = window.URL.createObjectURL(response.blob),
                        a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                } else {
                    // Toast.onClickToastWarn({ html: <div>No se pudo descargar el archivo vuelva a intentar</div> })
                }
            })
            .catch((error) => {
                // Toast.onClickToastWarn({ html: <div>No se pudo descargar el archivo vuelva a intentar</div> })
            })
    }

    return (
    <Fragment>
        <Breadcrumb icon={CreditCard} title={'Clientes'} parent={['clientes']} />
        <div className="card d-print-none" >
            <div className="mt-3">
                <Table 
                    cargando={false}
                    htmlBtnPag={
                        <button
                            className="btn btn-purple btn-nuevo"
                            type="button"
                            onClick={()=>{navigate('/clientes/nuevo')}}
                        > Nuevo cliente
                        </button>}
                    headers={[
                        {
                            nombre: 'CLIENTE', tipo: 'html', texto: 'EMPRESA_CL', html: (d) => {
                                return <div style={{width: '25em'}}><strong>{d.EMPRESA_CL}</strong></div>
                            }
                        },
                        {
                            nombre: 'CONTACTO', tipo: 'html', texto: 'CONTACTO', html: (d) => {
                                return <div className='d-flex align-items-start'>
                                    <p>
                                        <strong><span style={{ textTransform: 'uppercase' }}>{d.NOMBRES_CONTACTO} {d.APELLIDOS_CONTACTO}</span></strong>
                                        <br />{d.TELEFONO? `TEL. ${d.TELEFONO}`: ''}
                                        <br />{d.CELULAR ? `CEL. ${d.CELULAR}` : ''}
                                    </p>
                                </div>
                            }
                        },
                        {
                            nombre: 'CORREO', tipo: 'html', texto: 'CORREO', html: (d) => {
                                return <div>{d.CORREO}</div>
                            }
                        },
                        {
                            nombre: 'DETALLE', tipo: 'html', texto: 'DETALLE',
                            html: (d) => {
                                return <div>
                                    <BtnVer
                                        onClick={() => {navigate('/clientes/'+Tools.urlFormat(d.EMPRESA_CL))}}
                                    />
                                    <BtnEliminar
                                        onClick={() => onClickEliminar(d)}
                                    />
                                </div>
                            }
                        }
                    ]}
                    totalField={'NR'} /* NR = número de resultados totales */
                    path={'rt_clientes'}
                    refresh={esRefresh}
                    body={{}}
                    rowsPerPage={20}
                    paginadorTop={true}
                >
                </Table>
            </div>
        </div>
    </Fragment>
    )
}

export default Default;