import React, { Fragment, useState, useEffect } from 'react'
import { Archive, Users, Navigation, CreditCard, DollarSign } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumb from '../common/breadcrumbv2';
import Contacto from './contactos/contactos';
import ClienteEditar from './clientes_editar'
import DireccionesEntrega from './direcciones_entrega/direcciones'
import DatosFiscales from './datos_fiscales/datos_fiscales'
import ClientesPrecios from './clientes_precios'
import Tools from '../../js/tools'
import LoaderSeccion from '../../components/common/loader_secciones';

const Default = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const strUrlParent = 'Clientes'
    const [formCliente, setFormCliente] = useState({})
    const [catalogos, setCatalogos] = useState({
        ctgEdo: [],
        ctgDirCd: [],
        ctgDfRegimenFiscal: [],
        ctgDfUsoCfi: []
    })

    useEffect(() => {
        let isSubscribed = true

        Tools.toolFetch({
            ruta: 'rt_clientes_detalle',
            method: 'POST',
            body: { id_cliente: params.id },
        }, (response) => {
            if (isSubscribed) {
                if(response.estatus === 'ERROR'){
                    return navigate('/clientes')
                }

                let datos = response.datos

                if (datos.cliente.length === 0) {
                    return navigate('/clientes')
                }

                if (datos.cliente.length === 0) {
                    return navigate('/clientes')
                }

                setFormCliente(datos.cliente)
                setCatalogos((s) => ({
                    ...s, 
                    ctgEdo: datos.ctgEdo,
                    ctgDfRegimenFiscal: datos.ctgDfRegimenFiscal,
                    ctgDfUsoCfi: datos.ctgDfUsoCfi,
                    ctgProdServ: datos.ctgProdServ,
                    ctgFactor: datos.ctgFactor
                }))
            }
        })

        return () => isSubscribed = false
    }, [])

    return (
        <Fragment>
            <LoaderSeccion status={formCliente} />
            <Breadcrumb icon={CreditCard} title={formCliente ? formCliente.EMPRESA_CL : strUrlParent} parent={['clientes']} />
            <div className="row theme-tab">
                <Tabs className="col-sm-12" >
                    <TabList className="tabs tab-title">
                        <Tab><CreditCard /> CLIENTES</Tab>
                        <Tab><Navigation /> DIRECCIONES DE ENTREGA</Tab>
                        <Tab><Archive/> DATOS FISCALES</Tab>
                        <Tab><Users /> DATOS DE CONTACTO</Tab>
                        <Tab><DollarSign /> PRECIOS</Tab>
                    </TabList>
                    <div className="tab-content-cls">
                        <TabPanel>
                            <ClienteEditar
                                formCliente={formCliente}
                                setFormCliente={setFormCliente}
                            />
                        </TabPanel>
                        <TabPanel>
                            <DireccionesEntrega 
                                formCliente={formCliente}
                                catalogos={catalogos} 
                                setCatalogos={setCatalogos}
                            />
                        </TabPanel>
                        <TabPanel>
                            <DatosFiscales 
                              formCliente={formCliente}
                              catalogos={catalogos} 
                              setCatalogos={setCatalogos}
                            />
                        </TabPanel>
                        <TabPanel >
                            <Contacto 
                              formCliente={formCliente}
                            />
                        </TabPanel>
                        <TabPanel >
                            <ClientesPrecios 
                              formCliente={formCliente}
                              catalogos={catalogos}
                            />
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        </Fragment>)
}

export default Default;