import React, { Fragment, useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useLocation } from "react-router-dom";
// import NoImg from '../../assets/images/product/14.png'
import Select from "react-select";
import { formatoDinero } from "../../js/funciones";
import tools from "../../js/tools";

const TablaPro = (props) => {
	const noRutaDinamica = props.noRutaDinamica
	/* ============== PROPIEDADES ============== */
	const opcionesCantidadResultados = props.resultsOptions || [
		{ value: "10", label: "10" },
		{ value: "20", label: "20" },
		{ value: "30", label: "30" },
		{ value: "40", label: "40" },
	];

	const propRow = props.row? props.row: {}

/* 	const clasesTipos = {
		"0": "pendiente",
		"1": "transferencia",
		"2": "paypal",
		"3": "link",
		"4": "terminal",
		"5": "pendiente",
		"6": "terminal",
	}

	const clasesEstatus = {
		"danger": "cancelado",
		"1": "pendiente",
		"2": "pagado",
		"3": "revisar",
		"4": "pendiente",
		"5": "cortesia",
	}
 */
	const [loading, setLoading] = useState(props.loading || true);

	const [headers, setHeaders] = useState(props.headers);// props.headers;

	const [rows, setRows] = useState(props.rows || []);

	const [path, setPath] = useState(props.path);
	
	const [method, setMethod] = useState(props.method || 'POST');

	const [checked, setChecked] = useState({});
	const [totalChecked, setTotalChecked] = useState(0);
	const [checkedTotal, setCheckedTotal] = useState(0);

	// const [body, setBody] = useState(props.body);
	let body = props.body;

	const [totalField] = useState(props.totalField || 'CANTIDAD_RESULTADOS')

	// Paginador
	const [dataPagination, setDataPagination] = useState(props.dataPagination || {
		page: 1,
		pages: [],
		lastPage: 1,
		rowsPerPage: props.rowsPerPage? props.rowsPerPage: 10
	})

	// query params
	const search = useLocation().search;
	const search_params = new URLSearchParams(search);
	const ruta = useLocation().pathname;

    let timeout, busqueda_pendiente = '';
    const [filtros, setFiltros] = useState({
        b: ''
    })

	/* ============== USEEFFECT ============== */

	useEffect(() => {
		setHeaders(props.headers)
	}, [props.headers.length])

	useEffect(() => {
		/*	for (param of temp1.keys()) {
			console.log(param)
		} */
		// const ac = new AbortController();

        let isSubscribed = true
		let pag = getParamsJson(window.location.href)

		let s_pagina			 = pag.pagina? pag.pagina: search_params.get('page'),
			s_filas				 = search_params.get('rowsPerPage');
		
		if(isSubscribed){
			if(search_params.get('page') < 2){
				s_pagina = 1
			}

			cargarRegistros(s_pagina || 1, s_filas || dataPagination.rowsPerPage)
		}

		return () => isSubscribed = false
		// return () => ac.abort();
	}, [props.refresh, filtros.b])

	/* ============== FUNCIONES ============== */

	const selectCantidadResultados = (value) => {
		setDataPagination((s) => ({...s, rowsPerPage: value.value}));
	}

	const onClickChecked = (fila) => {
		let claveChecked = fila['CLAVE_CHECK']
		let item = `{"${claveChecked}":${JSON.stringify(fila)}}`
		
		if (fila.checked) {
			item = `{"${claveChecked}":0}`
			delete checked[claveChecked]
		}
		
		item = JSON.parse(item)

		let total = 0
		setChecked((s) => {
			total = Object.values({ ...s, ...item }).filter((v) => v != 0).length
			setTotalChecked(total)
			if(item[claveChecked]){
				return { ...checked, ...item }
			}else{
				return { ...checked }
			}
		})
		let _rows = rows.map((v) => ({...v, checked: v.CLAVE_CHECK === claveChecked? item[v.CLAVE_CHECK]: v.checked}))
		setRows(_rows)
	}

	const construirElementos = () => {
		if(rows.length == 0){
			return <tr key={`key_fila_tabla`} style={{cursor: 'pointer'}}>
				<td colSpan={headers.length} key={`key_fila_tabla_td`} 					>
					<div className="text-center">
						<h5>Sin registros</h5>
					</div>
				</td>
			</tr>
		}
		
		return rows.map((fila, i) => // e => elemento, i => index
			<tr key={`key_fila_tabla${i}`} style={{cursor: 'pointer'}}>
			{
				headers.map((h, j) => {
					if(h.tipo === 'check'){
						return <td
						style={h.style?h.style:{}}
						key={`key_fila_tabla_td${i}${j}`}
						onClick={() => onClickChecked(fila)}
						>
							<div className="form-check">
								<input 
									type="checkbox"
									style={h.chkStyle?h.chkStyle:{}}
									className="form-check-input" 
									checked={checkedTotal?(fila.checked?'':checked[fila.CLAVE_CHECK]?'':'on'):(fila.checked?'on':checked[fila.CLAVE_CHECK]?'on':'')}
									onChange={() => onClickChecked(fila)}
								/>
							</div>
						</td>
					}

					if(!h.tipo || h.tipo === 'individual')
						return <td 
						style={h.style?h.style:{}}
						key={`key_fila_tabla_td${i}${j}`}
						onClick={(e) => {
							if(propRow.onClick)
								if(!propRow.ignoreColumns.includes(h.texto))
									propRow.onClick(e, fila)
						}}>
							{
								h.formatear ? // si se envía este parámetro que es una función, se manda la fila como parámetro al llamarla
									h.formatear(fila)
								:
								h.formato === 'dinero' ?
									`$${formatoDinero(fila[h.texto])}`
									:
									fila[h.texto]
							}
						</td>

					if(!h.tipo || h.tipo === 'normal')
						return <td 
						style={h.style?h.style:{}}
						key={`key_fila_tabla_td${i}${j}`} 
						onClick={(e) => {
							if(propRow.onClick)
								if(!propRow.ignoreColumns.includes(h.texto))
									propRow.onClick(e, fila)
						}}
						>
							<div>
								<strong>{fila[h.titulo]}</strong>
								<br />
								<small>{fila[h.texto]}</small>
							</div>
						</td>

					if(h.tipo === 'categoria')
						return <td 
						style={h.style?h.style:{}}
						key={`key_fila_tabla_td${i}${j}`}
						onClick={(e) => {
							if(propRow.onClick)
								if(!propRow.ignoreColumns.includes(h.texto))
									propRow.onClick(e, fila)
						}}
						>
							<div className={`alert alert-light p-2 m-0 rounded ${h.clases[fila[h.categoria]]}`} role="alert">
								{fila[h.texto]}
							</div>
						</td>

					if(!h.tipo || h.tipo === 'html')
						return <td 
						style={h.style? h.style:{}}
						key={`key_fila_tabla_td${i}${j}`} 
						onClick={(e) => {
							if(propRow.onClick)
								if(!propRow.ignoreColumns.includes(h.texto))
									propRow.onClick(e, fila)
						}}
						>
							{h.html? h.html(fila): ''}
						</td>

					if(h.tipo === 'acciones')
						return <td 
						style={h.style?h.style:{}}
						key={`key_fila_tabla_td${i}${j}`} 
						className="detalle-col text-left"
						onClick={(e) => {
							if(propRow.onClick)
								if(!propRow.ignoreColumns.includes(h.texto))
									propRow.onClick(e, fila)
						}}
						>
						{
							h.acciones.map((a, k) => {
								let objeto;
								if(a.params && Array.isArray(a.params) && a.params.length){
									objeto = {};
									a.params.forEach(param => {
										objeto[param] = fila[param];
									});
								}

								return <button key={`key_fila_tabla_btn${i}${j}${k}`} type="button" className="btn btn-detalle rounded-pill m-1" onClick={a.onClick ? () => {a['onClick'](objeto)} : null}>
									{a['texto']}
								</button>
							})
						}
						</td>
				})
			}
			</tr>
		)
	}

	const construirElementosCargando = () => {
		let arreglo = [], i;
		for(i = 0; i < dataPagination.rowsPerPage; i++){
			arreglo.push(
			<tr key={`key_fila_cargando${i}`}>
			{
				headers.map((h, j) => {
					if(h.tipo === 'categoria')
						return <td key={`key_fila_td_cargando${i}${j}`}>
							<div>
								<div className="skeleton skeleton-text skeleton-footer"></div>
								<div className="skeleton skeleton-text skeleton-text__body"></div>
							</div>
						</td>

					else
						return <td key={`key_fila_td_cargando${i}${j}`} className="detalle-col">
							<div className="skeleton skeleton-text skeleton-badge"></div>
						</td>
				})
			}
			</tr>
			)
		}
		return arreglo;
	}

	const construirElementosCargandoCards = () => {
		let arreglo = [], i;
		for(i = 0; i < dataPagination.rowsPerPage; i++){
			arreglo.push(
				<div className={`col-xl-3 col-sm-6 xl-4 col-grid-box`} key={'rowSlide'+i}>
					<div className="card" >
						<div className="product-box">
							<div className="product-img">
								<div className="skeleton skeleton-text skeleton-card"></div>
							</div>
						</div>
					</div>
				</div>
			)
		}
		return arreglo;
	}

	const getParamsJson = (url, esUrl) => {
		var arr = url.split('?')? url.split('?'): []
		url = arr[0]
		var strParams = arr[1]
		var arrParams = strParams? strParams.split('&'): []
		var _json = {}
		if(esUrl){
			_json['url'] = url
		}
		arrParams.map((v) => {
			var arrItem = v.split('=')
			_json[arrItem[0]] = arrItem[1]
		})
		return _json
	}
	
	const cargarRegistros = (pagina, n_filas) => {
		// console.log('Cargar registros', JSON.stringify(({...body, pagina, cantidad_resultados: n_filas})));
		// console.log('pagina', pagina);
		// console.log('n_filas', n_filas);
        let isSubscribed = true

		if(isSubscribed){
			setLoading(true);
		}
		//pagina = body.pagina == 0 && body.BUSQUEDA.length == 0? body.pagina: pagina

        fetch(path, {
			method,
			body: method === 'GET' ? null : JSON.stringify(({...(body), ...filtros, pagina, cantidad_resultados: n_filas})),
			headers: {'Content-Type': 'application/json'}
		}).then(res => res.json())
		.then(res =>  {
			if(isSubscribed){
				if(res.status === 'OK'){
					if(res.result[0] && res.result[0][totalField]){

						if(!noRutaDinamica){
							window.history.pushState({}, 'url', `${ruta}?pagina=${pagina}`)
						}

						let paginacion = infoPaginacion(pagina, res.result[0][totalField], n_filas);
						setDataPagination((s) => ({...s, page: pagina, pages: paginacion.paginas, lastPage: paginacion.total_paginas, rowsPerPage: n_filas}));
						setRows(res.result);
						setLoading(false);
					} else {
						setDataPagination((s) => ({...s, page: 1, pages: [], lastPage: 1, rowsPerPage: n_filas}));
						setRows([]);
						setLoading(false);
					}
				}else{
					setLoading(false);
				}
			}
		}).catch(err => {
			if(isSubscribed){
				setLoading(false);
			}
		})

		return () => isSubscribed = false
	}

    const evaluarEscribiendo = (e) => {
        if (timeout) {
            clearTimeout(timeout)
        }

        busqueda_pendiente = e.target.value;

		if(!noRutaDinamica){
			window.history.pushState({}, 'url', `${ruta}?pagina=1`)
		}

        timeout = setTimeout(() => {
            setFiltros((s) => ({ ...s, b: busqueda_pendiente }))
        }, 500)
    }

	// useEffect(() => {
	// 	let _rows = rows.map((v) => ({...v, checked: checked[v.CLAVE_CHECK]?true:false}))
	// 	setRows(_rows)
	// 	console.log('Entro', checked)
	// }, [checked])

	return (
	<Fragment>
		<div className="mt-2">
			<div className="row mt-2 mb-2">
                {!props.sinBuscador?
				<div className="col-lg-12 col-xl-6">
                    {/* <label style={{ fontWeight: 600, fontSize: 16 }} htmlFor="buscar">
                        BUSCAR
                    </label> */}
                    <div className="d-flex justify-content-between align-items-end">
					<div className='input-group input-gpack'>
					<input
                            id="input_buscador"
                            type="text"
                            className="form-control"
                            name="buscar"
                            aria-label=""
                            aria-describedby="basic-addon1"
							placeholder="Buscar..."
                            onKeyUp={evaluarEscribiendo}
                        />
                        <div className='icon-input'><span className="input-group-text"><i className="fa fa-search" aria-hidden="true"></i></span></div>
					</div>
                        
                        <span className='m-2'></span>
                        <button type="button" className="btn btn-green" onClick={() => {
                            window.document.getElementById('input_buscador').value = ''
                            setFiltros((s) => ({ ...s, pagina: 0, b: ''}))
                        }}>
                            <i className="fa fa-refresh"></i>
                        </button>
						{/* <span className='m-2'></span> */}
						{props.htmlBtn? props.htmlBtn: ''}
                    </div>
                </div>
				:''}
				{props.paginadorTop?
				<div className={`col-lg-12 col-xl-6 mt-4 mt-xl-0 selectnum d-flex align-items-end justify-content-end flex-wrap`}>
					{props.htmlBtnPag?
					props.htmlBtnPag
					:''}
					{props.seleccion?
					<button
						className={props.seleccion.className?props.seleccion.className:"btn btn-secondary btn-sm"}
						type="button"
						onClick={() => {
							props.seleccion.onClickSeleccion({checked, checkedTotal}, (datos) => {
								if(datos.estatus){
									setChecked((s) => ({}))
									setTotalChecked(0)

									if(checkedTotal){
										setCheckedTotal((s) => false)
									}

									setRows((s) => (s.map((v) => ({...v, checked: false}))))
								}
							})
						}}
					>{props.seleccion.btnNombre}
					</button>:''}
					{/* <span className='m-2'></span> */}
					<div className="pagin">
						<Paginador
							pagina={dataPagination.page}
							paginas={dataPagination.pages}
							ultima_pagina={dataPagination.lastPage}
							filasPorPagina={dataPagination.rowsPerPage}
							cargarDatos={(pagina, n_filas) => {cargarRegistros(pagina, n_filas)}}
							rows={rows}
						/>
					</div>
				</div>: ''}
			</div>
			{!props.modo || props.modo === 'table'?<div className="table-responsive prueba">
				<table className="table table-sticky">
					<thead>
						<tr>
					{
						headers.map((h, i) => {
							return <th key={`key_header	_tabla${i}`} scope="col" style={h.style?h.style:{}}>
								<div className="tab-header">
									<span className="mb-32">
										{!(h.tipo === 'check')?h.nombre:
											<Fragment>
												<div className="form-check">
													<input className="form-check-input" type="checkbox"
														checked={(checkedTotal?'on':'')}
														onChange={() => {
															if(checkedTotal){
																setTotalChecked(0)
															}
															
															setChecked((s) => ({}))
															setTimeout(() => setCheckedTotal((s) => !s), 100)
															setTimeout(() => setRows((s) => (s.map((v) => ({...v, checked: 0})))), 100)
														}}
													/>
													{h.nombre} {!checkedTotal?<Fragment>({totalChecked})</Fragment>:''}
												</div> 
											</Fragment>
										}
									</span>
								</div>
							</th>
						})
					}
						</tr>
					</thead>
					<tbody>
						{!loading && rows && body ?
							construirElementos()
							:
							construirElementosCargando()
						}
					</tbody>
				</table>
			</div>:''}
			{props.modo === 'card' ?
				<div className="row gridRow">
					{!loading && rows && body ? rows.map((item, i) =>
						<div className={`col-xl-3 col-sm-6 xl-4 col-grid-box`} key={'row'+i}>
							<div className="card" >
								<div className="product-box">
									<div className="product-img">
										{/* <span className="ribbon ribbon-bookmark ribbon-vertical-right ribbon-info">
											<i className="icon-heart">Test</i>
										</span>  */}
										{/* {(item.status === 'Hot') ?
											<span className="ribbon ribbon ribbon-clip ribbon-warning">
												{item.status}
											</span> : ''} */}
										{/* <img className="img-fluid" style={{height: '100%'}} alt="" src={
											(item[(headers.find((h) => {
												return h.tipo === 'img'												
											}) || {}).texto]) || NoImg
										}/> */}
										<div className="product-hover">
											<ul>
												{/* <li>
													<button className="btn" type="button" onClick={() => null} >
														<i className="icon-shopping-cart"></i>
													</button>
												</li> */}
												<li style={{cursor: 'pointer'}}>
													<button className="btn" type="button" data-toggle="modal"
														onClick={(e) =>props.modoProps.onClick?props.modoProps.onClick(item):null} data-target="#exampleModalCenter">
														<i className="icon-plus"></i>
													</button>
												</li>
												{/* <li>
													<button className="btn" type="button" onClick={() => null}>
														<i className="icon-heart"></i>
													</button>
												</li> */}
											</ul>
										</div>
									</div>
									<div className="product-details">
										{headers.map((h,i) => {
											if(h.tipo === 'html'){
												return h.html(item)
											}

											if(h.tipo === 'img'){
												return ''
											}

											if(h.tipo === 'titulo'){
												return <h5 key={'titulo'+i}>{item[h.texto]}</h5>
											}
											
											return <div key={'otro'+i}>{item[h.texto]}</div>
										})}
										{/* <h5>
											<a onClick={() => null} className="font-primary" href="#javascript">
												{item.name}
											</a></h5>
										<div className="product-price">
											<del>
											{item.discountPrice} </del>
											{item.price}
										</div> */}
									</div>
								</div>
							</div>
						</div>
					) : 
						construirElementosCargandoCards()
					}
				</div>
			: ''}
		</div>

		{(props.esPaginador === null || props.esPaginador === undefined) || props.esPaginador === true?
		<div className="row">
			<div className="col-12 selectnum d-flex align-items-center justify-content-end flex-wrap">
				<div className="select-number d-flex align-items-center me-3 mb-0">
					<label className="mb-0 mr-2">Elementos por página: </label>
					<Select 
						className="elementospagina"
						style={{ width: 40 }}
						options={opcionesCantidadResultados}
						placeholder="10"
						onChange={selectCantidadResultados}
						menuPlacement="auto"
					></Select>
				</div>
				<div className="pagin">
					<Paginador 
						pagina={dataPagination.page}
						paginas={dataPagination.pages}
						ultima_pagina={dataPagination.lastPage}
						filasPorPagina={dataPagination.rowsPerPage}
						cargarDatos={(pagina, n_filas) => {cargarRegistros(pagina, n_filas)}}
						rows={rows}
					/>
				</div>
			</div>
		</div>
		:''}
	</Fragment>
	)
}

function infoPaginacion (pag, n_resultados /* cantidad de resultados */, n_filas /* filas por página */) {
	pag = parseInt(pag)
	var cantidad = n_filas, array_paginas = []
	var i, fin, total = Math.ceil(n_resultados/cantidad), suma = true, content = ''

	if(pag - 2 < 1){
		i = 1
		fin = 5
	}else{
		i = pag - 2
		fin = pag + 2
	}

	if(fin > total){
		fin = total
	}
	if((fin - i) < 4){
		while((fin - i) < 4 && suma){
			suma = false
			if(i > 1){
				suma = true
				i--
			}
			if((fin - i) < 4 && fin < total){
				suma = true
				fin++
			}
		}
	}

	for(i; i <= fin; i++){
		array_paginas.push(i)
	}

	return {paginas: array_paginas, total_paginas: total}
}

const Paginador = ({pagina, paginas, ultima_pagina, cargarDatos, filasPorPagina, rows}) => {
	return <Pagination aria-label="Page navigation" className="pagination-primary new-pagination">
		<PaginationItem 
			disabled={pagina == 1 ? true: false}
			onClick={() => {
				if(pagina-1>=1){
					cargarDatos(1, filasPorPagina);
				}
			}} 
		>
			<PaginationLink className="bg-blue arrow">
				<i className="fa fa-angle-double-left"></i>
			</PaginationLink>
		</PaginationItem>
		
		<PaginationItem onClick={() => {
			if(pagina-1>1){
				cargarDatos(pagina-1, filasPorPagina)
			}
		}} 
			disabled={pagina == 1 ? true: false}>
			<PaginationLink  className="bg-blue arrow">
				<i className="fa fa-angle-left"></i>
			</PaginationLink>
		</PaginationItem>

		{
			paginas.map((pag, i) => {
				return <PaginationItem key={`PaginationItem${i}`} onClick={() => {cargarDatos(pag, filasPorPagina);}} className={pag == pagina ? "active": ""}>
			<PaginationLink >{tools.formatNumber(pag)}</PaginationLink>
		</PaginationItem>
			})
		}

		<PaginationItem 
			disabled={pagina == ultima_pagina ? true: false}
			onClick={() => {
				if(pagina<ultima_pagina){
					cargarDatos(pagina + 1, filasPorPagina);
				}
			}} 
		>

		<PaginationLink className="bg-blue arrow">
				<i className="fa fa-angle-right"></i>
			</PaginationLink>
		</PaginationItem>
		
		<PaginationItem 
			disabled={pagina == ultima_pagina ? true: false}
			onClick={() => {
				if(ultima_pagina>1 && pagina < ultima_pagina){
					cargarDatos(ultima_pagina, filasPorPagina);
				}
			}} 
		>
			<PaginationLink className="bg-blue arrow">
				<i className="fa fa-angle-double-right"></i>
			</PaginationLink>
		</PaginationItem>
		<PaginationItem disabled={false}>
			<PaginationLink className="bg-blue arrow text-center">
				<span style={{fontSize:11}}>{rows[0]?tools.formatNumber(rows[0].NR):tools.formatNumber(0)}</span>
			</PaginationLink>
		</PaginationItem>
	</Pagination>
}

export default TablaPro;