import React, { Fragment } from 'react'
import Select from '../common/select-basic'

const Default = ({
    onSelectEstado,
    catalogos,
    setFormDirOr,
    formDirOr,
    setFormDirDes,
    formDirDes,
    onChangeSucOr,
    onChangeSucDes,
    selectTipoServ,
    esResumen
}) => {
    // console.log(formDirOr)
    return <Fragment>
        <div className='row mb-3'>
            <form id='formOrigen' className="col-sm-12 col-md-6 col-xl-6">
                <div className="row">
                    <div className='col-sm-12 col-md-12 mb-4 mt-5'>
                        <h4><strong>ORIGEN</strong></h4>
                    </div>
                    <div className="col-sm-12 col-md-12">
                        <div className='row'>
                            {selectTipoServ.clave === 1 || selectTipoServ.clave === 2 ?
                                <div className="col-sm-12 col-md-12 mb-3">
                                    <div className="form-group">
                                        <label className="form-label">Sucursal<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="sucursal"
                                                placeholder="Seleccionar sucursal"
                                                id="listSucOr"
                                                name="listSucOr"
                                                disabled={esResumen}
                                                datos={catalogos.ctgSucursales}
                                                onChange={onChangeSucOr}
                                                value={formDirOr.listSucOr}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios listSucOr" style={{ display: 'none' }}><strong>Falta sleccionar (Sucursal)</strong></span>
                                    </div>
                                </div>
                                : ''}
                            {selectTipoServ.clave === 3 || selectTipoServ.clave === 4 ?
                                <Fragment>
                                    <div className="col-sm-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <label className="form-label">Dirección<span className="text-danger">*</span></label>
                                            <div className="input-group input-gpack2">
                                                <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Dirección"
                                                placeholder="Seleccionar dirección"
                                                id="listDirOr"
                                                name="listDirOr"
                                                disabled={esResumen}
                                                datos={catalogos.ctgDir}
                                                onChange={onChangeSucOr}
                                                value={formDirOr.listDirOr}
                                                />
                                            </div>
                                            <span className="text-danger txtVacios listDirOr" style={{ display: 'none' }}><strong>Falta sleccionar (Dirección)</strong></span>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <label className="form-label">Alias de dirección<span className="text-danger">*</span></label>
                                            <div className="input-group input-gpack2">
                                                <input
                                                id="txtAliasOr"
                                                name="txtAliasOr"
                                                className="form-control"
                                                type="text"
                                                autoComplete='off'
                                                disabled={esResumen}
                                                placeholder="Alias de dirección (Casa, Empresa, etc)"
                                                defaultValue={formDirOr.txtAliasOr}
                                                />
                                            </div>
                                            <span className="text-danger FormOrigen txtAliasOr" style={{ display: 'none' }}><strong>Falta completar (Alias de dirección)</strong></span>
                                        </div>
                                    </div>
                                </Fragment>
                                : ''}
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Estado<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <Select
                                        className="form-control form-control-md border border-dark"
                                        data-label="Estado"
                                        placeholder="Seleccionar estado"
                                        id="listEstadoOr"
                                        name="listEstadoOr"
                                        disabled={esResumen}
                                        // disabled={formDirOr.ID_EDO_SUC}
                                        // tipoValue={true}
                                        value={formDirOr.listEstadoOr}
                                        datos={catalogos.ctgEdo}
                                        onChange={(datos) => {
                                            onSelectEstado(datos.item, 'origen')
                                        }}
                                        />
                                    </div>
                                    <span className="text-danger FormOrigen listEstadoOr" style={{ display: 'none' }}><strong>Falta sleccionar (Estado)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Ciudad<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <Select
                                        className="form-control form-control-md border border-dark"
                                        data-label="Ciudad"
                                        placeholder="Seleccionar ciudad"
                                        id="listCiudadOr"
                                        name="listCiudadOr"
                                        disabled={esResumen}
                                        // tipoValue={1}
                                        datos={catalogos.ctgCdOr}
                                        // disabled={formDirOr.ID_CD_SUC}
                                        value={formDirOr.listCiudadOr}
                                        onChange={(datos) => {
                                            setFormDirOr((s) => ({ ...s, listCiudadOr: datos.item.clave }))
                                        }}
                                        />
                                    </div>
                                    <span className="text-danger FormOrigen listCiudadOr" style={{ display: 'none' }}><strong>Falta sleccionar (Ciudad)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Calle<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        id="txtCalleOr"
                                        name='txtCalleOr'
                                        autoComplete='off'
                                        className="form-control"
                                        type="text" placeholder="Calle"
                                        disabled={esResumen}
                                        // disabled={formDirOr.CALLE_SUC}
                                        defaultValue={formDirOr.txtCalleOr}
                                        />
                                    </div>
                                    <span className="text-danger FormOrigen txtCalleOr" style={{ display: 'none' }}><strong>Falta sleccionar (Calle)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Número Ext.<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        id="txtNumExtOr"
                                        name='txtNumExtOr'
                                        autoComplete='off'
                                        className="form-control" type="text"
                                        placeholder="Número Ext"
                                        disabled={esResumen}
                                        // disabled={formDirOr.NUM_EXT_SUC}
                                        defaultValue={formDirOr.txtNumExtOr}
                                        />
                                    </div>
                                    
                                    <span className="text-danger FormOrigen txtNumExtOr" style={{ display: 'none' }}><strong>Falta sleccionar (Número Ext)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Número Int.</label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        id="txtNumIntOr"
                                        name='txtNumIntOr'
                                        autoComplete='off'
                                        className="form-control" type="text" placeholder="Número Int"
                                        disabled={esResumen}
                                        // disabled={formDirOr.NUM_INT_SUC}
                                        defaultValue={formDirOr.txtNumIntOr}
                                        />
                                    </div>
                                    <span className="text-danger FormOrigen txtNumIntOr" style={{ display: 'none' }}><strong>Falta sleccionar (Número Int)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">C.P<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        id="txtCpOr"
                                        name='txtCpOr'
                                        autoComplete='off'
                                        className="form-control" placeholder="Código postal" type="number"
                                        disabled={esResumen}
                                        // disabled={formDirOr.CP_SUC}
                                        defaultValue={formDirOr.txtCpOr}
                                        />
                                    </div>
                                    
                                    <span className="text-danger FormOrigen txtCpOr" style={{ display: 'none' }}><strong>Falta sleccionar (C.P)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Colonia<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        id="txtColoniaOr"
                                        name='txtColoniaOr'
                                        autoComplete='off'
                                        className="form-control" type="text" placeholder="Colonia"
                                        disabled={esResumen}
                                        // disabled={formDirOr.COLONIA_SUC}
                                        defaultValue={formDirOr.txtColoniaOr}
                                        />
                                    </div>
                                    <span className="text-danger FormOrigen txtColoniaOr" style={{ display: 'none' }}><strong>Falta sleccionar (Colonia)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Referencia</label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        id="txtReferenciaOr"
                                        name='txtReferenciaOr'
                                        autoComplete='off'
                                        className="form-control" type="text"
                                        placeholder="Referencia"
                                        disabled={esResumen}
                                        // disabled={formDirOr.REFERENCIA_SUC}
                                        defaultValue={formDirOr.txtReferenciaOr}
                                    />
                                    </div>
                                    
                                    <span className="text-danger FormOrigen txtReferenciaOr" style={{ display: 'none' }}><strong>Falta sleccionar (Referencia)</strong></span>
                                </div>
                            </div>
                            <h6 className="mb-3 mt-4"><strong>DATOS DE GEOLOCALIZACIÓN</strong></h6>
                            <div className="col-sm-12 col-md-12">
                                <div className='row'>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Latitud</label>
                                            <div className="input-group input-gpack2">
                                                <input
                                                id="txtLatitudOr"
                                                name='txtLatitudOr'
                                                autoComplete='off'
                                                className="form-control"
                                                type="text"
                                                placeholder="Latitud"
                                                disabled={esResumen}
                                                // disabled={formDirOr.LATITUD_SUC}
                                                defaultValue={formDirOr.txtLatitudOr}
                                            />
                                            </div>
                                            
                                            <span className="text-danger FormOrigen txtLatitudOr" style={{ display: 'none' }}><strong>Falta sleccionar (Latitud)</strong></span>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Longitud</label>
                                            <div className="input-group input-gpack2">
                                                <input
                                                id="txtLongitudOr"
                                                name='txtLongitudOr'
                                                autoComplete="off"
                                                className="form-control" type="text" placeholder="Longitud"
                                                disabled={esResumen}
                                                // disabled={formDirOr.LONGITUD_SUC}
                                                defaultValue={formDirOr.txtLongitudOr}
                                            />
                                            </div>
                                            
                                            <span className="text-danger FormOrigen txtLongitudOr" style={{ display: 'none' }}><strong>Falta sleccionar (Longitud)</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <form id='formDestino' className="col-sm-12 col-md-6 col-xl-6">
                <div className="row">
                    <div className='col-sm-12 col-md-12 mb-4 mt-5'>
                        <h4><strong>DESTINO</strong></h4>
                    </div>
                    <div className="col-sm-12 col-md-12">
                        <div className='row'>
                            {selectTipoServ.clave === 1 || selectTipoServ.clave === 3 ?
                                <div className="col-sm-12 col-md-12 mb-3">
                                    <div className="form-group">
                                        <label className="form-label">Sucursal<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <Select
                                            className="form-control form-control-md border border-dark"
                                            data-label="sucursal"
                                            placeholder="Seleccionar sucursal"
                                            id="listSucDes"
                                            name="listSucDes"
                                            disabled={esResumen}
                                            // tipoValue={1}
                                            datos={catalogos.ctgSucursales}
                                            onChange={onChangeSucDes}
                                            value={formDirDes.listSucDes}
                                        />
                                        </div>
                                        
                                        <span className="text-danger txtVacios listSucDes" style={{ display: 'none' }}><strong>Falta sleccionar (Sucursal)</strong></span>
                                    </div>
                                </div>
                                : ''}
                            {selectTipoServ.clave === 2 || selectTipoServ.clave === 4 ?
                                <Fragment>
                                    <div className="col-sm-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <label className="form-label">Dirección<span className="text-danger">*</span></label>
                                            <div className="input-group input-gpack2">
                                                <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Dirección"
                                                placeholder="Seleccionar dirección"
                                                id="listDirDes"
                                                name="listDirDes"
                                                tipoValue={1}
                                                disabled={esResumen}
                                                datos={catalogos.ctgDir}
                                                onChange={onChangeSucDes}
                                               value={formDirDes.listDirDes}
                                            />
                                            </div>
                                             
                                            <span className="text-danger txtVacios listDirDes" style={{ display: 'none' }}><strong>Falta sleccionar (Dirección)</strong></span>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <label className="form-label">Alias de dirección<span className="text-danger">*</span></label>
                                            <div className="input-group input-gpack2">
                                                <input
                                                className="form-control"
                                                type="text"
                                                autoComplete='off'
                                                id="txtAliasDes"
                                                name='txtAliasDes'
                                                disabled={esResumen}
                                                placeholder="Alias de dirección (Casa, Empresa, etc)"
                                                defaultValue={formDirDes.txtAliasDes}
                                            />
                                            </div>
                                            
                                            <span className="text-danger txtVacios txtAliasDes" style={{ display: 'none' }}><strong>Falta completar (Alias de dirección)</strong></span>
                                        </div>
                                    </div>
                                </Fragment>
                                : ''}
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Estado<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <Select
                                        className="form-control form-control-md border border-dark"
                                        data-label="Estado"
                                        placeholder="Seleccionar estado"
                                        id="listEstadoDes"
                                        name="listEstadoDes"
                                        tipoValue={1}
                                        // disabled={formDirDes.ID_EDO_SUC}
                                        disabled={esResumen}
                                        datos={catalogos.ctgEdo}
                                        value={formDirDes.listEstadoDes}
                                        onChange={(datos) => {
                                            onSelectEstado(datos.item, 'destino')
                                        }}
                                    />
                                    </div>
                                    
                                    <span className="text-danger FormDestino listEstadoDes" style={{ display: 'none' }}><strong>Falta sleccionar (Estado)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Ciudad<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <Select
                                        className="form-control form-control-md border border-dark"
                                        data-label="Ciudad"
                                        placeholder="Seleccionar ciudad"
                                        id="listCiudadDes"
                                        name="listCiudadDes"
                                        disabled={esResumen}
                                        // disabled={formDirDes.ID_CD_SUC}
                                        datos={catalogos.ctgCdDes}
                                        value={formDirDes.listCiudadDes}
                                        onChange={(datos) => {
                                            setFormDirDes((s) => ({ ...s, listCiudadDes: datos.item.clave }))
                                        }}
                                    />
                                    </div>
                                    
                                    <span className="text-danger FormDestino listCiudadDes" style={{ display: 'none' }}><strong>Falta sleccionar (Ciudad)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Calle<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        id="txtCalleDes"
                                        name='txtCalleDes'
                                        autoComplete='off'
                                        className="form-control" type="text" placeholder="Calle"
                                        disabled={esResumen}
                                        // disabled={formDirDes.CALLE_SUC}
                                        defaultValue={formDirDes.txtCalleDes}
                                    />
                                    </div>
                                    
                                    <span className="text-danger FormDestino txtCalleDes" style={{ display: 'none' }}><strong>Falta sleccionar (Calle)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Número Ext.<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        id="txtNumExtDes"
                                        name='txtNumExtDes'
                                        autoComplete='off'
                                        className="form-control" type="text"
                                        placeholder="Número Ext"
                                        disabled={esResumen}
                                        // disabled={formDirDes.NUM_EXT_SUC}
                                        defaultValue={formDirDes.txtNumExtDes}
                                    />
                                    </div>
                                    
                                    <span className="text-danger FormDestino txtNumExtDes" style={{ display: 'none' }}><strong>Falta sleccionar (Número Ext)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Número Int.</label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        id="txtNumIntDes"
                                        name='txtNumIntDes'
                                        autoComplete='off'
                                        className="form-control" type="text" placeholder="Número Int"
                                        disabled={esResumen}
                                        // disabled={formDirDes.NUM_INT_SUC}
                                        defaultValue={formDirDes.txtNumIntDes}
                                    />
                                    </div>
                                    
                                    <span className="text-danger FormDestino txtNumIntDes" style={{ display: 'none' }}><strong>Falta sleccionar (Número Int)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">C.P<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        id="txtCpDes"
                                        name='txtCpDes'
                                        autoComplete='off'
                                        className="form-control" placeholder="Código postal" type="number"
                                        disabled={esResumen}
                                        // disabled={formDirDes.CP_SUC}
                                        defaultValue={formDirDes.txtCpDes}
                                    />
                                    </div>
                                    
                                    <span className="text-danger FormDestino txtCpDes" style={{ display: 'none' }}><strong>Falta sleccionar (C.P)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Colonia<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        id="txtColoniaDes"
                                        name='txtColoniaDes'
                                        autoComplete='off'
                                        className="form-control" type="text" placeholder="Colonia"
                                        disabled={esResumen}
                                        // disabled={formDirDes.COLONIA_SUC}
                                        defaultValue={formDirDes.txtColoniaDes}
                                    />
                                    </div>
                                    
                                    <span className="text-danger FormDestino txtColoniaDes" style={{ display: 'none' }}><strong>Falta sleccionar (Colonia)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Referencia</label>
                                    <div className="input-group input-gpack2">
                                        <input
                                        id="txtReferenciaDes"
                                        name='txtReferenciaDes'
                                        autoComplete='off'
                                        className="form-control" type="text"
                                        placeholder="Referencia"
                                        disabled={esResumen}
                                        // disabled={formDirDes.REFERENCIA_SUC}
                                        defaultValue={formDirDes.txtReferenciaDes}
                                    />
                                    </div>
                                    
                                    <span className="text-danger FormDestino txtReferenciaDes" style={{ display: 'none' }}><strong>Falta sleccionar (Referencia)</strong></span>
                                </div>
                            </div>
                            <h6 className="mb-3 mt-4"><strong>DATOS DE GEOLOCALIZACIÓN</strong></h6>
                            <div className="col-sm-12 col-md-12">
                                <div className='row'>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Latitud</label>
                                            <div className="input-group input-gpack2">
                                                <input
                                                id="txtLatitudDes"
                                                name='txtLatitudDes'
                                                autoComplete='off'
                                                className="form-control"
                                                type="text"
                                                placeholder="Latitud"
                                                disabled={esResumen}
                                                // disabled={formDirDes.LATITUD_SUC}
                                                defaultValue={formDirDes.txtLatitudDes}
                                            />
                                            </div>
                                            
                                            <span className="text-danger FormDestino txtLatitudDes" style={{ display: 'none' }}><strong>Falta sleccionar (Latitud)</strong></span>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Longitud</label>
                                            <div className="input-group input-gpack2">
                                                <input
                                                id="txtLongitudDes"
                                                name='txtLongitudDes'
                                                autoComplete='off'
                                                className="form-control" type="text" placeholder="Longitud"
                                                disabled={esResumen}
                                                // disabled={formDirDes.LONGITUD_SUC}
                                                defaultValue={formDirDes.txtLongitudDes}
                                            />
                                            </div>
                                            
                                            <span className="text-danger FormDestino txtLongitudDes" style={{ display: 'none' }}><strong>Falta sleccionar (Longitud)</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </Fragment>
}

export default Default