import React, {Fragment, useState} from 'react'
import SelectFetch from '../common/select-fetch';
import Select from '../common/select-basic'
import Tools from '../../js/tools'

const Default = ({
    catalogos,
    onChangeCliente,
    onChangeTipoEnvio,
    selectCliente,
    setSelectCliente,
    selectTipoServ,
    esResumen,
    idEditar
}) => {
    return <Fragment>
        <div className='row mb-3'>
            {!(idEditar >= 0)?
            <div className="col-sm-12 col-md-6 col-xl-6 mb-3">
                <label className="form-label">Clientes<span className="text-danger">*</span></label>
                {!esResumen?
                <SelectFetch
                    id={'listClientes'}
                    name={'listClientes'}
                    ruta='rt_ctg_clientes'
                    label={'Producto Servicio'}
                    value={selectCliente}
                    disabled={esResumen}
                    onChange={(datos) => {
                        setSelectCliente(datos)
                        onChangeCliente(datos)
                    }}
                />:
                <input
                    className="form-control"
                    type="text"
                    autoComplete='off'
                    placeholder="Clientes"
                    disabled={esResumen}
                    value={selectCliente.label}
                />}
            </div>:''}
            <div className="col-sm-12 col-md-6 col-xl-6 mb-3 mt-5">
                <label className="form-label">Tipo de envío<span className="text-danger">*</span></label>
                <div className="input-group input-gpack2">
                    <Select
                    className="form-control form-control-md border border-dark"
                    id="listSuc"
                    name="listSuc"
                    data-label="Tipo de envío"
                    placeholder="Seleccionar envío"
                    tipoValue={1}
                    datos={catalogos.ctgTipoServicio}
                    onChange={onChangeTipoEnvio}
                    value={selectTipoServ.clave}
                    disabled={esResumen}
                />
                </div>
                
                <span className="text-danger txtVacios listSuc" style={{ display: 'none' }}><strong>Falta sleccionar (Sucursal)</strong></span>
            </div>
        </div>
    </Fragment>
}

export default Default