import React, { Fragment, useState } from 'react';
import FormDF from './datos_fiscales_form'
import Tools from '../../../js/tools'
import { BtnVer, BtnEliminar } from '../../common/table_buttons'
import Table from '../../common/tabla'
import notie from 'notie'

const Default = ({
    formCliente,
    catalogos,
    setCatalogos
}) => {
    const [formDF, setFormDF] = useState({form: 'tabla'})
    const [esRefresh, setEsRefresh] = useState(false)

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_clientes_df_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            console.log(response)
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            {formDF.form === 'tabla' ?
            <div className="row">
                <div className="card">
                    <div className="col-xl-12 col-md-12">
                        <div className="card-header d-flex justify-content-center p-3">
                            <h5>DATOS FISCALES</h5>
                        </div>
                        <div className="mt-3">
                            <Table
                                noRutaDinamica={true}
                                cargando={false}
                                htmlBtnPag={ 
                                <button
                                    className="btn btn-purple btn-nuevo"
                                    type="button"
                                    onClick={() => {
                                        setCatalogos((s) => ({...s, ctgDirCd: []}))
                                        setFormDF({form: 'nuevo'})
                                    }}
                                > Nuevos Datos Fiscales
                                </button>}
                                headers={[
                                    {
                                        nombre: 'NOMBRE', tipo: 'html', texto: 'ALIAS', html: (d) => {
                                            return <div style={{ width: '25em' }}>
                                                <strong>{d.ALIAS_DF}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'DATOS FISCALES', tipo: 'html', texto: 'ALIAS', html: (d) => {
                                            return <div style={{ width: '25em' }}>
                                                Razón Social: {d.RAZON_SOCIAL}<br/>
                                                Rfc: {d.RFC}<br/>
                                                Uso CFDI: {d.USO_CFDI}<br/>
                                                Regimen Fiscal: {d.REGIMEN_FISCAL}
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'DIRECCIÓN FISCAL', tipo: 'html', texto: 'ALIAS', html: (d) => {
                                            return <div style={{ width: '25em' }}>
                                                    {d.CALLE} {d.NUM_EXT} {d.NUM_INT?`Int.${d.NUM_INT}`:''}, {d.COLONIA}, {d.CIUDAD}, {d.ESTADO}, C.P. {d.CP}
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
                                        html: (d) => {
                                            return <div>
                                            <BtnVer
                                                onClick={() => {
                                                    Tools.toolFetch({
                                                        ruta: 'rt_ctg_cd',
                                                        method: 'POST',
                                                        body: {clave: d.ID_EDO_DF}
                                                    }, (response) => {
                                                        setCatalogos((s) => ({
                                                            ...s,
                                                            ctgDfCd: response.datos
                                                        }))
                                                        
                                                        setTimeout(() => setFormDF((s) => ({...s, form: 'editar', ...d})), 100)
                                                    })
                                                }} 
                                            />
                                            <BtnEliminar
                                                onClick={() => {
                                                    notie.confirm({
                                                        text: `Se eliminara ${d.RAZON_SOCIAL} ¿estas de acuerdo?`,
                                                        submitText: 'Si, eliminar',
                                                        cancelText: 'No, Cancelar',
                                                        cancelCallback: function (value) {
                                                        },
                                                        submitCallback: function (value) {
                                                            eliminar({ id_df: d.ID_DF, id_cl: d.ID_CL_DF })
                                                        },
                                                    })
                                                }} 
                                            />
                                            </div>
                                        }
                                    }
                                ]}
                                totalField={'NR'} /* NR = número de resultados totales */
                                path={'/rt_clientes_df'}
                                refresh={esRefresh}
                                body={{id_cliente: formCliente.ID_CL}}
                                rowsPerPage={20}
                                paginadorTop={true}
                            >
                            </Table>
                        </div>
                    </div>
                </div>
            </div> : ''}
            {formDF.form !== 'tabla'?
                <FormDF
                    formCliente={formCliente}
                    catalogos={catalogos}
                    setCatalogos={setCatalogos}
                    formDF={formDF}
                    setFormDF={setFormDF}
                />
            : ''}
        </Fragment>
    );
};

export default Default;