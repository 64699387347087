import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './i18n';
import { BrowserRouter } from 'react-router-dom';

// ** Import custom components for redux **
import { Provider } from 'react-redux';
import store from './store';
import MainRoutes from './routes';

const Root = () => {

    return (
        <div className="App">
            {/* <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} redirectUri={auth0.redirectUri}> */}
                <Provider store={store}>
                    <BrowserRouter>
                        <MainRoutes />
                    </BrowserRouter>
                </Provider>
            {/* </Auth0Provider> */}
        </div>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));