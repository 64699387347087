import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Box, X } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom'
import Dropzone from '../common/dropzone';
import SelectFetch from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';
import LoaderSeccion from '../../components/common/loader_secciones';

const Default = ({esEditar}) => {
    const [catalogos, setCatalogos] = useState({})
    const [formPromos, setFormPromos] = useState({})
    const [promos, setPromos] = useState([])
    const [promos2, setPromos2] = useState([])
    const [esRefresh, setEsRefresh] = useState({})
    const [loaded, setLoaded] = useState(false)
    const navigate = useNavigate()
    const params = useParams();

    let timeout
    const guardarPromocion = () => {
        const _formPromociones = Tools.getForm('formPromos', {
            claveEtiquetas: '.txtVacios',
        })

        if (_formPromociones.formIsEmpty) {
            return notie.alert({
                type: 'warning',
                text: `Falta completar (${_formPromociones.stringIsEmpty})`,
                time: 3,
                position: 'top'
            })
        }

        Tools.toolFetch({
            ruta: 'rt_guias_prepagadas_promos_nuevo',
            method: 'POST',
            body: _formPromociones.data,
        }, (response) => {
            if (response.estatus === 'ERROR-EXISTE') {
                return notie.alert({
                    type: 'warning',
                    text: `Esta promoción ya ha sido agregada anteriormente.`,
                    time: 2,
                    position: 'top'
                })
            }
            if (response.estatus === 'ERROR-ACT') {
                return notie.alert({
                    type: 'warning',
                    text: `No fue posible activar esta promocion.`,
                    time: 2,
                    position: 'top'
                })
            }
            if (response.estatus === 'ERROR-AGG') {
                return notie.alert({
                    type: 'warning',
                    text: `No fue posible agregar esta promocion.`,
                    time: 2,
                    position: 'top'
                })
            }
            if (response.estatus === 'OK-ACT') {
                notie.alert({
                    type: 'success',
                    text: `Esta promoción fue activada correctamente.`,
                    time: 2,
                    position: 'top'
                })
            }
            if (response.estatus === 'OK-AGG') {
                notie.alert({
                    type: 'success',
                    text: `Esta promocion fue agregada correctamente.`,
                    time: 2,
                    position: 'top'
                })
            }
            

            setFormPromos({})
            setEsRefresh((s) => !s)
            document.getElementById('txt_cantidad').value = ''
        })
    }

    const actualizaPrecioPromocion = (valor, id) => {
        
        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(() => {
            Tools.toolFetch({
                ruta: 'rt_guias_prepagadas_promos_editar',
                method: 'POST',
                body: {
                    idPGP: id,
                    valor: valor.floatValue
                },
            }, (response) => {
                if (response.estatus === 'ERROR-ACT') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `No fue posible acutalizar el precio de la promoción.`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }
                
                // notie.alert({
                //     type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                //     text: `El precio para esta promoción fue modificado correctamente.`,
                //     // stay: Boolean, // optional, default = false
                //     time: 2, // optional, default = 3, minimum = 1,
                //     position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                // })
                setEsRefresh((s) => !s)
            })
        }, 1000)

    }

    const eliminarColumna = (promocion) => {
        notie.confirm({
            text: `Se eliminara la promoción de <strong>${promocion}</strong> piezas compradas ¿Estás de acuerdo?`,
            submitText: 'Si, eliminar',
            cancelText: 'No, Cancelar',
            cancelCallback: function (value) {
            },
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_guias_prepagadas_promos_eliminar',
                    method: 'POST',
                    body: {cantidad: promocion}
                }, (response) => {
                    if (response.estatus === 'ERROR-ELIM') {
                        return notie.alert({
                            type: 'warning',
                            text: `No fue posible desactivar esta promoción.`,
                            time: 2,
                            position: 'top'
                        })
                    }

                    notie.alert({
                        type: 'succes',
                        text: `La promoción fue desactivada correctamente.`,
                        time: 2,
                        position: 'top'
                    })
                    setEsRefresh((s) => !s)
                })
            },
        })

    }

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_ctg_promos_guias',
            method: 'POST',
            body: { tipo: 7 },
        }, (response) => {
            setCatalogos((s) => ({ ...s, ...response.datos }))
        })
    }, [])

    useEffect(() => {
        if (!catalogos.ctgTipos) {
            return
        }

        Tools.toolFetch({
            ruta: 'rt_guias_prepagadas_promos',
            method: 'POST',
            body: {},
        }, (response) => {
            setPromos(response.datos)
            let arr = []
            arr.push(response.datos[1])
            arr.push(response.datos[2])
            setPromos2(arr)
            setLoaded(true)
        })
    }, [esRefresh, catalogos.ctgTipos])

    return (
        <Fragment>
            {loaded ?
                <>
                    <LoaderSeccion status={loaded}/>
                    <Breadcrumb icon={Box} title={'Guias Prepagadas'} parent={['guias-prepagadas']} />
                    <div className="container-fluid ">
                        <div className='row'>
                            <form id='formPromos' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className='card'>
                                    <div className="card-header d-flex justify-content-between">
                                        <button className="btn btn-regresar" type="button"
                                            onClick={(e) => {
                                                navigate('/guias-prepagadas')
                                            }} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                        <h4><strong>PROMOCIONES PARA LAS GUÍAS PREPAGADAS</strong></h4>
                                        <h4></h4>
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className="col-md-12 mb-3">
                                                <h6><strong>Ingrese el valor de guías compradas por cliente para la siguiente promoción</strong></h6>
                                                <hr />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <label htmlFor="">Cantidad<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <div className="input-group-prepend"></div>
                                                    <input className="form-control"
                                                        autoComplete='off'
                                                        id="txt_cantidad" name="txt_cantidad"
                                                        type="text"
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_cantidad" style={{ display: 'none' }}><strong>Falta completar (Cantidad)</strong></span>
                                            </div>
                                            <div className="col-md-3 mb-3 d-flex align-items-end">
                                                <button
                                                    className="btn btn-purple d-flex align-items-center"
                                                    type="button"
                                                    onClick={(e) => guardarPromocion()}
                                                >Guardar</button>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    {catalogos.ctgTipos ?
                                                        catalogos.ctgTipos.map((t) => (
                                                            <Fragment key={(t.clave * 7) + t.label}>
                                                                <div className="col-md-12 mb-6">
                                                                    <br />
                                                                    <h6><strong>{t.label}</strong></h6>
                                                                    <hr />
                                                                    <TablaPrecios
                                                                        rows={promos[2]}
                                                                        headers={[
                                                                            {
                                                                                nombre: 'Piezas', clave: 'PIEZAS_GPP', tipo: 'html', style: { padding: 2 },
                                                                                html: (d) =>  ( 
                                                                                    <p className="form-control"
                                                                                        id="txt_cantidad" name="txt_cantidad"
                                                                                    >{d.CANTIDAD_GPP}</p>
                                                                                )
                                                                            },
                                                                            {
                                                                                nombre: 'Precio general', clave: 'PRECIO_GPP', tipo: 'html', style: { padding: 2 },
                                                                                html: (d) => (
                                                                                    <NumericFormat className='form-control text-end'
                                                                                        id={`txt_precio_pre`} 
                                                                                        name={`txt_precio_pre`}
                                                                                        prefix="$"
                                                                                        thousandSeparator
                                                                                        defaultValue={d.PRECIO_PRE}
                                                                                        readOnly
                                                                                    />
                                                                                )
                                                                            },
                                                                            ...promos[0].map((promo, index) => ({
                                                                                nombre: (
                                                                                    <>
                                                                                        {`Promoción ${promo.CANTIDAD_PGP} compradas `}
                                                                                        <X className='text-danger' style={{ cursor: 'pointer' }} onClick={() => eliminarColumna(promo.CANTIDAD_PGP)} />
                                                                                    </>
                                                                                ), clave: `PROMOCION_${index + 1}`, tipo: 'html', style: { padding: 2 },
                                                                                html: (d) => (
                                                                                    <NumericFormat className='form-control text-end'
                                                                                        id={d[`ID_PROMO_${promo.CANTIDAD_PGP}`]} 
                                                                                        name={d[`ID_PROMO_${promo.CANTIDAD_PGP}`]}
                                                                                        prefix="$"
                                                                                        thousandSeparator
                                                                                        defaultValue={d[`PROMO_${promo.CANTIDAD_PGP}`]}
                                                                                        onValueChange={(e) => actualizaPrecioPromocion(e, d[`ID_PROMO_${promo.CANTIDAD_PGP}`])}
                                                                                    />
                                                                                )
                                                                            }))
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </Fragment>
                                                        ))
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
                :
                ''
            }
        </Fragment>
    )
}

export default Default