import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from '../common/breadcrumbv2';
import { MapPin } from 'react-feather';
import Table from '../common/tabla'
import tools from "../../js/tools";
import Select from '../common/select-basic';
import notie from 'notie'
import LoaderSeccion from '../../components/common/loader_secciones';

const Default = () => {
    const [headers, setHeaders] = useState([])

    const onChangeDefinirZonas = (datos) => {
        if(!datos.idZona || !datos.origen || !datos.destino){
            return notie.alert({ type: 'warning', text: 'Debe de seleccionar una opción', time: 2})
        }

        tools.toolFetch({
            ruta: 'rt_cobertura_guardar_grupo_zona',
            method: 'POST',
            body: {...datos}
        }, (response) => {
        })
    }

    useEffect(() => {
        let isS = true
        tools.toolFetch({
            ruta: 'rt_cobertura_zonas_datos',
            method: 'POST',
            body: {}
        }, (response) => {
            let grupos = response.datos.grupos
            let zonas = response.datos.zonas
            let gruposZonas = response.datos.gruposZonas

            if(!isS){
                return
            }
            
            setHeaders([
                {
                    nombre: 'O / D', tipo: 'html', texto: '-',
                    style: { width: 10 },
                    html: (d) => {
                        return <div >
                            <strong>{d.NOMBRE_CG}</strong>
                        </div>
                    }
                },
                ...grupos.map((destino, i) => {
                    return {
                        nombre: destino.NOMBRE_CG, tipo: 'html', texto: destino.NOMBRE_CG,
                        style: { width: 10 },
                        html: (origen) => {
                            let selectItem = gruposZonas.find((gz) => gz.ID_CG_ORIGEN_GZ === origen.ID_CG && gz.ID_CG_DESTINO_GZ === destino.ID_CG)
                            return <div>
                                <Select 
                                    id={'list_zona'+i}
                                    name={'list_zona'+i}
                                    placeholder='---'
                                    tipoValue={1}
                                    datos={zonas}
                                    value={selectItem?selectItem.ID_CZ_GZ:''}
                                    onChange={(datos) => {
                                        onChangeDefinirZonas({idZona: datos.item.clave, origen: origen.ID_CG, destino: destino.ID_CG})
                                    }}
                                />
                            </div>
                        }
                    }
                })
            ])
        })

        return () => isS = false
    }, [])

    return <Fragment>
        <LoaderSeccion status={headers}/>
        <Breadcrumb icon={MapPin} title={'Zonas'} parent={['definir-zonas']} />
        <div className="card">
            <div className="card-header p-3">
                <div className='d-flex justify-content-center'>
                    <h4>Definición de zonas de precios de acuerdo al origen y destino</h4>
                </div>
            </div>
            <div className='row'>
                <div className="col-xl-12 col-md-12 tabla-zonas">
                    <Table
                        sinBuscador={true}
                        noRutaDinamica={true}
                        cargando={false}
                        row={{
                            ignoreColumns: [],
                            onClick: (e, row) => {
                                // console.log(row)
                            }
                        }}
                        headers={headers}
                        totalField={'NR'} /* NR = número de resultados totales */
                        path={'/rt_cobertura_zonas'}
                        body={{}}
                        rowsPerPage={20}
                        esPaginador={false}
                    >
                    </Table>
                </div>
            </div>
        </div>
    </Fragment>
}

export default Default;