import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Box, X } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom'
import Dropzone from '../common/dropzone';
import SelectFetch from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';
import LoaderSeccion from '../../components/common/loader_secciones';

const Default = ({
    esEditar
}) => {
    const [catalogos, setCatalogos] = useState({
        ctgExtra: [
            { clave: 1, label: 'Sobre precio base' },
            { clave: 2, label: 'Sobre precio extra' },
            { clave: 3, label: 'Sobre precio extra (rango)' }
        ],
    })
    const [formParametros, setFormParametros] = useState({})
    const [parametros, setParametros] = useState([])
    const [esRefresh, setEsRefresh] = useState({})
    const navigate = useNavigate()
    const params = useParams();
    let timeout

    const guardarParametro = () => {
        const _formParametros = Tools.getForm('formParametros', {
            claveEtiquetas: '.txtVacios',
            // ignore: ['list_grupo', 'list_prod_serv', 'list_suc', 'list_tipo', 'txt_desc', 'txt_nombre'] 
        })

        if (_formParametros.formIsEmpty) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_formParametros.stringIsEmpty})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        _formParametros.data.txt_min = formParametros.txt_min
        _formParametros.data.txt_max = formParametros.txt_max

        Tools.toolFetch({
            ruta: 'rt_prod_serv_params_nuevo',
            method: 'POST',
            body: _formParametros.data,
        }, (response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setFormParametros({})
            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro realizado con exito`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const actualizaValorMinMax = (registro, valor, minMax) => {
        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(() => {
            Tools.toolFetch({
                ruta: 'rt_prod_serv_params_editar',
                method: 'POST',
                body: {
                    idPp: registro.ID_PP,
                    tipoValor: registro.TIPO_VALOR_PP,
                    valor: valor.floatValue,
                    minMax,
                },
            }, (response) => {
                if (response.estatus === 'ERROR') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                if (response.estatus === 'EXISTE') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Valor [${minMax == 1 ? 'Mínimo' : 'Máximo'}] existente, verifica los datos por actualizar.`,
                        // stay: Boolean, // optional, default = false
                        time: 4, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                setEsRefresh((s) => !s)
            })
        }, 1000)

    }

    const actualizaParamsGrupo = (registro, grupo) => {
        if(!grupo.clave){
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Debes seleccionar un <strong>grupo</strong> para poder actualizar este campo`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        Tools.toolFetch({
            ruta: 'rt_prod_serv_params_grupo_editar',
            method: 'POST',
            body: {
                idPp: registro.ID_PP,
                grupo: grupo.clave
            },
        }, (response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
        })
    }

    const actualizaParamsAplica = (registro, aplica) => {
        if(!aplica.clave){
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Debes seleccionar <strong>tipo aplicación</strong> para poder actualizar este campo`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }
        
        Tools.toolFetch({
            ruta: 'rt_prod_serv_params_aplica_editar',
            method: 'POST',
            body: {
                idPp: registro.ID_PP,
                aplica: aplica.clave
            },
        }, (response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
        })
    }

    const eliminarRegistroParams = (registro) => {
        const tipo = catalogos.ctgTipos.find((t) => t.clave == registro.TIPO_VALOR_PP)
        notie.confirm({
            text: `Se eliminara valor mínimo <strong>${registro.VALOR_MINIMO_PP}</strong> y máximo <strong>${registro.VALOR_MAXIMO_PP}</strong> de <strong>${tipo.label}</strong> ¿estas de acuerdo?`,
            submitText: 'Si, eliminar',
            cancelText: 'No, Cancelar',
            cancelCallback: function (value) {
            },
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_prod_serv_params_eliminar',
                    method: 'POST',
                    body: {idPp: registro.ID_PP}
                }, (response) => {
                    if (response.estatus === 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }

                    setEsRefresh((s) => !s)
                })
            },
        })

    }

    const aplicaPrecio = (d) => {
        let aplica = 0
        if (d.ES_EXTRA_PP) {
            aplica = 2
            if (d.ES_RANGO_PP) {
                aplica = 3
            }
        } else {
            aplica = 1
        }

        return aplica
    }

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_ctg_params',
            method: 'POST',
            body: { tipo: 5 },
        }, (response) => {
            // setCatalogos((s) => ({...s, ctgTipos: response.datos}))
            setCatalogos((s) => ({ ...s, ...response.datos }))
        })
    }, [])

    useEffect(() => {
        if (!catalogos.ctgTipos) {
            return
        }

        Tools.toolFetch({
            ruta: 'rt_prod_serv_params',
            method: 'POST',
            body: {},
        }, (response) => {
            setParametros(response.datos)
        })
    }, [esRefresh, catalogos.ctgTipos])

    return (
        <Fragment>
            <LoaderSeccion status={catalogos.ctgTipos}/>
            <Breadcrumb icon={Box} title={'Servicios Parametros'} parent={['servicios']} />
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formParametros' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className="btn btn-regresar" type="button"
                                    onClick={(e) => {
                                        navigate('/servicios')
                                    }} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <h4><strong>PARAMETROS PARA LA DEFINICIÓN DE PRECIOS</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Ingrese el rango mínimo y máximo de los siguientes parametros</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Tipo parametro<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Tipo parametro"
                                                placeholder="Seleccionar tipo parametro"
                                                id="list_tipo"
                                                name="list_tipo"
                                                tipoValue={1}
                                                datos={catalogos.ctgTipos}
                                                value={formParametros.list_tipo || ''}
                                                onChange={(e) => {
                                                    setFormParametros((s) => ({ ...s, list_tipo: e.item.clave }))
                                                }}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios list_tipo" style={{ display: 'none' }}><strong>Falta completar (Tipo parametro)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Grupo<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Grupo"
                                                placeholder="Seleccionar grupo"
                                                id="list_grupo"
                                                name="list_grupo"
                                                tipoValue={1}
                                                datos={catalogos.ctgGrupos}
                                                value={formParametros.list_grupo || ''}
                                                onChange={(e) => {
                                                    setFormParametros((s) => ({ ...s, list_grupo: e.item.clave }))
                                                }}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios list_grupo" style={{ display: 'none' }}><strong>Falta completar (Grupo)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Aplicación<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Aplicación"
                                                placeholder="Seleccionar forma de aplicación"
                                                id="list_aplicacion"
                                                name="list_aplicacion"
                                                tipoValue={1}
                                                datos={catalogos.ctgExtra}
                                                value={formParametros.list_aplicacion || ''}
                                                onChange={(e) => {
                                                    setFormParametros((s) => ({ ...s, list_aplicacion: e.item.clave }))
                                                }}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios list_aplicacion" style={{ display: 'none' }}><strong>Falta completar (Aplicación)</strong></span>
                                    </div>
                                    {!formParametros.list_aplicacion || formParametros.list_aplicacion !== 2 ?
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="">Mínimo<span className="text-danger">*</span></label>
                                            <div className="input-group input-gpack2">
                                                <div className="input-group-prepend"></div>
                                                <NumericFormat
                                                    id="txt_min"
                                                    name="txt_min"
                                                    className="form-control text-end"
                                                    thousandSeparator=","
                                                    placeholder='0.00'
                                                    autoComplete='off'
                                                    data-label='Mínimo'
                                                    value={formParametros.txt_min || ''}
                                                    onValueChange={(e) => {
                                                        setFormParametros((s) => ({ ...s, txt_min: e.value }))
                                                    }}
                                                />
                                            </div>
                                            <span className="text-danger txtVacios txt_min" style={{ display: 'none' }}><strong>Falta completar (Mínimo)</strong></span>
                                        </div>
                                        : ''}
                                    <div className="col-md-3 mb-3">
                                        <label htmlFor="">Máximo<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <NumericFormat
                                                id="txt_max"
                                                name="txt_max"
                                                className="form-control text-end"
                                                thousandSeparator=","
                                                placeholder='0.00'
                                                autoComplete='off'
                                                data-label='Máximo'
                                                value={formParametros.txt_max || ''}
                                                onValueChange={(e) => {
                                                    setFormParametros((s) => ({ ...s, txt_max: e.value }))
                                                }}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_max" style={{ display: 'none' }}><strong>Falta completar (Máximo)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3 d-flex align-items-end">
                                        <button
                                            className="btn btn-purple d-flex align-items-center"
                                            type="button"
                                            onClick={(e) => guardarParametro()}
                                        >Guardar</button>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            {catalogos.ctgTipos ?
                                                catalogos.ctgTipos.map((t) => (
                                                    <Fragment key={(t.clave * 5) + t.label}>
                                                        <div className="col-md-6 mb-6">
                                                            <br />
                                                            <h6><strong>{t.label}</strong></h6>
                                                            <hr />
                                                            <TablaPrecios
                                                                rows={parametros.filter((v) => v.TIPO_VALOR_PP == t.clave)}
                                                                headers={[
                                                                    {
                                                                        nombre: 'Grupo', clave: 'GRUPO_PP', tipo: 'html', style: { padding: 2 },
                                                                        html: (d) => {
                                                                            return <Select
                                                                                className="form-control form-control-md border border-dark"
                                                                                data-label="Grupo"
                                                                                placeholder="Seleccionar grupo"
                                                                                id="list_grupo"
                                                                                name="list_grupo"
                                                                                tipoValue={1}
                                                                                datos={catalogos.ctgGrupos}
                                                                                value={d.GRUPO_PP || ''}
                                                                                onChange={(e) => actualizaParamsGrupo(d, e.item)}
                                                                            />
                                                                        }
                                                                    },
                                                                    {
                                                                        nombre: 'Aplicación', clave: 'ID_PP', tipo: 'html', style: { padding: 2 },
                                                                        html: (d) => {
                                                                            let aplica = aplicaPrecio(d)
                                                                            return <Select
                                                                                className="form-control form-control-md border border-dark"
                                                                                data-label="Aplicación"
                                                                                placeholder="Seleccionar forma de aplicación"
                                                                                id="list_aplicacion"
                                                                                name="list_aplicacion"
                                                                                tipoValue={1}
                                                                                datos={catalogos.ctgExtra}
                                                                                value={aplica}
                                                                                onChange={(e) => actualizaParamsAplica(d, e.item)}
                                                                            />
                                                                        }
                                                                    },
                                                                    {
                                                                        nombre: 'Mínimo', clave: 'VALOR_MINIMO_PP', tipo: 'html', style: { padding: 2 },
                                                                        html: (d) => {
                                                                            let aplica = aplicaPrecio(d)
                                                                            return <NumericFormat
                                                                                className="form-control text-end"
                                                                                thousandSeparator=","
                                                                                placeholder='0.00'
                                                                                autoComplete='off'
                                                                                disabled={aplica == 2}
                                                                                value={d.VALOR_MINIMO_PP}
                                                                                onValueChange={(e) => actualizaValorMinMax(d, e, 1)}
                                                                            />
                                                                        }
                                                                    },
                                                                    {
                                                                        nombre: 'Máximo', clave: 'VALOR_MAXIMO_PP', tipo: 'html', style: { padding: 2 },
                                                                        html: (d) => {
                                                                            return <NumericFormat
                                                                                className="form-control text-end"
                                                                                thousandSeparator=","
                                                                                placeholder='0.00'
                                                                                autoComplete='off'
                                                                                value={d.VALOR_MAXIMO_PP}
                                                                                onValueChange={(e) => actualizaValorMinMax(d, e, 2)}
                                                                            />
                                                                        }
                                                                    },
                                                                    {
                                                                        nombre: '-', clave: 'VALOR_MAXIMO_PP', tipo: 'html', style: { padding: 2 },
                                                                        html: (d) => {
                                                                            return <X className='text-danger' style={{ cursor: 'pointer' }} onClick={(e) => eliminarRegistroParams(d)} />
                                                                        }
                                                                    }
                                                                ]}
                                                            />
                                                        </div>
                                                    </Fragment>
                                                ))
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default Default