import React, {useEffect,useState} from 'react';
import logo from '../../assets/images/logo/logo.png';
import '../../assets/css/notie.css';
import notie from 'notie';
import { textos } from '../../constant/textos';
import Tools from '../../js/tools';
import { useNavigate, useParams } from 'react-router-dom';

const Default = (props) => {
    const [conteoNuevoCodigo, setConteoNuevoCodigo] = useState({conteo:30,estatus:false})
    const navigate = useNavigate();
    const params = useParams();

    const onClickVerificar = () => {
        let formLogin = Tools.getForm('formLogin', {claveEtiquetas: '.txtVacios'})

        if (formLogin.formIsEmpty) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${formLogin.stringIsEmpty})`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        Tools.toolFetch({
            ruta: 'rt_login_cv',
            method: 'POST',
            body: {
                id:params.id,
                ...formLogin.data
            }
        }, (response) => {
            if (response.estatus === 'NO-EXISTE') {
                return notie.alert({ type: 'warning', text: 'El código ingresado no es valido', time: 3 })
            }

            if (response.estatus === 'EXPIRO') {
                return notie.alert({ type: 'warning', text: 'El código ingresado no es valido', time: 3 })
            }

            localStorage.setItem('UID', JSON.stringify(response.datos))
            notie.alert({
                type: 'success',
                text: `${textos.login.acceso_correcto} ${response.datos.NOMBRE} ${response.datos.APELLIDO}`, 
                time: 2
            })

            return setTimeout(() => {
                navigate(`/empresa`);
            }, 2500)
        })
    }

    const generarNuevoCodigo = (conteo = 30) => {
        let idInterval = setInterval(() => {
            setConteoNuevoCodigo((s) => {
                if(s.conteo - 1 > -1){
                    return {conteo: s.conteo - 1, estatus: true}
                }else{
                    clearInterval(idInterval)
                    return {conteo: conteo, estatus: false}
                }
            })
        }, 1000)
    }

    const onClickNuevoCodigo = () => {
        generarNuevoCodigo(conteoNuevoCodigo.conteo)

        Tools.toolFetch({
            ruta: 'rt_login_cv_nuevo',
            method: 'POST',
            body: {
                id:params.id,
            }
        }, (response) => {
            console.log(response)
            if (response.estatus === 'NO-EXISTE') {
                return notie.alert({ type: 'warning', text: 'Código de verificación incorrecto', time: 3 })
            }

            return notie.alert({ type: 'success', text: 'Se reenvío nuevo código de verificación', time: 3 })
        })
    }

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_login_cv_validar',
            method: 'POST',
            body: {id:params.id}
        }, (response) => {
            if (response.estatus === 'EXISTE') {
                return navigate('/login')
            }

            if (response.estatus === 'EXPIRO') {
                return navigate('/login')
            }

            if (response.estatus === 'ERR') {
                return navigate('/login')
            }
        })
    }, [])

    return (
        <div className="page-wrapper">
            <div className="container-fluid p-0">
                <div className="authentication-main">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="auth-innerright">
                                <div className="authentication-box">
                                    <div className="card mt-4 pt-5">
                                        <div className="text-center">
                                            <img className="img-fluid" src={logo} alt="GPACK" />
                                        </div>
                                        <div className="card-body">
                                            <div className="text-center">
                                                {/* <h4>GPACK</h4> */}
                                                <h6>Ingrese el código de verificación enviado a su correo</h6>
                                            </div>
                                            <form id='formLogin' className="theme-form">
                                                <div className="form-group">
                                                    <div className="form-group mb-0">
                                                        {/* <label className="col-form-label">Código verificación</label> */}
                                                        <div className='input-group input-gpack'>
                                                            <div className='icon-input'><span className="input-group-text"><i className="fa fa-hashtag" aria-hidden="true"></i></span></div>
                                                            <input id='txt_cod' name='txt_cod' className="form-control" placeholder='Código verificación' maxLength={6}/>
                                                        </div>
                                                    </div>
                                                    <span className="text-danger txtVacios txt_cod" style={{ display: 'none' }}><strong>Falta completar (Código verificación)</strong></span>
                                                    <div className="form-group form-row mb-0">
                                                        <button id='btn_ingresar' className="btn btn-purple" type="button" onClick={onClickNuevoCodigo} disabled={conteoNuevoCodigo.estatus} >Generar nuevo código {conteoNuevoCodigo.estatus?`(${conteoNuevoCodigo.conteo})`:''}</button>
                                                    </div>
                                                    <div className="form-group form-row mb-0">
                                                        <button id='btn_ingresar' className="btn btn-purple" type="button" onClick={onClickVerificar}>Confirmar código</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
};

export default Default;