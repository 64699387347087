import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UserPlus } from 'react-feather';
import notie from 'notie';
import Tools from '../../js/tools';
import Breadcrumb from '../common/breadcrumbv2';
import LoaderSeccion from '../../components/common/loader_secciones';
import tools from '../../js/tools';

const Default = (props) => {
    /* ==================== HOOKS ==================== */
    const params = useParams();

    const [catalogos, setCatalogos] = useState({
        empresas: [],
        tipos: [],
        permisosSecciones: []
    });

    const [datosUsuario, setDatosUsuario] = useState({});

    /* ==================== CONSTANTES ==================== */

    /* ==================== USE EFFECT ==================== */
    useEffect(() => {
        fetch('/rt_usuarios_catalogos_detalle', {
            method: 'POST',
            body: JSON.stringify({id: Number(tools.aesDecrypt(params.id))}),
            headers: {'Content-Type': 'application/json'}
        }).then(function(res) { return res.json(); })
        .then(function(res) {
            if(res.estatus === 'OK'){
                let seccionesPermisos = groupArrayByKey(res.datos.permisos, 'id_sec', 'id');

                let sec = res.datos.secciones.map(seccion => {
                    return {
                        id: seccion.id,
                        titulo: seccion.nombre,
                        permisos: seccionesPermisos[seccion.id]?.array || []
                    }
                });

                setDatosUsuario((s) => ({...res.datos.usuario}));

                res.datos.permisosSecciones = sec;
                res.datos.secciones = null;

                setCatalogos(res.datos);
            }

            else
                throw Error(res.estatus);
        }).catch(function(err){
            notie.alert({text: 'No se pudo obtener los catálogos, inténtelo más tarde', type: 4, time: 5});
        })
    }, [])

    /* ==================== FUNCIONES ==================== */
    const groupArrayByKey = (xs, key, param) => {
        return xs.reduce((rv, x) => {
          (rv[x[key]] = rv[x[key]] || {key: x[param], array: []}).array.push(x);
          return rv;
        }, {});
    }

    const enviarDatosFormulario = () => {
        let datos = Tools.getForm('form_usuario', {ignore: ['input_CELULAR']});

        if(datos.formIsEmpty > 0){
            notie.alert({text: `El campo ${datos.labelIsEmpty[datos.keys[0]]} es requerido`, type: 3, time: 4});
            return;
        }

        let form_permisos = new FormData(document.getElementById('form_permisos'));
        let permisos_form = [], permiso_split, e;
        for(e of form_permisos.entries()){
            permiso_split = e[0].split('_');
            permisos_form.push({
                id: permiso_split[2]
            });
        };
        datos.data['input_ID_UA'] = Tools.aesDecrypt(params.id);
        datos.data['permisos'] = permisos_form;

        fetch('../../rt_usuarios_editar', {
            method: 'POST',
            body: JSON.stringify(datos.data),
            headers: {'Content-Type': 'application/json'}
        }).then(res => res.json())
        .then(res => {
            if(res.estatus === 'OK'){
                notie.alert({text: 'Información actualizada correctamente', type: 1, time: 5});
            }else {
                throw Error(res.estatus)
            }
        }).catch(err => {
            notie.alert({text: 'No se pudo actualizar la información, inténtelo más tarde', type: 3, time: 6});
            console.log(err);
        })
    }

    const ComponentePermisos = (propsPermisos) => {
        return (
            <Fragment>
                <div className='card-permisos'>
                    <div><h5>{propsPermisos.titulo}</h5></div>
                    <hr className='solid'></hr>
                        {
                            propsPermisos.permisos.map((permiso) => {
                                let id = `input_${propsPermisos.id}_${permiso.id}`;

                                return <div key={`key_permiso_${permiso.id}`} className="checkbox">
                                    <input id={id} name={id} type="checkbox" value={'1'} defaultChecked={permiso.activo}/>
                                    <label htmlFor={id}>{permiso.nombre}</label>
                                </div>
                            }
                        )}
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <LoaderSeccion status={datosUsuario}/>
            <Breadcrumb icon={UserPlus} title={'EDITAR USUARIO'} parent={['usuarios-cms']}/>
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">
                        <form id='form_usuario' className="theme-form">

                            <div className="form-group row">
                                <div className='col-md-6 col-12 mb-3'>
                                    <label className='col-sm-3 col-lg-2 col-form-label' htmlFor="input_EMPRESA">Empresa</label>
                                    <div className="input-group input-gpack2">
                                        <select className='form-select' data-label='empresa' name='input_EMPRESA' id='input_EMPRESA' value={datosUsuario?.ID_EM || ''} onChange={(e) => {setDatosUsuario((s) => ({...datosUsuario, ID_EM: e.target.value}));}}>
                                            <option hidden value={''}>Seleccione</option>
                                            {
                                                catalogos.empresas.map(item => 
                                                <option key={`key_option_empresas_${item.ID}`} value={item.ID}>{item.NOMBRE}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12 mb-3'>
                                    <label className="col-form-label" htmlFor="input_NOMBRES">Nombres</label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_NOMBRES' id='input_NOMBRES' type="text" data-label="nombres" defaultValue={datosUsuario.NOMBRES} />
                                    </div>
                                </div>
                                <div className='col-md-6 col-12 mb-3'>
                                <label className="col-form-label" htmlFor="input_APELLIDOS">Apellidos</label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_APELLIDOS' id='input_APELLIDOS' type="text" data-label="apellidos" defaultValue={datosUsuario.APELLIDOS}/>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12 mb-3'>
                                    <label className="col-form-label" htmlFor="input_CORREO">Correo</label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_CORREO' id='input_CORREO' type="text" data-label="nombre de sucursal" defaultValue={datosUsuario.CORREO}/>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12 mb-3'>
                                    <label className="col-form-label" htmlFor="input_TELEFONO">Telefono</label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_TELEFONO' id='input_TELEFONO' type="text" data-label="nombre de sucursal" defaultValue={datosUsuario.TELEFONO}/>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12 mb-3'>
                                    <label className="col-form-label" htmlFor="input_CELULAR">Celular</label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_CELULAR' id='input_CELULAR' type="text" data-label="nombre de sucursal" defaultValue={datosUsuario.CELULAR}/>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12 mb-3'>
                                    <label className='col-form-label' htmlFor="input_TIPO">Tipo usuario</label>
                                    <div className="input-group input-gpack2">
                                        <select className='form-select' data-label='tipo de usuario' name='input_TIPO' id='input_TIPO' value={datosUsuario?.TIPO || ''} onChange={(e) => {setDatosUsuario((s) => ({...datosUsuario, TIPO: e.target.value}));}}>
                                            <option hidden value={''}>Seleccione</option>
                                            {
                                                catalogos.tipos.map(item => 
                                                <option key={`key_option_tipos_${item.ID}`} value={item.ID}>{item.NOMBRE}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="container-fluid d-print-none">
                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <div className="page-header-left">
                                    <h3>Asignar permisos</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        <form id='form_permisos' className="theme-form">
                            <div className='row'>
                            {
                                catalogos.permisosSecciones.map(seccion => {
                                    return (
                                        <div key={`key_seccion_${seccion.id}`} className='col-12 col-md-6'>
                                            <ComponentePermisos 
                                                titulo = {seccion.titulo}
                                                id = {seccion.id}
                                                permisos = {seccion.permisos}
                                            />
                                        </div>
                                    )
                                })
                            }
                            </div>
                            <div className='row mt-4'>
                                <div className='col-12 text-center'>
                                    <button className='btn btn-purple' type='button' onClick={enviarDatosFormulario}>Guardar cambios</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;