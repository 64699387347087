import React, { Fragment, useState } from 'react';
import {useNavigate} from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Send } from 'react-feather';

const Default = (props) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const navigate = useNavigate()
    
    const colorSelector = (val) => {
        switch (val) {
            case 0:
                return '#dc3545'
            case 1:
                return ''
            case 2:
                return '#ffc107'
            case 3:
                return '#fd7e14'
            case 4:
                return '#17a2b8'
            case 5:
                return '#28a745'
            default:
                break;
        }
    }

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_prod_serv_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            console.log(response)
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Send} title={'Envíos'} parent={['envios']} />
            <div className="card" >
                <div className="card-body">
                    <div className='row'>
                        <div className="col-xl-12">
                        <Table
                            noRutaDinamica={true}
                            cargando={false}
                            htmlBtnPag={
                                <button
                                    className="btn btn-purple"
                                    type="button"
                                    onClick={() => {
                                        navigate('/envios/nuevo')
                                    }}
                                > Nuevo envío
                                </button>}
                            headers={[
                                {
                                    nombre: 'ENVÍO', tipo: 'html', texto: 'NOMBRES_CONTACTO_CL', html: (d) => {
                                        return <div>#{d.ID_SE}</div>
                                    }
                                },
                                {
                                    nombre: 'CLIENTE', tipo: 'html', texto: 'NOMBRES_CONTACTO_CL', html: (d) => {
                                        return <div>
                                            {d.NOMBRES_CONTACTO_CL} {d.APELLIDOS_CONTACTO_CL}<br/>
                                            {d.CORREO_CL}    
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'SUCURSAL', tipo: 'html', texto: 'NOMBRE_SUC', html: (d) => {
                                        return <div>{d.NOMBRE_SUC}</div>
                                    }
                                },
                                {
                                    nombre: 'ESTATUS ENVÍO', tipo: 'html', texto: 'DESCRIPCION_EST', html: (d) => {
                                        return <>
                                            <div>
                                                <div className='row'>
                                                    {d.DESCRIPCION_EST != '' || d.DESCRIPCION_EST != null ? 
                                                        <div className='col-md-6 p-0'>
                                                            <div className="card border-secondary mb-1 text-center">
                                                                <div className="card-body text-secondary p-0">
                                                                    <strong className="card-title p-0 m-0" style={{fontSize: 14, color: colorSelector(d.ESTATUS_ENVIO_SE) }}>{d.DESCRIPCION_EST}</strong><br/>
                                                                    <p className="card-text" style={{fontSize: 12}}>{d.FECHA_ALTA_TEXT}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className='col-md-6 p-0 text-center'>
                                                            Sin estatus
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </> 
                                    }
                                },
                                {
                                    nombre: 'DETALLE', tipo: 'html', texto: 'Detalle',
                                    html: (d) => {
                                        return <div>
                                        <BtnVer
                                            onClick={() => {
                                                navigate(`/envios/${d.ID_SE}`)
                                            }} 
                                        />
                                        <BtnEliminar
                                            onClick={() => {
                                                notie.confirm({
                                                    text: `Se eliminara <strong>${d.NOMBRE_PS}</strong> ¿estas de acuerdo?`,
                                                    submitText: 'Si, eliminar',
                                                    cancelText: 'No, Cancelar',
                                                    cancelCallback: function (value) {
                                                    },
                                                    submitCallback: function (value) {
                                                        eliminar({ id_ps: d.ID_PS })
                                                    },
                                                })
                                            }} 
                                        />
                                        </div>
                                    }
                                }
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'/rt_envio_realizados'}
                            refresh={esRefresh}
                            body={{}}
                            rowsPerPage={10}
                            paginadorTop={true}
                        >
                        </Table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;