import React, { Fragment } from 'react'
import {NumericFormat} from 'react-number-format';

const Default = ({
    precio
}) => {
    return <Fragment>
        <div className='row mb-3 mt-5'>
            <form id='formCarga' className="col-sm-12 col-md-12 col-xl-12">
                <div className="row">
                    <div className='col-sm-12 col-md-12 mb-4'>
                        <h4><strong>DATOS DEL PAGO</strong></h4>
                    </div>
                    <div className="col-sm-12 col-md-12">
                        <div className='row'>
                            <div className="col-sm-12 col-md-3 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Subtotal</label>
                                    <div className="input-group input-gpack2">
                                        <NumericFormat
                                        id="txtSubtotal"
                                        name='txtSubtotal'
                                        className="form-control"
                                        type="text"
                                        autoComplete='off'
                                        placeholder='$0.00'
                                        data-label='Subtotal'
                                        disabled={true}
                                        value={precio.txtSubtotal}
                                        onChange={() => null}
                                    />
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-3 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Iva</label>
                                    <div className="input-group input-gpack2">
                                        <NumericFormat
                                        id="txtIva"
                                        name='txtIva'
                                        type="text"
                                        className="form-control"
                                        autoComplete='off'
                                        placeholder='$0.00'
                                        data-label='Iva'
                                        disabled={true}
                                        value={precio.txtIva}
                                        onChange={() => null}
                                    />
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-3 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Total</label>
                                    <div className="input-group input-gpack2">
                                        <NumericFormat
                                        id="txtTotal"
                                        name='txtTotal'
                                        type="text"
                                        className="form-control"
                                        autoComplete='off'
                                        placeholder='$0.00'
                                        data-label='Total'
                                        disabled={true}
                                        value={precio.txtTotal}
                                        onChange={() => null}
                                    />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </Fragment>
}

export default Default