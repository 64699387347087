import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Box } from 'react-feather';

const Default = (props) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const navigate = useNavigate()


    
    return (
        <Fragment>
            <Breadcrumb icon={Box} title={'Plantillas'} parent={['plantillas']} />
            <div className="card">
                <div className="col-xl-12 col-md-12">
                    {/* <div className="card-header d-flex justify-content-center p-3">
                        <h4>Productos Servicios</h4>
                        </div> */}
                    <div className="mt-3">
                        <Table
                            noRutaDinamica={true}
                            cargando={false}
                            htmlBtnPag={
                                <button
                                className="btn btn-purple btn-nuevo"
                                type="button"
                                onClick={() => {navigate('/plantillas/nuevo')}}
                                > Nueva plantilla
                                </button>
                            }
                            headers={[
                                {
                                    nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE', html: (d) => {
                                        return <div style={{ width: '25em' }}>
                                            {d.NOMBRE}
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'REGISTRO', tipo: 'html', texto: 'REGISTRO', html: (d) => {
                                        return <div style={{ width: '25em' }}>
                                            {d.FECHA}
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'DETALLE', tipo: 'html', texto: 'DETALLE',
                                    html: (d) => {
                                        return <div>
                                            <BtnVer
                                                onClick={() => {
                                                    navigate(`/plantillas/${Tools.urlFormat(d.NOMBRE)}`)
                                                }}
                                            />
                                        </div>
                                    }
                                }
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'/rt_plantillas_registros'}
                            refresh={esRefresh}
                            body={{}}
                            rowsPerPage={20}
                            paginadorTop={true}
                        >
                        </Table>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Default;