import React, { Fragment, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate, useParams } from 'react-router-dom';
import Tools from '../../js/tools'
import notie from 'notie'
import Select from '../common/select-basic'
import { BtnEditar, BtnEliminar } from '../common/table_buttons'
import Table from '../common/tabla'

const Default = ({
    formCliente,
    setFormCliente,
    catalogos
}) => {
    const navigate = useNavigate()
    const params = useParams();
    const [esRefresh, setEsRefresh] = useState(true)
    const [formProdServ, setFormProdServ] = useState({})

    const onClickAsignarPrecio = () => {
        const _formProdServ = Tools.getForm('formProdServ', {claveEtiquetas: '.txtVacios'})   
   
        if(_formProdServ.formIsEmpty){
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_formProdServ.stringIsEmpty})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }
           
        Tools.toolFetch({
            ruta: formProdServ.esEditar?'rt_clientes_precios_editar':'rt_clientes_precios_nuevo',
            method: 'POST',
            body: {
                idCl: formCliente.ID_CL,
                id: formProdServ.ID_DSC,
                ..._formProdServ.data
            },
        }, (response) => {
            if(response.estatus === 'EXISTE'){
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ya hay un precio preferencial para el producto que intentas agregar`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            _formProdServ.documentForm.reset()
            setFormProdServ({})
            setEsRefresh((s) => !s)

            return notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Precio preferencial agregado correctamente.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const onClickEliminar = (d) => {
        notie.confirm({
            text: `Se eliminara <strong>${d.NOMBRE_PS}</strong> ¿estas de acuerdo?`,
            submitText: 'Si, eliminar',
            cancelText: 'No, cancelar',
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_clientes_precios_eliminar',
                    method: 'POST',
                    body: {id: d.ID_DSC},
                }, (response) => {
                    if (response.estatus === 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = false
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }

                    if (response.estatus === 'NO-EXISTE') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `El registro ${d.NOMBRE_PS} ya no esta disponible`,
                            // stay: Boolean, // optional, default = false
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }
        
                    setEsRefresh((s) => !s)
        
                    return notie.alert({
                        type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `El registro ha sido eliminado correctamente.`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                })
            },
            cancelCallback: function (value) {  
            },
        })
    }

    return (
        <Fragment>
            <div className="card">
                <div className='row card-body'>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h6 className="mb-3"><strong>PRECIO PREFERENCIAL</strong></h6>
                        <form className="row" id='formProdServ'>
                            <div className={`form theme-form needs-validation tooltip-validation form-row d-flex justify-content-start`}>
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="validationCustomUsername">Producto servicio<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <Select
                                            className="form-control form-control-md border border-dark"
                                            data-label="Producto servicio"
                                            placeholder="Seleccionar producto servicio"
                                            id="list_prod_serv"
                                            name="list_prod_serv"
                                            datos={catalogos.ctgProdServ}
                                            value={formProdServ.ID_PS_DSC}
                                            onChange={(e) => setFormProdServ((s) => ({...s, ID_PS_DSC: e.item.clave}))}
                                        />
                                    </div>
                                    <span className="text-danger txtVacios list_prod_serv" style={{display: 'none'}}><strong>Falta completar (Producto servicio)</strong></span>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="validationCustomUsername">Tipo factor<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <Select
                                            className="form-control form-control-md border border-dark"
                                            data-label="Tipo factor"
                                            placeholder="Seleccionar tipo factor"
                                            id="list_tipo_factor"
                                            name="list_tipo_factor"
                                            value={formProdServ.TIPO_FACTOR_DSC}
                                            datos={catalogos.ctgFactor}
                                            onChange={(e) => setFormProdServ((s) => ({...s, TIPO_FACTOR_DSC: e.item.clave}))}
                                        />
                                    </div>
                                    <span className="text-danger txtVacios list_tipo_factor" style={{display: 'none'}}><strong>Falta completar (Tipo factor)</strong></span>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="validationCustomUsername">Valor<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <NumericFormat 
                                            className="form-control text-end" 
                                            thousandSeparator="," 
                                            id="txt_valor" name="txt_valor"
                                            data-label={'Valor'}
                                            placeholder='$0.00'
                                            autoComplete='off'
                                            value={formProdServ.VALOR_DSC || ''}
                                            onValueChange={(datos) => setFormProdServ((s) => ({...s, VALOR_DSC: datos.floatValue}))}
                                        />
                                    </div>
                                    <span className="text-danger txtVacios txt_valor" style={{display: 'none'}}><strong>Falta completar (Valor)</strong></span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end mt-1">
                        <div className="text-left">
                            {formProdServ.esEditar?
                            <Fragment>
                                <button 
                                    className="btn btn-purple me-3" 
                                    type="button"
                                    onClick={() => onClickAsignarPrecio()}
                                >Editar precio</button>
                                <button 
                                    className="btn btn-back btn-sm" 
                                    type="button"
                                    onClick={() => setFormProdServ({})}
                                >Cancelar</button>
                            </Fragment>
                            :<Fragment>
                                <button 
                                    className="btn btn-purple mr-3" 
                                    type="button"
                                    onClick={() => onClickAsignarPrecio()}
                                >Agregar precio</button>
                            </Fragment>}
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h6 className="mb-3 mt-5"><strong>PRECIOS AGREGADOS</strong></h6>
                        <Table 
                            cargando={false}
                            headers={[
                                {
                                    nombre: 'PRODUCTO', tipo: 'html', texto: 'NOMBRE_PS', html: (d) => {
                                        return <div style={{width: '25em'}}>{d.NOMBRE_PS}</div>
                                    }
                                },
                                {
                                    nombre: 'FACTOR', tipo: 'html', texto: 'TIPO_FACTOR_DSC', html: (d) => {
                                        return <div>{d.TIPO_FACTOR_DSC}</div>
                                    }
                                },
                                {
                                    nombre: 'VALOR', tipo: 'html', texto: 'VALOR_DSC', html: (d) => {
                                        return <div style={{width: '25em'}}>$ {d.VALOR_TEXT}</div>
                                    }
                                },
                                {
                                    nombre: 'DETALLE', tipo: 'html', texto: 'DETALLE',
                                    html: (d) => {
                                        return <div>
                                            <BtnEditar
                                                onClick={() => {setFormProdServ({esEditar: true, ...d})}}
                                            />
                                            <BtnEliminar
                                                onClick={() => onClickEliminar(d)}
                                            />
                                        </div>
                                    }
                                }
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'/rt_clientes_precios'}
                            refresh={esRefresh}
                            body={{id: params.id}}
                            rowsPerPage={20}
                            paginadorTop={true}
                        >
                        </Table>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end mt-3">
                        <div className="text-left">
                            {/* <button className="btn btn-primary btn-lg" type="button"
                                onClick={() => editarCliente()}>Editar Datos del Cliente</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;