import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

const EnviosPaquete = () => {
    const [lista, setLista] = useState([]);
    const [nombre, setNombre] = useState('');

    const leerNombre = (e) => {
        setNombre(e.target.value);
    };

    const eliminarNombre = (key) => {

        fetch('/rt_eliminar_lista', {
            method: 'POST',
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({ key: key })

        }).then((response) => response.json())
            .then((datos) => {
                console.log('data', datos);
                setLista(datos)
            })
            .catch((error) => {
                console.error("Error al hacer fetch:", error);
            });
    };

    const cargarLista = (key) => {

        fetch('/rt_obtener_lista', {
            method: 'POST',
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({})
        }).then( async (response) => {
        
            // let a = await response.text()
            // console.log('response.text', a)
            let datos = await response.json()
            // console.log('response.json', b)
            setLista(datos)
            
            console.log(response)
        })
            // .then((datos) => {
            //     console.log('data', datos);
            //     setLista(datos)
            //     console.log(datos)
            // })
            .catch((error) => {
                console.error("Error al hacer fetch:", error);
            });
    };


    useEffect(() => {
        console.log(1)
        cargarLista();
    }, []);

  
    const agregarNombre = () => {

        // setLista([...lista, nombre]);

        fetch('/rt_agregar_lista', {
            method: 'POST',
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({ nombre: nombre })

        }).then((response) => response.json())
            .then((datos) => {
                console.log('data', datos);
                setLista(datos)
            })
            .catch((error) => {
                console.error("Error al hacer fetch:", error);
            });
    };

    const actualizarNombre = (key, item) => {
        Swal.fire({
            title: 'Actualizar el nombre',
            input: 'text',
            inputValue: item,
            confirmButtonText: 'Aceptar',
        }).then((result) => {
            if (result.isConfirmed) {
                const nuevoNombre = result.value;
                console.log('nombre actualizado:', nuevoNombre)
                fetch('/rt_actualizar_lista', {
                    method: 'POST',
                    headers: {
                        "content-type": "application/json",
                    },
                    body: JSON.stringify({ key, nuevoNombre })

                }).then((response) => response.json())
                    .then((datos) => {
                        console.log('data', datos);
                        setLista(datos)
                    })
                    .catch((error) => {
                        console.error("Error al hacer fetch:", error);
                    });
            }
        });
    };


    // return (
    //     <>
    //         <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto', fontFamily: 'Arial, sans-serif' }}>
    //             <h1 style={{ textAlign: 'center', fontSize: '2rem' }}>Invitación al CamiFest 2024</h1>
    //             <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
    //                 <input 
    //                     value={nombre} 
    //                     onChange={leerNombre} 
    //                     style={{ 
    //                         padding: '10px', 
    //                         fontSize: '1.2rem', 
    //                         borderRadius: '5px', 
    //                         border: '1px solid #ccc', 
    //                         marginRight: '10px', 
    //                         width: '70%' 
    //                     }} 
    //                 />
    //                 <button 
    //                     onClick={agregarNombre} 
    //                     style={{ 
    //                         padding: '10px 20px', 
    //                         fontSize: '1.2rem', 
    //                         borderRadius: '5px', 
    //                         backgroundColor: '#28a745', 
    //                         color: 'white', 
    //                         border: 'none', 
    //                         cursor: 'pointer' 
    //                     }}
    //                 >
    //                     Agregar
    //                 </button>
    //             </div>
    //             <ul style={{ listStyle: 'none', padding: '0' }}>
    //                 {lista.map((item, index) => (
    //                     <li 
    //                         key={index} 
    //                         style={{ 
    //                             backgroundColor: '#f9f9f9', 
    //                             margin: '10px 0', 
    //                             padding: '15px', 
    //                             borderRadius: '5px', 
    //                             display: 'flex', 
    //                             justifyContent: 'space-between', 
    //                             alignItems: 'center', 
    //                             fontSize: '1.2rem' 
    //                         }}
    //                     >
    //                         <span>{index+1}. {item}</span>
    //                         <div>
    //                             <button 
    //                                 onClick={() => actualizarNombre(index, item)} 
    //                                 className="btn btn-primary" 
    //                                 style={{ 
    //                                     marginRight: '10px', 
    //                                     backgroundColor: '#007bff', 
    //                                     border: 'none', 
    //                                     padding: '10px', 
    //                                     borderRadius: '5px', 
    //                                     cursor: 'pointer', 
    //                                     color: 'white' 
    //                                 }}
    //                             >
    //                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
    //                                     <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
    //                                     <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
    //                                 </svg>
    //                             </button>
    //                             <button 
    //                                 onClick={() => eliminarNombre(index)} 
    //                                 className="btn btn-danger" 
    //                                 style={{ 
    //                                     backgroundColor: '#dc3545', 
    //                                     border: 'none', 
    //                                     padding: '10px', 
    //                                     borderRadius: '5px', 
    //                                     cursor: 'pointer', 
    //                                     color: 'white' 
    //                                 }}
    //                             >
    //                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
    //                                     <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
    //                                 </svg>
    //                             </button>
    //                         </div>
    //                     </li>
    //                 ))}
    //             </ul>
    //         </div>
    //     </>
    // );

    return (
        <>
            <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto', fontFamily: 'Arial, sans-serif' }}>
                <h1 style={{ textAlign: 'center', fontSize: '2rem' }}>Invitación al CamiFest 2024</h1>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    <input
                        value={nombre}
                        onChange={leerNombre}
                        style={{
                            padding: '10px',
                            fontSize: '1.2rem',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                            marginRight: '10px',
                            width: '70%'
                        }}
                    />
                    <button
                        onClick={agregarNombre}
                        style={{
                            padding: '10px 20px',
                            fontSize: '1.2rem',
                            borderRadius: '5px',
                            backgroundColor: '#28a745',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        Agregar
                    </button>
                </div>
                <ul style={{ listStyle: 'none', padding: '0' }}>
                    {lista.map((item,index) => (
                        <li
                            key={item.ID}
                            style={{
                                backgroundColor: 'white',
                                margin: '10px 0',
                                padding: '15px',
                                borderRadius: '5px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                fontSize: '1.2rem'
                            }}
                        >
                            <span>{index+1}. {item.NOMBRE}</span>
                            <div>
                                <button
                                    onClick={() => actualizarNombre(item.ID, item.NOMBRE)}
                                    className="btn btn-primary"
                                    style={{
                                        marginRight: '10px',
                                        backgroundColor: '#007bff',
                                        border: 'none',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        color: 'white'
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                    </svg>
                                </button>
                                <button
                                    onClick={() => eliminarNombre(item.ID)}
                                    className="btn btn-danger"
                                    style={{
                                        backgroundColor: '#dc3545',
                                        border: 'none',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        color: 'white'
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );

};

export default EnviosPaquete;
