import React, { Fragment, useEffect } from 'react'
import Select from '../common/select-basic'
import {NumericFormat} from 'react-number-format';
import tools from '../../js/tools'

const Default = ({
    catalogos,
    onChangePesoVol,
    formCarga,
    setFormCarga,
    esResumen
}) => {

    useEffect(() => {
        let txtPesoVol = tools.calcularPesoVol(formCarga.txtLargo, formCarga.txtAlto, formCarga.txtAncho)
        setFormCarga((s) => ({...s, txtPesoVol}))        
    }, [formCarga.txtLargo, formCarga.txtAncho, formCarga.txtAlto])

    return <Fragment>
        <div className='row mb-3 mt-5'>
            <form id='formCarga' className="col-sm-12 col-md-12 col-xl-12">
                <div className="row">
                    <div className='col-sm-12 col-md-12 mb-4'>
                        <h4><strong>INFORMACIÓN DE CARGA</strong></h4>
                    </div>
                    <div className="col-sm-12 col-md-12">
                        <div className='row'>
                            <div className="col-sm-12 col-md-3 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Largo (CM)<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <NumericFormat 
                                        className="form-control text-end" 
                                        thousandSeparator="," 
                                        id="txtLargo" 
                                        name="txtLargo"
                                        placeholder=''
                                        data-label='Largo'
                                        autoComplete='off'
                                        disabled={esResumen}
                                        value={formCarga.txtLargo}
                                        onValueChange={(datos) => {
                                            setFormCarga((s) => ({
                                                ...s,
                                                txtLargo: datos.floatValue
                                            }))
                                        }}
                                    />
                                    </div>
                                    
                                    <span className="text-danger txtVacios txtLargo" style={{ display: 'none' }}><strong>Falta completar (Largo)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-3 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Ancho (CM)<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <NumericFormat 
                                        className="form-control text-end" 
                                        thousandSeparator="," 
                                        id="txtAncho" 
                                        name="txtAncho"
                                        placeholder=''
                                        data-label='Ancho'
                                        autoComplete='off'
                                        disabled={esResumen}
                                        value={formCarga.txtAncho}
                                        onValueChange={(datos) => {
                                            setFormCarga((s) => ({
                                                ...s,
                                                txtAncho: datos.floatValue
                                            }))
                                        }}
                                    />
                                    </div>
                                    
                                    <span className="text-danger txtVacios txtAncho" style={{ display: 'none' }}><strong>Falta completar (Ancho)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-3 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Alto (CM)<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <NumericFormat 
                                        className="form-control text-end" 
                                        thousandSeparator="," 
                                        id="txtAlto" 
                                        name="txtAlto"
                                        placeholder=''
                                        data-label='Alto'
                                        autoComplete='off'
                                        disabled={esResumen}
                                        value={formCarga.txtAlto}
                                        onValueChange={(datos) => {
                                            setFormCarga((s) => ({
                                                ...s,
                                                txtAlto: datos.floatValue
                                            }))
                                        }}
                                    />
                                    </div>
                                    
                                    <span className="text-danger txtVacios txtAlto" style={{ display: 'none' }}><strong>Falta completar (Alto)</strong></span>
                                </div>
                            </div>
                            {/* <div className="col-sm-12 col-md-3 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Peso volumetrico estimado<span className="text-danger">*</span></label>
                                    <Select
                                        className="form-control form-control-md border border-dark"
                                        data-label="Contacto"
                                        placeholder="Seleccionar contacto"
                                        id="listPesoVol"
                                        name="listPesoVol"
                                        tipoValue={1}
                                        disabled={esResumen}
                                        datos={catalogos.ctgPesoVol}
                                        value={formCarga.listPesoVol}
                                        onChange={onChangePesoVol}
                                    />
                                    <span className="text-danger txtVacios listPesoVol" style={{ display: 'none' }}><strong>Falta completar (Peso volumetrico estimado)</strong></span>
                                </div>
                            </div> */}
                            <div className="col-sm-12 col-md-3 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Peso real (KG)<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <NumericFormat 
                                        className="form-control text-end" 
                                        thousandSeparator="," 
                                        id="txtPesoReal" 
                                        name="txtPesoReal"
                                        placeholder=''
                                        data-label='Peso real'
                                        autoComplete='off'
                                        disabled={esResumen}
                                        value={formCarga.txtPesoReal}
                                        onValueChange={(datos) => {
                                            setFormCarga((s) => ({
                                                ...s,
                                                txtPesoReal: datos.floatValue
                                            }))
                                        }}
                                    />
                                    </div>
                                    
                                    <span className="text-danger txtVacios txtPesoReal" style={{ display: 'none' }}><strong>Falta completar (Peso real)</strong></span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-3 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Peso volumétrico (KG)<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <NumericFormat 
                                        className="form-control text-end" 
                                        thousandSeparator="," 
                                        id="txtPesoVol" 
                                        name="txtPesoVol"
                                        placeholder=''
                                        data-label='Peso volumétrico'
                                        autoComplete='off'
                                        disabled={true}
                                        value={formCarga.txtPesoVol}
                                        onValueChange={(datos) => {
                                            setFormCarga((s) => ({
                                                ...s,
                                                txtPesoVol: datos.floatValue
                                            }))
                                        }}
                                    />
                                    </div>
                                    
                                    <span className="text-danger txtVacios txtPesoVol" style={{ display: 'none' }}><strong>Falta completar (Peso volumétrico)</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </Fragment>
}

export default Default