import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Box } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom';
import Dropzone from '../common/dropzone';
import SelectFetch from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import LoaderSeccion from '../../components/common/loader_secciones';

const Default = ({ esEditar }) => {
    const [catalogos, setCatalogos] = useState({})
    const [selectSuc, setSelectSuc] = useState([])
    const [guiasPrep, setGuiasPrep] = useState(null)
    const [tipoGuia, setTipoGuia] = useState({})
    const [tipoServicio, setTipoServicio] = useState(null)
    const [tipoEnvio, setTipoEnvio] = useState(null)
    const [refreshPrimeraCarga, setRefreshPrimeraCarga] = useState(true)
    const [img, setImg] = useState({})
    const navigate = useNavigate()
    const params = useParams()

    const uploadImg = (img, datos) => {
        let arrImg = []

        if (img.imgPortada) {
            arrImg = [...arrImg, { img: img.imgPortada, nombre: 1 }]
        }

        if (img.imgDetalle) {
            arrImg.push({ img: img.imgDetalle, nombre: 2 })
        }

        let length = arrImg.length

        let arrRes = [], inputs = {
            ruta: `/rt_upload_img_guias_prepagadas`,
            arr: arrImg,
            index: 0,
            id: datos.ID_GPP
        }

        console.log(634545672, arrImg)

        let callback = (datos) => {
            console.log(432, datos);
            
            if (datos.index <= length - 1) {
                arrRes.push(datos)
                Tools.subirImg({
                    ...inputs,
                    arr: arrImg,
                    index: datos.index
                }, callback)
            }else {
                if (!esEditar) {
                    setTimeout(() => {
                        navigate('/guias-prepagadas')
                    }, 3000)
                }

                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: 'Registro realizado exitosamente.',
                    stay: false, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
        }

        Tools.subirImg(inputs, callback)
    }

    const uploadImgTpv = (img, datos) => {
        let arrImg = []

        if (img.imgPortada) {
            arrImg = [...arrImg, { img: img.imgPortada, nombre: 1 }]
        }

        if (img.imgDetalle) {
            arrImg.push({ img: img.imgDetalle, nombre: 2 })
        }

        let length = arrImg.length
        let arrRes = [], inputs = {
            ruta: `${datos.URL_PRO}/rt_upload_img_guias_prepagadas`,
            arr: arrImg,
            index: 0,
            id: datos.ID_GPP
        }

        let callback = (datos) => {
            if (datos.index <= length - 1) {
                arrRes.push(datos)
                Tools.subirImg({
                    ...inputs,
                    arr: arrImg,
                    index: datos.index
                }, callback)
            } else {
                if (!esEditar) {
                    setTimeout(() => {
                        navigate('/guias-prepagadas')
                    }, 3000)
                }

                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: 'Registro realizado exitosamente.',
                    stay: false, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
        }

        Tools.subirImg(inputs, callback)
    }

    const guardar = () => {
        const _guiasPrep = Tools.getForm('formguiasPrep', { claveEtiquetas: '.txtVacios', ignore: ['list_costo_extra'] })

        console.log(_guiasPrep);
        
        _guiasPrep.data.txt_nombre = _guiasPrep.data.txt_nombre + ' ' + _guiasPrep.data.txt_cantidad 
        _guiasPrep.data.list_grupo = 1
        

        if (_guiasPrep.formIsEmpty || (!img.imgPortada && !esEditar)) {
            let mensajeP = ''
            if (!img.imgPortada && !esEditar) {
                mensajeP = `${_guiasPrep.formIsEmpty ? `, ` : ''}Portada`
                setImg((s) => ({ ...s, statusPortada: 'removed', imgPortada: null }))
            }
            let mensaje = `${_guiasPrep.formIsEmpty ? _guiasPrep.stringIsEmpty : ''}${mensajeP}`
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${mensaje})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }
        Tools.toolFetch({
            ruta: esEditar ? 'rt_guias_prepagadas_editar' : 'rt_guias_prepagadas_nuevo',
            method: 'POST',
            body: {
                selectSuc: selectSuc.map((s) => ({ value: s.value })),
                ...(guiasPrep ? { id_gpp: guiasPrep.ID_GPP } : {}),
                ..._guiasPrep.data,
            },
        }, (response) => {
            if (response.estatus === 'ERROR-PRECIO-0') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El precio mínimo que el producto <strong>${_guiasPrep.data.txt_nombre}</strong> puede tener es de $0.01`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            if (response.estatus === 'EXISTE') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Producto <strong>${_guiasPrep.data.txt_nombre}</strong> existente intente con otro nombre diferente`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (!esEditar) {
                uploadImg(img, response.datos)
                uploadImgTpv(img, response.datos)
            } else {
                guiasPrep.ID_GPP = response.datos.ID_GPP
                uploadImg(img, response.datos)
                uploadImgTpv(img, response.datos)
                guardarSucursales()
                window.location.replace( `/guias-prepagadas/guias-prepagadas-${document.getElementById('txt_cantidad').value}`)
            }
        })
    }

    const guardarSucursales = () => {
        const _guiasPrep = Tools.getForm('formguiasPrep', {
            claveEtiquetas: '.txtVacios',
            ignore: ['list_grupo', 'list_prod_serv', 'list_suc', 'list_tipo', 'txt_desc', 'txt_nombre'],
        })

        if (_guiasPrep.formIsEmpty) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_guiasPrep.stringIsEmpty})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (!selectSuc.length) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar seleccionar (Sucursales)`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        Tools.toolFetch({
            ruta: 'rt_guias_prepagadas_suc_editar',
            method: 'POST',
            body: {
                selectSuc,
                list_tipo: tipoGuia.clave,
                id_gpp: guiasPrep.ID_GPP,
                ..._guiasPrep.data
            },
        }, (response) => {
            if (response.estatus === 'ERROR-PRECIO-0') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El precio mínimo que el producto <strong>${_guiasPrep.data.txt_nombre}</strong> puede tener es de $0.01`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            // setRefreshPrimeraCarga((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: 'Se actualizaron correctamente las sucursales.',
                stay: false, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const guardarImg = (tipoImg) => {
        if (tipoImg === 1 && !img.imgPortada && esEditar) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (Portada)`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (tipoImg === 1) {
            uploadImg({ imgPortada: img.imgPortada }, guiasPrep)
            // uploadImgTpv({ imgPortada: img.imgPortada }, guiasPrep)
        } else {
            if(img.imgPortada){
                uploadImg({ imgDetalle: img.imgDetalle }, guiasPrep)
            }
            // uploadImgTpv({ imgDetalle: img.imgDetalle }, guiasPrep)
        }
    }

    function validarNumeros(input) {
        input.target.value = input.target.value.replace(/[^0-9]/g, '');
    }

    useEffect(() => {
        let isSubscribed = true

        if (esEditar) {
            return Promise.all([
                fetch('/rt_guias_prepagadas_detalle', {
                    method: 'POST',
                    headers: { "Content-Type": 'application/json' },
                    body: JSON.stringify({ nombreGuiasPrep: params.id })
                }),
                fetch('/rt_ctg_gpo_gp', {
                    method: 'POST',
                    headers: { "Content-Type": 'application/json' },
                    body: JSON.stringify({})
                }),
            ])
                .then(async (response) => {
                    const detalle = await response[0].json()
                    const ctgGpo = await response[1].json()

                    if (!isSubscribed) {
                        return
                    }

                    const datos = ctgGpo.datos
                    setCatalogos((s) => ({ ...s, ...datos }))

                    // DETALLE
                    setTipoGuia({ clave: detalle.datos.guiasPrep.TIPO_GPP })
                    const preciosSucursales = (detalle.datos.preciosSuc.map((v) => ({ value: v.ID_SUC_PRE, label: v.NOMBRE_SUC, precio: v.PRECIO_PRE })))
                    setSelectSuc(preciosSucursales)
                    setGuiasPrep(detalle.datos.guiasPrep)

                })
        }

        Tools.toolFetch({
            ruta: 'rt_ctg_gpo_gp',
            method: 'POST',
            body: {},
        }, (response) => {
            if (isSubscribed) {
                if (response.estatus === 'ERROR') {
                    return navigate('/guias-prepagadas')
                }
                const datos = response.datos
                setCatalogos((s) => ({ ...s, ...datos }))
            }
        })

        return () => isSubscribed = false
    }, [refreshPrimeraCarga])

    return (
        <Fragment>
            {esEditar?<LoaderSeccion status={guiasPrep}/>:''}
            <Breadcrumb icon={Box} title={'Guías prepagadas'} parent={['guias-prepagadas']} />
            <div className="container-fluid">
                <div className='row'>
                    <form id='formguiasPrep' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className="btn btn-regresar" type="button"
                                    onClick={(e) => {
                                        navigate('/guias-prepagadas')
                                    }} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVO'} PAQUETE DE GUÍAS</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Datos generales del paquete de guías</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label htmlFor="validationTooltip02">Nombre<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control"
                                                autoComplete='off'
                                                id="txt_nombre" name="txt_nombre"
                                                type="text"
                                                placeholder="Nombre"
                                                defaultValue="Guías prepagadas"
                                                readOnly
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Tipo<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Tipo"
                                                placeholder="Seleccionar tipo"
                                                id="list_tipo"
                                                name="list_tipo"
                                                tipoValue={1}
                                                value="5"
                                                datos={catalogos.ctgTipos}
                                                disableDropdown={true}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios list_tipo" style={{ display: 'none' }}><strong>Falta completar (Tipo)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Claves unidades<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Claves unidades"
                                                placeholder="Seleccionar clave unidad"
                                                id="list_clave_ud"
                                                name="list_clave_ud"
                                                value={guiasPrep ? guiasPrep.CLAVE_CU_GPP : ''}
                                                tipoValue={1}
                                                datos={catalogos.ctgClavesUds}
                                                onChange={() => null}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios list_clave_ud" style={{ display: 'none' }}><strong>Falta completar (Claves unidades)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3" hidden>
                                        <label className="form-label">Grupo<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <input className="form-control form-control-md border border-dark"
                                                data-label="Grupo"
                                                id="list_grupo"
                                                name="list_grupo"
                                                autoComplete='off'
                                                type="text"
                                                value={1}
                                                readOnly
                                            />
                                        </div>
                                        <span className="text-danger txtVacios list_grupo" style={{ display: 'none' }}><strong>Falta completar (Grupo)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Clave Producto Servicio<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <SelectFetch
                                                id={'list_prod_serv'}
                                                name={'list_prod_serv'}
                                                ruta='rt_ctg_prod_serv'
                                                label={'Producto Servicio'}
                                                clave_cps={guiasPrep ? guiasPrep.CLAVE_CPS : ''}
                                                value={guiasPrep ? { value: guiasPrep.CLAVE_CPS, label: `${guiasPrep.CLAVE_CPS} ${guiasPrep.PROD_SERV_TEXT}` } : null}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios list_prod_serv" style={{ display: 'none' }}><strong>Falta completar (Clave Producto Servicio)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Tipo de servicio<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Tipo de servicio"
                                                placeholder="Seleccionar tipo de servicio"
                                                id="list_serv"
                                                name="list_serv"
                                                tipoValue={1}
                                                datos={catalogos.ctgTipoServ}
                                                value={guiasPrep ? guiasPrep.ID_PS_GPP : ''}
                                                onChange={(datos) => {
                                                    esEditar ? 
                                                    setTipoServicio((s) => ({...s, ID_PS_GPP: datos.item.clave}))
                                                    : setTipoServicio()
                                                }}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios list_serv" style={{ display: 'none' }}><strong>Falta completar (Tipo de servicio)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Tipo de envío<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Tipo de envío"
                                                placeholder="Seleccionar tipo de envío"
                                                id="list_env"
                                                name="list_env"
                                                tipoValue={1}
                                                datos={catalogos.ctgTipoEnv}
                                                value={guiasPrep ? guiasPrep.ID_TP_GPP : ''}
                                                onChange={(datos) => {
                                                    esEditar ? 
                                                    setTipoEnvio((s) => ({...s, ID_TP_GPP: datos.item.clave}))
                                                    : setTipoEnvio()
                                                }}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios list_env" style={{ display: 'none' }}><strong>Falta completar (Tipo de envío)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label htmlFor="validationTooltip02">Cantidad de guías<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control"
                                                autoComplete='off'
                                                id="txt_cantidad" name="txt_cantidad"
                                                type="text"
                                                placeholder="0"
                                                defaultValue={guiasPrep ? guiasPrep.CANTIDAD_GPP : ''}
                                                onChange={validarNumeros}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Cantidad)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label htmlFor="validationTooltip02">Peso máximo (kg)<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <NumericFormat
                                                className="form-control"
                                                id={`txt_peso`} name={`txt_peso`}
                                                placeholder='0.00'
                                                autoComplete='off'
                                                data-label={`Peso máximo`}
                                                value={guiasPrep ? guiasPrep.PESO_MAXIMO_GPP : ''}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_peso" style={{ display: 'none' }}><strong>Falta completar (Peso máximo)</strong></span>
                                    </div>
{/*                                     
                                    {tipoGuia.clave == 5 ? selectSuc.map((v, i) =>
                                        <Fragment key={v.label + i + v.value}>
                                            <div className="col-md-3 mb-3">
                                                <label htmlFor="validationTooltip02">Precio<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <NumericFormat
                                                        className="form-control text-end"
                                                        id={`txt_suc12`} name={`txt_suc12`}
                                                        placeholder='0.00'
                                                        autoComplete='off'
                                                        data-label={`GPPACK EN LINEA`}
                                                        defaultValue={v.precio}
                                                    />
                                                </div>
                                                <span className={`text-danger txtVacios txt_suc12`} style={{ display: 'none' }}><strong>Falta completar (Precio)</strong></span>
                                            </div>
                                        </Fragment>
                                        ) : 
                                        <Fragment >
                                            <div className="col-md-3 mb-3">
                                                <label htmlFor="validationTooltip02">Precio<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <NumericFormat
                                                        className="form-control text-end"
                                                        id={`txt_suc12`} name={`txt_suc12`}
                                                        placeholder='0.00'
                                                        autoComplete='off'
                                                        data-label={`GPPACK EN LINEA`}
                                                    />
                                                </div>
                                                <span className={`text-danger txtVacios txt_suc12`} style={{ display: 'none' }}><strong>Falta completar (Precio)</strong></span>
                                            </div>
                                        </Fragment>
                                        } */}
                                    <div className="col-md-3 mb-3">
                                        <label htmlFor="validationTooltip02">Periodo de caducidad<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control"
                                                autoComplete='off'
                                                id="txt_periodo" name="txt_periodo"
                                                type="text"
                                                placeholder="0"
                                                defaultValue={guiasPrep ? guiasPrep.CADUCIDAD_PERIODO_GPP : ''}
                                                onChange={validarNumeros}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Periodo de caducidad)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label htmlFor="validationTooltip02">Periodicidad de caducidad<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Tipo"
                                                placeholder="Seleccionar periodicidad"
                                                id="txt_periodicidad"
                                                name="txt_periodicidad"
                                                tipoValue={1}
                                                value={guiasPrep ? guiasPrep.CADUCIDAD_PERIODICIDAD_GPP : ''}
                                                datos={[{clave: 'DAY', label: 'Día(s)'}, {clave: 'MONTH', label: 'Mes(es)'}, {clave: 'YEAR', label: 'Año(s)'}]}
                                                onChange={() => null}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Periodicidad de caducidad)</strong></span>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">Descripción<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <textarea style={{ width: '100%' }} rows={5} id='txt_desc' name='txt_desc' placeholder='Descripción' defaultValue={guiasPrep ? guiasPrep.DESCRIPCION_GPP : ''}></textarea>
                                        </div>
                                        <span className="text-danger txtVacios txt_desc" style={{ display: 'none' }}><strong>Falta completar (Descripción)</strong></span>
                                    </div>
                                    {esEditar ? 
                                    <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            <button
                                                className="btn btn-purple d-flex align-items-center"
                                                type="button"
                                                onClick={(e) => guardar()}
                                            >Guardar paquete</button>
                                        </div>
                                    </div>
                                    : ''}
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Imagenes que seran mostradas en TPV y Sitio</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Portada<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <div className="input-group-prepend"></div>
                                            <Dropzone
                                                initialFiles={guiasPrep ? '/pro-serv-img/guias/' + guiasPrep.ID_GPP + '/' + guiasPrep.IMG_PORTADA_URL : null}
                                                fileName={guiasPrep ? guiasPrep.IMG_PORTADA_GPP : ''}
                                                onImg={(datos) => { setImg((s) => ({ ...s, statusPortada: datos.status, imgPortada: datos.file })) }}
                                            />
                                        </div>
                                        <span className="text-danger" style={{ display: img.statusPortada === 'removed' ? '' : 'none' }}><strong>Falta completar (Portada)</strong></span>
                                        {esEditar ?
                                            <div className="justify-content-end d-flex mt-2">
                                                <button className="btn btn-purple d-flex align-items-center" type="button"
                                                    onClick={(e) => guardarImg(1)} >Guardar Portada</button>
                                            </div>
                                            : ''}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Detalle (Opcional)</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend"></div>
                                            <Dropzone
                                                initialFiles={guiasPrep ? '/pro-serv-img/guias/' + guiasPrep.ID_GPP + '/' + guiasPrep.IMG_DETALLE_URL : null}
                                                fileName={guiasPrep ? guiasPrep.IMG_DETALLE_GPP : ''}
                                                onImg={(datos) => { setImg((s) => ({ ...s, statusDatelle: datos.status, imgDetalle: datos.file })) }}
                                            />
                                        </div>
                                        {esEditar ?
                                            <div className="justify-content-end d-flex mt-2">
                                                <button className="btn btn-purple d-flex align-items-center" type="button"
                                                    onClick={(e) => guardarImg(2)} >Guardar Detalle</button>
                                            </div>
                                            : ''}
                                    </div>
                                    <Fragment>
                                        <Fragment>
                                            <div className="col-md-12 mb-3">
                                                <h6><strong>Precios por sucursal</strong></h6>
                                                <hr />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className="form-label">Selecciona sucursales<span className="text-danger">*</span></label>
                                                <SelectSimple
                                                    id={'list_suc'}
                                                    name={'list_suc'}
                                                    isMulti={true}
                                                    options={catalogos.ctgSuc}
                                                    value={selectSuc}
                                                    onChange={(datos) => {
                                                        setSelectSuc(datos)
                                                    }}
                                                />
                                            </div>
                                        </Fragment>
                                        {selectSuc.map((v, i) =>
                                            <Fragment key={v.label + i + v.value}>
                                                <div className="col-md-4 mb-3">
                                                    <label htmlFor="validationTooltip02">{v.label}<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <NumericFormat
                                                            className="form-control text-end"
                                                            id={`txt_suc${v.value}`} name={`txt_suc${v.value}`}
                                                            placeholder='0.00'
                                                            autoComplete='off'
                                                            data-label={`${v.label}`}
                                                            defaultValue={v.precio}
                                                        />
                                                    </div>
                                                    <span className={`text-danger txtVacios txt_suc${v.value}`} style={{ display: 'none' }}><strong>Falta completar Precio ({v.label})</strong></span>
                                                </div>
                                            </Fragment>
                                        )}
                                        {selectSuc.map((v, i) =>
                                            <Fragment key={v.label + i + v.value} >
                                                <div className="col-md-4 mb-3" hidden>
                                                    <label htmlFor="validationTooltip02">{v.label}<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <NumericFormat
                                                            className="form-control text-end"
                                                            id={`txt_suc${v.value}`} name={`txt_suc${v.value}`}
                                                            placeholder='0.00'
                                                            autoComplete='off'
                                                            data-label={`${v.label}`}
                                                            defaultValue={0.00}
                                                        />
                                                    </div>
                                                    <span className={`text-danger txtVacios txt_suc${v.value}`} style={{ display: 'none' }}><strong>Falta completar Precio ({v.label})</strong></span>
                                                </div>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                    {esEditar ?
                                        <div className="col-md-12 mb-3">
                                            <div className="justify-content-end d-flex">
                                                <button
                                                    className="btn btn-purple d-flex align-items-center"
                                                    type="button"
                                                    onClick={(e) => guardarSucursales()}
                                                >Guardar Precios</button>
                                            </div>
                                        </div>
                                    : ''}

                                    {!esEditar ? <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            <button
                                                className="btn btn-purple d-flex align-items-center"
                                                type="button"
                                                onClick={(e) => guardar()}
                                            >Guardar paquete</button>
                                        </div>
                                    </div> : ''}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default