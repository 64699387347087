import React, { Fragment, useState, useEffect } from 'react';
import { CreditCard } from 'react-feather';
//FORMULARIOS
import FormEmpresa from './clientes_registro/form_cliente'
import FormDireccion from './clientes_registro/form_direccion'
import FormDatosFiscales from './clientes_registro/form_datos_fiscales'
import FormTodos from './clientes_registro/form_resumen'
import Breadcrumb from '../common/breadcrumbv2';

const Default = (props) => {
    const [formDireccion, setFormDireccion] = useState({})
    const [formEmpresa, setFormEmpresa] = useState({})
    const [formDF, setFormDF] = useState({})
    const [catalogos, setCatalogos] = useState({
        ctgEdo: [],
        ctgCd: [],
        ctgDirEdo: [],
        ctgDirCd: [],
        ctgDfEdo: [],
        ctgDfCd: [],
        ctgDfUsoCfi: [],
        ctgDfRegimenFiscal: []
    })

    const [state, setState] = useState({
        estatusMostrarMsj: false,
        activoListaCatalogos: false,
        siguienteFormulario: 1,
        jsonVacio: {},
        jsonTel: {},
        jsonList: {},
        jsonCorreo: {}
    })

    useEffect(() => {
        let isS = true
        fetch('/rt_ctg_cliente', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then(async (response) => {
                if (!isS) {
                    return
                }

                if (response.estatus === 'OK') {
                    setState((s) => ({ ...s, activoListaCatalogos: true }))

                    setCatalogos((s) => ({ ...s, ...response.datos }))
                }
            })
        return () => isS = false
    }, [])

    return (
        <Fragment>
            <Breadcrumb icon={CreditCard} title={'NUEVO CLIENTE'} parent={['clientes']} />
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">
                        <div className="u-pearls-lg row d-flex justify-content-center">
                            {
                                [
                                    (index, key) => <div key={key} className={`u-pearl ${index == 1 ? 'current' : index > 1 ? 'done' : ''} col-2`}><span className="u-pearl-number">1</span><span className="u-pearl-title">Cliente</span></div>,
                                    (index, key) => <div key={key} className={`u-pearl ${index == 2 ? 'current' : index < 2 ? '' : 'done'} col-2`}><span className="u-pearl-number">2</span><span className="u-pearl-title">Dirección de Entrega</span></div>,
                                    (index, key) => <div key={key} className={`u-pearl ${index == 3 ? 'current' : index < 4 ? '' : 'done'} col-2`}><span className="u-pearl-number">3</span><span className="u-pearl-title">Datos Fiscales</span></div>,
                                    (index, key) => <div key={key} className={`u-pearl ${index == 4 ? 'current' : index < 4 ? '' : 'done'} col-2`}><span className="u-pearl-number">4</span><span className="u-pearl-title">Resumen</span></div>
                                ]
                                    .map((v, i) => (v(state.siguienteFormulario, i)))
                            }
                        </div>
                    </div>
                </div>
            </div>
            {state.siguienteFormulario === 1 ?
                <FormEmpresa
                    state={state}
                    setState={setState}

                    formEmpresa={formEmpresa}
                    setFormEmpresa={setFormEmpresa}

                    catalogos={catalogos}
                    setCatalogos={setCatalogos}
                />
                : ''}
            {state.siguienteFormulario === 2 ?
                <FormDireccion
                    state={state}
                    setState={setState}

                    formDireccion={formDireccion}
                    setFormDireccion={setFormDireccion}

                    catalogos={catalogos}
                    setCatalogos={setCatalogos}
                />
                : ''}
            {state.siguienteFormulario === 3 ?
                <FormDatosFiscales
                    state={state}
                    setState={setState}

                    catalogos={catalogos}

                    formDF={formDF}
                    setFormDF={setFormDF}
                    setCatalogos={setCatalogos}
                />
                : ''}
            {state.siguienteFormulario === 4 ?
                <FormTodos
                    state={state}
                    setState={setState}

                    catalogos={catalogos}
                    setCatalogos={setCatalogos}

                    formDireccion={formDireccion}
                    formEmpresa={formEmpresa}
                    formDF={formDF}
                    setFormDF={setFormDF}
                />
                : ''}
        </Fragment>)
}

export default Default;