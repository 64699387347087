import React, { Fragment, useState } from 'react';
import FormContacto from './contactos_form'
import Tools from '../../../js/tools'
import { BtnVer, BtnEliminar } from '../../common/table_buttons'
import Table from '../../common/tabla'
import notie from 'notie'

const Default = ({
    formCliente
}) => {
    const [formContacto, setFormContacto] = useState({form: 'tabla'})
    const [esRefresh, setEsRefresh] = useState(false)

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_clientes_contacto_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            {formContacto.form === 'tabla' ?
            <div className="row">
                <div className="card">
                    <div className="col-xl-12 col-md-12">
                        <div className="card-header d-flex justify-content-center p-3">
                            <h5>DATOS CONTACTO</h5>
                        </div>
                        <div className="mt-3">
                            <Table
                                noRutaDinamica={true}
                                cargando={false}
                                htmlBtnPag={ 
                                <button
                                    className="btn btn-purple btn-nuevo"
                                    type="button"
                                    onClick={() => {
                                        setFormContacto({form: 'nuevo'})
                                    }}
                                > Nuevo Contacto
                                </button>}
                                headers={[
                                    {
                                        nombre: 'NOMBRES', tipo: 'html', texto: 'NOMBRES_CLC', html: (d) => {
                                            return <div style={{ width: '25em' }}>
                                                {d.NOMBRES_CLC}
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'APELLIDOS', tipo: 'html', texto: 'APELLIDOS_CLC', html: (d) => {
                                            return <div style={{ width: '25em' }}>
                                            {d.APELLIDOS_CLC}
                                        </div>
                                        }
                                    },
                                    {
                                        nombre: 'CONTACTO', tipo: 'html', texto: 'CORREO_CLC', html: (d) => {
                                            return <div style={{ width: '25em' }}>
                                            <strong>Correo:</strong> {d.CORREO_CLC}<br/>
                                            {d.CELULAR_CLC?<Fragment><strong>Celular:</strong> {d.CELULAR_CLC}<br/></Fragment>:''}
                                            {d.TELEFONO_CLC?<Fragment><strong>Telefono:</strong> {d.TELEFONO_CLC}<br/></Fragment>:''}
                                        </div>
                                        }
                                    },
                                    {
                                        nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
                                        html: (d) => {
                                            return <div>
                                            <BtnVer
                                                onClick={() => {
                                                    setFormContacto((s) => ({...s, form: 'editar', ...d}))
                                                }} 
                                            />
                                            <BtnEliminar
                                                onClick={() => {
                                                    notie.confirm({
                                                        text: `Se eliminara ${d.NOMBRES_CLC} ${d.APELLIDOS_CLC} ¿estas de acuerdo?`,
                                                        submitText: 'Si, eliminar',
                                                        cancelText: 'No, Cancelar',
                                                        cancelCallback: function (value) {
                                                        },
                                                        submitCallback: function (value) {
                                                            eliminar({ id_clc: d.ID_CLC })
                                                        },
                                                    })
                                                }} 
                                            />
                                            </div>
                                        }
                                    }
                                ]}
                                totalField={'NR'} /* NR = número de resultados totales */
                                path={'/rt_clientes_contacto'}
                                refresh={esRefresh}
                                body={{id_cliente: formCliente.ID_CL}}
                                rowsPerPage={20}
                                paginadorTop={true}
                            >
                            </Table>
                        </div>
                    </div>
                </div>
            </div> : ''}
            {formContacto.form !== 'tabla'?
                <FormContacto
                    formCliente={formCliente}
                    formContacto={formContacto}
                    setFormContacto={setFormContacto}
                />
            : ''}
        </Fragment>
    );
};

export default Default;