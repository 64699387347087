import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Clipboard } from 'react-feather';
import { Accordion, AccordionItem } from "react-light-accordion";
import LoaderSeccion from '../../components/common/loader_secciones';
import "react-light-accordion/demo/css/index.css";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown
} from "reactstrap";

export const groupArrayByKey = (xs, key, param) => {
    return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || { key: x[param], array: [] }).array.push(x);
        return rv;
    }, {});
}

const Default = (props) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const [solicitud, setSolicitud] = useState({})
    const [partidas, setPartidas] = useState([])
    const navigate = useNavigate();
    const params = useParams();

    const abrirAccordion = () => {
        let accordion_items = document.querySelectorAll('.accordion-item>.title')
        for (let item of accordion_items) {
            item.click();
        }
    }

    const verDatelle = (e,item) => {
        e.preventDefault()
        console.log(item)
    }

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_solicitudes_detalle',
            method: 'POST',
            body: { id: params.id },
        }, (response) => {
            let datos = response.datos
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            console.log(datos)
            setSolicitud(datos.solicitud)
            setPartidas(datos.partidas)
        })

        abrirAccordion()
    }, [])

    return (
        <div id="divToPrint">
            <LoaderSeccion status={solicitud}/>
            <Fragment
            >
                <div className="col-sm-12 mb-1 mt-3">
                    <Breadcrumb icon={Clipboard} title={'Solicitudes'} parent={['solicitudes', params.id]} />
                </div>

                <div className="col-md-12 d-flex align-items-end justify-content-start mb-3 ml-0 p-0">
                    <button type="button" className="btn btn-primary">
                        <i className="fa fa-download"></i> Descargar
                    </button>
                </div>

                <div
                    className="default-according panel-accordion"
                    id="accordionclose"
                >
                    <Accordion atomic={false}>
                        <AccordionItem
                            eventKey="0"
                            className="card-header bg-primary b-0 m-0"
                            title={<div className="card-title-detalle">
                                {/* <i className="fa fa-usd"></i> */}
                                <h5 className=" font-weight-bold mb-0">
                                    Cliente
                                </h5>
                            </div>
                            }
                        >
                            <div
                                className="collapse show"
                                id="collapseicon2"
                                aria-labelledby="collapseicon2"
                                data-parent="#accordionoc"
                            >
                                <div className="card container-fluid">
                                    <br />
                                    <div className="row m-auto w-100">
                                        <div className="col-md-12 p-0">
                                            <form className="theme-form">
                                                <div className="row m-auto">
                                                    {/* <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Nombre completo: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                <div
                                                                    className={`alert alert-light p-2 m-0 rounded `}
                                                                    role="alert"
                                                                >
                                                                    {solicitud.NOMBRES_CONTACTO_CL?
                                                                    `${solicitud.NOMBRES_CONTACTO_CL} ${solicitud.APELLIDOS_CONTACTO_CL}`
                                                                    :'-'}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Nombre completo: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {solicitud.NOMBRES_CONTACTO_CL ?
                                                                    `${solicitud.NOMBRES_CONTACTO_CL} ${solicitud.APELLIDOS_CONTACTO_CL}`
                                                                    : '-'}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Celular: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {solicitud.CELULAR_CONTACTO_CL || '-'}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Correo: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {solicitud.CORREO_CL || '-'}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Fecha: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {solicitud.FECHA_ALTA_TEXT || '-'}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem
                            eventKey="0"
                            className="card-header bg-primary"
                            title={
                                <div className="card-title-detalle">
                                    {/* <i className="fa fa-usd"></i> */}
                                    <h5 className="ml-3 font-weight-bold mb-0">
                                        Datos de la solicitud
                                    </h5>
                                </div>
                            }
                        >
                            <div
                                className="collapse show"
                                id="collapseicon2"
                                aria-labelledby="collapseicon2"
                                data-parent="#accordionoc"
                            >
                                <div className="card container-fluid">
                                    <br />
                                    <div className="row m-auto w-100">
                                        <div className="col-md-12 p-0">
                                            <form className="theme-form">
                                                <div className="row m-auto">
                                                    <div className="col-md-6">
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                        Fecha registro: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        {solicitud.FECHA_PAGO_TEXT || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                        Estatus solicitud: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        <div className="alert alert-light m-0 p-1" role="alert">
                                                                            {solicitud.ESTATUS_SOLICITUD_TEXT || '-'}
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                        Estatus pago: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        <div className="alert alert-light m-0 p-1" role="alert">
                                                                            {solicitud.ESTATUS_PAGO_TEXT || '-'}
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                        Estatus factura: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        <div className="alert alert-light m-0 p-1" role="alert">
                                                                            {solicitud.ESTATUS_CFDI_TEXT || '-'}
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold" style={{ width: 100 }}>
                                                                        Subtotal: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        ${solicitud.SUBTOTAL_TEXT || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold" style={{ width: 100 }}>
                                                                        Descuento: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        ${solicitud.DESCUENTO_TEXT || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold" style={{ width: 100 }}>
                                                                        Iva: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        ${solicitud.IVA_TEXT || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold" style={{ width: 100 }}>
                                                                        Total: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        ${solicitud.TOTAL_TEXT || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Tipo de pago: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                <div
                                                                    className={`alert alert-light p-2 m-0 rounded `}
                                                                    role="alert"
                                                                >
                                                                    {registro.informacion.PASARELA_PAGO || '-'}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Id. de transacción: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {registro.informacion.TRANSACCION || '-'}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Código de descuento: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Importe de descuento: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Monto: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Estatus del pago: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                <div
                                                                    className={`alert alert-light p-2 m-0 rounded`}
                                                                    role="alert"
                                                                >
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Fecha y hora del pago: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Concepto del pago: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Comprobante de pago: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                <button type="button" className="btn btn-primary">
                                                                    <i className="fa fa-download"></i> Descargar
                                                                </button>
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem
                            eventKey="0"
                            className="card-header bg-primary b-0 m-0"
                            title={<div className="card-title-detalle">
                                <h5 className=" font-weight-bold mb-0">Partidas</h5>
                            </div>
                            }
                        >
                            <div
                                className="collapse show"
                                id="collapseicon2"
                                aria-labelledby="collapseicon2"
                                data-parent="#accordionoc"
                            >
                                <div className="card container-fluid">
                                    <br />
                                    <div className="row m-auto w-100">
                                        <div className="col-md-12 p-0">
                                            <div className="row m-auto w-100">
                                                <div className="col-md-12 p-0">
                                                    <form className="theme-form">
                                                        <div className="info-container">
                                                            <div className="list-group">
                                                                {partidas.map((v, i) =>
                                                                    <span key={i} className="list-group-item list-group-item-action flex-column align-items-start">
                                                                        <div className="d-flex w-100 justify-content-between">
                                                                            <h5 className="mb-1">{v.DESCRIPCION_PROD_SERV_SP}</h5>
                                                                            {/* <a href='#' className="link-primary" onClick={(e) => verDatelle(e,v)}>Ver detalle</a> */}
                                                                        </div>
                                                                        <div className="d-flex w-100 justify-content-between">
                                                                            <h6 className="mb-1">Cant. {v.CANTIDAD_SP}</h6>
                                                                            <h5 className="mb-1">${v.TOTAL_TEXT}</h5>
                                                                        </div>
                                                                        {v.TIPO_SERVICIO_SP == 1 ?
                                                                            <div className='row'>
                                                                                <div className="col-md-6">
                                                                                    <p className="font-weight-bold mb-0 pb-0" style={{ fontWeight: 600 }}>Origen</p>
                                                                                    <p className="font-weight-normal mb-0 pb-0">
                                                                                        {v.OR_DIRECCION}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <p className="font-weight-bold mb-0 pb-0" style={{ fontWeight: 600 }}>Destino</p>
                                                                                    <p className="font-weight-normal mb-0 pb-0">{v.DES_DIRECCION}</p>
                                                                                </div>
                                                                            </div> : ''}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </AccordionItem>
                    </Accordion>
                    <div style={{ height: 50 }}></div>
                </div>
            </Fragment>
        </div>
    )
}

export default Default;