import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppLayout from '../components/app';
import Login from '../components_gpack/login/login';
import LoginVerificar from '../components_gpack/login/login_verificacion';
import { routes } from './layouts-routes';

const MainRoutes = () => {
  return (
    <>
      <Routes>
      {/* element={<PrivateRoute />} */}
      {/* Rutas con sesion */}
        <Route exact path='/'  >
          {routes.map(({ path, Component }, i) => (
            <Route element={<AppLayout />} key={i}>
              <Route exact path={path} element={Component} />
            </Route>
          ))}
        </Route>
        {/* Rutas sin sesión */}
        <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
        <Route exact path={`${process.env.PUBLIC_URL}/verificacion/:id`} element={<LoginVerificar />} />
      </Routes>
    </>
  );
};

export default MainRoutes;