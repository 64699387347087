import React, { Fragment, useEffect } from 'react';
import { ChevronRight, ChevronLeft, Mail } from 'react-feather'
import Tools from '../../../js/tools'
import notie from 'notie'
import Select from '../../common/select-basic'

const Default = ({
    state,
    setState,
    catalogos,
    formDF,
    setFormDF,
    setCatalogos,
    disabledInputs
}) => {
    const onSelectEstado = ({ item }) => {
        Tools.toolFetch({
            ruta: 'rt_ctg_cd',
            method: 'POST',
            body: item
        }, (response) => {
            setCatalogos((s) => ({
                ...s,
                ctgDfCd: response.datos
            }))
        })
    }

    const validarForm = () => {
        let _formDF = guardarForm()
        
        if(_formDF.formIsEmpty){
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_formDF.stringIsEmpty})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        setState((s) => ({
            ...s,
            siguienteFormulario: 4
        }))
    }

    const guardarForm = (omitir = false) => {
        const _formDF = Tools.getForm('formDF', {claveEtiquetas: '.txtVacios', ignore: ['txt_num_int_rs']})   
        setFormDF({omitir, ..._formDF.data})
        return _formDF
    }

    useEffect(() => {
        if(!setFormDF){
            return
        }

        if(setFormDF.list_estado){
            onSelectEstado({clave: setFormDF.list_estado})
        }
    }, [])

    return (
        <Fragment>
            <div className='col-12'>
                <div className="container-fluid card">
                    <div className='row'>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className="card-header d-flex justify-content-center">
                                <h4><strong>NUEVOS DATOS FISCALES</strong></h4>
                            </div>
                        </div>
                        <form id='formDF' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className={`form theme-form needs-validation tooltip-validation form-row`}>
                                <div className="col-md-6 card-body">
                                    <div className='row'>
                                        <div className="col-md-12 mb-3">
                                            <h6 className="mb-3"><strong>DATOS FISCALES</strong></h6>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="validationTooltip02">Razón Social<span className="text-danger">*</span></label>
                                            <div className="input-group input-gpack2">
                                                <div className="input-group-prepend"></div>
                                                <input className="form-control" 
                                                    autoComplete='off'
                                                    id="txt_rs" name="txt_rs" 
                                                    type="text" 
                                                    placeholder="Razón Social"
                                                    defaultValue={formDF ? formDF.txt_rs : ''}
                                                    disabled={disabledInputs ? disabledInputs : false} 
                                                />
                                            </div>
                                            <span className="text-danger txtVacios txt_rs" style={{display: 'none'}}><strong>Falta completar (Razón Social)</strong></span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="validationTooltip02">R.F.C<span className="text-danger">*</span></label>
                                            <div className="input-group input-gpack2">
                                                <div className="input-group-prepend"></div>
                                                <input className="form-control" 
                                                    autoComplete='off'
                                                    id="txt_rfc" name="txt_rfc" 
                                                    type="text" 
                                                    placeholder="R.F.C"
                                                    defaultValue={formDF ? formDF.txt_rfc : ''}
                                                    disabled={disabledInputs ? disabledInputs : false} />
                                            </div>
                                            <span className="text-danger txtVacios txt_rfc" style={{display: 'none'}}><strong>Falta completar (R.F.C)</strong></span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Regimen Fiscal<span className="text-danger">*</span></label>
                                            <div className="input-group input-gpack2">
                                                <Select
                                                disabled={disabledInputs ? disabledInputs : false}
                                                className="form-control form-control-md border border-dark"
                                                data-label="Regimen Fiscal"
                                                placeholder="Seleccionar Regimen Fiscal"
                                                id="list_regimen_fiscal"
                                                name="list_regimen_fiscal"
                                                value={formDF.list_regimen_fiscal}
                                                datos={catalogos.ctgDfRegimenFiscal}
                                                onChange={() => null}
                                            />
                                            </div>
                                            <span className="text-danger txtVacios list_regimen_fiscal" style={{display: 'none'}}><strong>Falta completar (Regimen Fiscal)</strong></span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Uso CFDI<span className="text-danger">*</span></label>
                                            <div className="input-group input-gpack2">
                                                <Select
                                                disabled={disabledInputs ? disabledInputs : false}
                                                className="form-control form-control-md border border-dark"
                                                data-label="Uso CFDI"
                                                placeholder="Seleccionar Uso CFDI"
                                                id="list_uso_cfdi"
                                                name="list_uso_cfdi"
                                                value={formDF.list_uso_cfdi}
                                                datos={catalogos.ctgDfUsoCfi}
                                                onChange={() => null}
                                            />
                                            </div>
                                            <span className="text-danger txtVacios list_uso_cfdi" style={{display: 'none'}}><strong>Falta completar (Uso CFDI)</strong></span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Correo<span className="text-danger">*</span></label>
                                            <div className="input-group input-gpack2">
                                                {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><Mail /></span></div> */}
                                                <input id='correo_rs' name='correo_rs' 
                                                    autoComplete='off'
                                                    className="form-control" type="text" placeholder="Correo"
                                                    defaultValue={formDF ? formDF.correo_rs : ''}
                                                    disabled={disabledInputs ? disabledInputs : false} />
                                            </div>
                                            <span className="text-danger txtVacios correo_rs" style={{display: 'none'}}><strong>Falta completar (Correo)</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`form theme-form needs-validation tooltip-validation form-row`}>
                                <div className="col-md-6 mb-6 card-body">
                                    <div className='row'>
                                        <div className="col-md-12 mb-3">
                                            <h6 className="mb-3"><strong>DIRECCIÓN FISCAL</strong></h6>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Estado<span className="text-danger">*</span></label>
                                            <div className="input-group input-gpack2">
                                                <Select
                                                disabled={disabledInputs ? disabledInputs : false}
                                                className="form-control form-control-md border border-dark"
                                                data-label="Estado"
                                                placeholder="Seleccionar estado"
                                                id="list_estado"
                                                name="list_estado"
                                                value={formDF.list_estado}
                                                datos={catalogos.ctgEdo}
                                                onChange={onSelectEstado}
                                            />
                                            </div>
                                            <span className="text-danger txtVacios list_estado" style={{display: 'none'}}><strong>Falta sleccionar (Estado)</strong></span>
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-3">
                                            <div className="form-group">
                                                <label className="form-label">Ciudad<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <Select
                                                    disabled={disabledInputs ? disabledInputs : false}
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Ciudad"
                                                    placeholder="Seleccionar ciudad"
                                                    id="list_ciudad"
                                                    name="list_ciudad"
                                                    value={formDF.list_ciudad}
                                                    datos={catalogos.ctgDfCd}
                                                    onChange={({ item }) => {
                                                        setFormDF((s) => ({
                                                            ...s,
                                                            list_ciudad: item.clave
                                                        }))
                                                    }}
                                                />
                                                </div>
                                                <span className="text-danger txtVacios list_ciudad" style={{display: 'none'}}><strong>Falta sleccionar (Ciudad)</strong></span>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Colonia<span className="text-danger">*</span></label>
                                            <div className="input-group input-gpack2">
                                                <div className="input-group-prepend"></div>
                                                <input id='txt_colonia_rs' name='txt_colonia_rs' 
                                                    autoComplete='off'
                                                    className="form-control" type="text" placeholder="Colonia"
                                                    defaultValue={formDF ? formDF.txt_colonia_rs : ''}
                                                    disabled={disabledInputs ? disabledInputs : false} />
                                            </div>
                                            <span className="text-danger txtVacios txt_colonia_rs" style={{display: 'none'}}><strong>Falta completar (Código Postal)</strong></span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Calle<span className="text-danger">*</span></label>
                                            <div className="input-group input-gpack2">
                                                <div className="input-group-prepend"></div>
                                                <input id='txt_calle_rs' name='txt_calle_rs' 
                                                    autoComplete='off'
                                                    className="form-control" type="text" placeholder="Calle"
                                                    defaultValue={formDF ? formDF.txt_calle_rs : ''}
                                                    disabled={disabledInputs ? disabledInputs : false} />
                                            </div>
                                            <span className="text-danger txtVacios txt_calle_rs" style={{display: 'none'}}><strong>Falta completar (Calle)</strong></span>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label className="form-label">Número. Ext<span className="text-danger">*</span></label>
                                            <div className="input-group input-gpack2">
                                                <div className="input-group-prepend"></div>
                                                <input id='txt_num_ext_rs' name='txt_num_ext_rs' 
                                                    autoComplete='off'
                                                    className="form-control" type="text" placeholder="Numero Exterior"
                                                    defaultValue={formDF ? formDF.txt_num_ext_rs : ''}
                                                    disabled={disabledInputs ? disabledInputs : false} />
                                            </div>
                                            <span className="text-danger txtVacios txt_num_ext_rs" style={{display: 'none'}}><strong>Falta completar (Número. Ext)</strong></span>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label className="form-label">Número. Int</label>
                                            <div className="input-group input-gpack2">
                                                <div className="input-group-prepend"></div>
                                                <input id='txt_num_int_rs' name='txt_num_int_rs' 
                                                    autoComplete='off'
                                                    className="form-control" type="text" placeholder="Numero Interior"
                                                    defaultValue={formDF ? formDF.txt_num_int_rs : ''}
                                                    disabled={disabledInputs ? disabledInputs : false} />
                                            </div>
                                            <span className="text-danger txtVacios txt_num_int_rs" style={{display: 'none'}}><strong>Falta completar (Número. Int)</strong></span>
                                        </div>
                                        <div className="col-sm-12 col-md-2 mb-3">
                                            <div className="form-group m-0">
                                                <label className="form-label">C.P<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <input id="txt_cp" name='txt_cp' 
                                                    autoComplete='off'
                                                    className="form-control" placeholder="Código postal" type="number"
                                                    defaultValue={formDF ? formDF.txt_cp : ''}
                                                    disabled={disabledInputs ? disabledInputs : false} />
                                                </div>
                                            </div>
                                            <span className="text-danger txtVacios txt_cp" style={{display: 'none'}}><strong>Falta completar (C.P)</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {disabledInputs === undefined ?
            <div className='col-12'>
                <div className="card">
                    <div className='card-footer'>
                        <div className='row'>
                            <div className="col-md-6">
                                <div className="text-left">
                                    <button className="btn btn-back d-flex align-items-center" type="button"
                                        onClick={(e) => {
                                            guardarForm()
                                            setState((s) => ({...s, siguienteFormulario: 2}))
                                        }} ><ChevronLeft /> Atrás</button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="justify-content-end d-flex">
                                    <button className="btn btn-omitir" type="button"
                                        onClick={() => {
                                            guardarForm(true)
                                            setState((s) => ({...s, siguienteFormulario: 4}))
                                        }} 
                                        style={{ marginRight: 10 }}>Omitir</button>

                                    <button className="btn btn-purple d-flex align-items-center" type="button"
                                        onClick={(e) => {
                                            validarForm()
                                        }} >Siguiente <ChevronRight /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            : ''}
        </Fragment>
    )
}

export default Default