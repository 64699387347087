import React from 'react'
import Select, { components } from 'react-select'

const Option = props => {
	return (
		<components.Option {...props}>
			{props.label?<div>{props.data.label}</div>:''}
			{props.subLabel?<div style={{ fontSize: 12 }}>{props.data.subLabel}</div>:''}
		</components.Option>
	);
};

const Default = ({
	className,
	id,
	name,
	defaultValue,
	value,
	options,
	onChange,
	disabled,
	isMulti
}) => { 
    return (
	<Select
		className={className?className:''}
		id={id}
		name={name}
		isMulti={isMulti || false}
		defaultValue={defaultValue}
		value={value}
		options={options}
		onChange={onChange?onChange:''}
		components={{ Option }}
		placeholder={'Selecciona una opción de la lista...'} 
		isDisabled={disabled?disabled:false}
	/>
)}

export default Default