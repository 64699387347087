import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Box } from 'react-feather';

const Default = (props) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const navigate = useNavigate()

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_guias_prepagadas_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Box} title={'Guías prepagadas'} parent={['guias-prepagadas']} />
            <div className="card">
                <div className="col-xl-12 col-md-12">
                    {/* <div className="card-header d-flex justify-content-center p-3">
                        <h4>Productos Servicios</h4>
                    </div> */}
                    <div className="mt-3">
                        <Table
                            noRutaDinamica={true}
                            cargando={false}
                            htmlBtnPag={
                                <button
                                    className="btn btn-purple btn-nuevo"
                                    type="button"
                                    onClick={() => {navigate('/guias-prepagadas/nuevo')}}
                                > Nuevo paquete
                                </button>
                            }
                            headers={[
                                {
                                    nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                        return <div style={{ width: '10em' }}>
                                            <img src={'/pro-serv-img/guias/'+d.ID_GPP+'/'+d.IMG_PORTADA_GPP} width={100}/>
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_GPP', html: (d) => {
                                        return <div style={{ width: '20em' }}>
                                            {d.NOMBRE_GPP + ' PZ'} 
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'PESO MÁXIMO', tipo: 'html', texto: 'PESO MÁXIMO', html: (d) => {
                                        if(d.PESO_MAXIMO_GPP){
                                            return <div style={{ width: '15em' }}>
                                                {d.PESO_MAXIMO_GPP} kg
                                            </div>
                                        }else{
                                            return <div style={{ width: '15em' }}>
                                            </div>
                                        }
                                    }
                                },
                                {
                                    nombre: 'TIPO DE ENVÍO', tipo: 'html', texto: 'TIPO DE ENVÍO', html: (d) => {
                                        return <div style={{ width: '15em' }}>
                                            {d.NOMBRE_PS}
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'TIPO DE SERVICIO', tipo: 'html', texto: 'TIPO DE SERVICIO', html: (d) => {
                                        return <div style={{ width: '15em' }}>
                                            {d.NOMBRE_TP}
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'GRUPO', tipo: 'html', texto: 'GRUPO', html: (d) => {
                                        return <div style={{ width: '12em' }}>
                                            {d.GRUPO}
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'DETALLE', tipo: 'html', texto: 'DETALLE',
                                    html: (d) => {
                                        return <div>
                                            <BtnVer
                                                onClick={() => {
                                                    navigate(`/guias-prepagadas/${Tools.urlFormat(d.NOMBRE_GPP)}`)
                                                }}
                                            />
                                            <BtnEliminar
                                                onClick={() => {
                                                    notie.confirm({
                                                        text: `Se eliminara <strong>${d.NOMBRE_GPP}</strong> ¿estas de acuerdo?`,
                                                        submitText: 'Si, eliminar',
                                                        cancelText: 'No, Cancelar',
                                                        cancelCallback: function (value) {
                                                        },
                                                        submitCallback: function (value) {
                                                            eliminar({ id_gpp: d.ID_GPP })
                                                        },
                                                    })
                                                }}
                                            />
                                        </div>
                                    }
                                }
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'/rt_guias_prepagadas'}
                            refresh={esRefresh}
                            body={{}}
                            rowsPerPage={20}
                            paginadorTop={true}
                        >
                        </Table>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Default;