import React, {useEffect, useState} from "react";
import Swal from 'sweetalert2'

const EnviosRastreo = () => {
    const [lista, setLista] = useState([])
    const [nombre, setNombre] = useState('')
    const [carga, setCarga] = useState([]);
    const [indexCarga, setIndexCarga] = useState([]);
    const [editValues, setEditValues] = useState({});
    
    const cargar = async () => {
        const response = await fetch('/rt_traer_nombres', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify()
        });
        const data = await response.json();
        setCarga(data.datos.map(x => x.nombre));
        setIndexCarga(data.datos.map(x => x.index));
    };

    useEffect(() => {
        cargar();
    }, [lista]);


    const leerNombre = (e) => {
        setNombre(e.target.value)
    }

    const leerNombreEditado = (e, index) => {
        setEditValues({ ...editValues, [index]: e.target.value });
    };

    const eliminarNombre = (index) => {  
        console.log(12, index)
        fetch('/rt_eliminar_nombre', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({index: index})
        }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            cargar();
        })
    }

    const agregarNombre = (nombre) => {
        if(nombre !== ''){
            setLista([...lista, nombre])
            document.getElementById(`name-input`).style.border = 'none'
            document.getElementById(`add-button`).setAttribute('disabled', 'disabled')
            fetch('/rt_agregar_nombre', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({nombre: nombre})
            }).then((response) => response.json())
            .then(() => setNombre(''))
            .then(() => document.getElementById(`add-button`).removeAttribute('disabled'))
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Nombre no válido',
                text: 'Es necesario ingresar un nombre para continuar.',
                showConfirmButton: true,
                confirmButtonClass: 'btn btn-dark',
                confirmButtonColor: '#2a3142',
            }).then((isConfirmed) => {
                if(isConfirmed){
                    document.getElementById(`name-input`).style.border = '1px solid red'
                }
            })
        }
    }

    const editarNombre = (index, item) => {
        if(item != ''){
            setEditValues({ ...editValues, [index]: item });
        }
        document.getElementById(`edit-name-${index}`).setAttribute('hidden', 'hidden')
        document.getElementById(`save-edit-name-${index}`).removeAttribute('hidden')
        document.getElementById(`name-input-${index}`).removeAttribute('disabled')
        document.getElementById(`name-input-${index}`).removeAttribute('value')
        document.getElementById(`name-input-${index}`).focus()

    }

    const guardarEditarNombre = (index) => {
        const nuevoNombre = editValues[index];
        if(nuevoNombre !== ''){
            document.getElementById(`save-edit-name-${index}`).setAttribute('hidden', 'hidden')
            document.getElementById(`edit-name-${index}`).removeAttribute('hidden')
            document.getElementById(`name-input-${index}`).setAttribute('disabled', 'disabled')
            document.getElementById(`name-input-${index}`).blur()
            document.getElementById(`name-input-${index}`).style.border = 'none'
            fetch('/rt_editar_nombre', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({index: index, nombre: nuevoNombre})
            }).then((response) => response.json())
            .then(() => {
                cargar();
            })
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Nombre no válido',
                text: 'Es necesario ingresar un nombre para continuar.',
                showConfirmButton: true,
                confirmButtonClass: 'btn btn-dark',
                confirmButtonColor: '#2a3142',
            }).then((isConfirmed) => {
                if(isConfirmed){
                    document.getElementById(`name-input-${index}`).style.border = '1px solid red'
                }
            })
        }
    }
    
    const arrIndex = JSON.parse(JSON.stringify(indexCarga));

    return (
        <div><br/>
            <h1>Lista de amigos</h1> <br/>
            <div className="d-flex" style={{gap: '1%'}}>
                <input className="form-control" id="name-input" style={{fontSize: '25px'}} placeholder="Ingresa un nombre..." value={nombre} onChange={leerNombre}></input>
                <button className="btn btn-dark" id="add-button" style={{fontSize: '20px'}} onClick={() => agregarNombre(nombre)}>Agregar</button><br/><br/>
            </div><br/><br/>
            <div>
                {carga.map((item, index) => 
                    <ul className="list-group" key={index}>
                        <li className="list-group-item d-flex" style={{fontSize: '25px'}}>
                            <input id={`name-input-${arrIndex[index]}`} value={editValues[arrIndex[index]] !== undefined ? editValues[arrIndex[index]] : item} style={{border: 'none', width: '75%', borderRadius: '5px'}} onChange={(e) => leerNombreEditado(e, arrIndex[index])} disabled></input>&ensp; 
                            <div className="" style={{marginLeft: 'auto'}}>
                                <button id={`edit-name-${arrIndex[index]}`} className="btn btn-dark" style={{fontSize: '18px'}} onClick={() => editarNombre(arrIndex[index], item)}>Editar</button> &ensp; 
                                <button id={`save-edit-name-${arrIndex[index]}`} className="btn btn-dark" style={{fontSize: '18px'}} onClick={() => guardarEditarNombre(arrIndex[index])} hidden>Guardar</button> &ensp; 
                                <button className="btn btn-danger" style={{fontSize: '18px'}} onClick={() => eliminarNombre(arrIndex[index])}>Eliminar</button> 
                            </div>
                        </li> <br/>
                    </ul>
                )}
            </div>
        </div>
    );
};



export default EnviosRastreo;