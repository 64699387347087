import React, { Fragment, useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom'
import Tools from '../../js/tools'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { MapPin } from 'react-feather';
import Select from '../common/select-basic'
import tools from '../../js/tools';
import CoberturaDefinida from './cobertura_definida'
import CoberturaPorDefinir from './cobertura_por_definir'

const Default = (props) => {
    const [esRefresh, setEsRefresh] = useState(false)

    useEffect(() => {
    }, [])

    return (
        <Fragment>
            <Breadcrumb icon={MapPin} title={'Cobertura'} parent={['definir-cobertura']} />
            <div className="card">
                <div className='row'>
                    <div className="col-md-12">
                        <CoberturaPorDefinir 
                            esRefresh={esRefresh}
                            setEsRefresh={setEsRefresh}
                        />
                    </div>
                    <div className="col-md-12 mt-5">
                        <CoberturaDefinida 
                            esRefresh={esRefresh}
                            setEsRefresh={setEsRefresh}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Default;