import React, { Fragment, useState, useEffect } from 'react';
import Select from '../../common/select-basic'
import Tools from '../../../js/tools'
import notie from 'notie'
import { Box } from 'react-feather';
import Breadcrumb from '../../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom';
import Dropzone from '../../common/dropzone';
import SelectFetch from '../../common/select-fetch';
import SelectSimple from '../../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../../common/tabla_estatica';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import LoaderSeccion from '../../../components/common/loader_secciones';

const Default = ({ esEditar }) => {
    const [vehiculo, setVehiculo] = useState(null)
    const [catalogos, setCatalogos] = useState([])
    const [refreshPrimeraCarga, setRefreshPrimeraCarga] = useState(true)
    const [isUpdating, setIsUpdating] = useState(false);
    const navigate = useNavigate()
    const params = useParams()

    const guardar = () => {
        setIsUpdating(true);

        const _vehiculo = Tools.getForm('formvehiculo', { claveEtiquetas: '.txtVacios', ignore: ['modelo', 'anio', 'txt_desc'] })

        if (_vehiculo.formIsEmpty) {

            let mensaje = `${_vehiculo.formIsEmpty ? _vehiculo.stringIsEmpty : ''}`
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${mensaje})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
            setIsUpdating(false);
            return
        }

        Tools.toolFetch({
            ruta: 'rt_verificar_drv',
            method: 'POST',
            body: {
                ..._vehiculo.data,
                id : 0,
                editar: 0,
            },
        }, (response) => {

            if (response.estatus == 1) {
                notie.confirm({
                    text: `Este conductor ya está asignado a otro vehículo. Si deseas asignarlo a este vehículo, se eliminará su vínculo con el vehículo actual. ¿Deseas continuar?`,
                    submitText: 'Sí, reasignar conductor',
                    cancelText: 'No, continuar editando',
                    submitCallback: function (value) {


                        Tools.toolFetch({
                            ruta: esEditar ? 'rt_drivin_vehiculo_editar' : 'rt_drivin_vehiculo_agregar',
                            method: 'POST',
                            body: {
                                ..._vehiculo.data,
                            },
                        }, (response) => {
                            console.log(response)
                            if (response.estatus === 1) {
                                notie.alert({
                                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                                    text: `El vehículo ha sido agregado con exito`,
                                    // stay: Boolean, // optional, default = false
                                    time: 3, // optional, default = 3, minimum = 1,
                                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']

                                })

                                setTimeout(() => {
                                    window.location.href = '/vehiculos'
                                }, 2000);
                            }
                            if (response.estatus === 0) {
                                return notie.alert({
                                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                                    text: `No se ha podido agregar el vehículo`,
                                    // stay: Boolean, // optional, default = false
                                    time: 3, // optional, default = 3, minimum = 1,
                                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                                })
                            }
                            if (response.estatus === 3) {
                                return notie.alert({
                                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                                    text: `Ya existe un vehículo con este nombre`,
                                    // stay: Boolean, // optional, default = false
                                    time: 3, // optional, default = 3, minimum = 1,
                                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                                })
                            }
                            if (response.estatus == 5) {
                                return notie.alert({
                                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                                    text: 'La hora de inicio debe ser menor a la hora de término',
                                    // stay: Boolean, // optional, default = false
                                    time: 3, // optional, default = 3, minimum = 1,
                                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                                })
                            }
                            setIsUpdating(false);


                        })

                    },
                    cancelCallback: function (value) {
                    setIsUpdating(false);
                    },
                })
            }

            if (response.estatus == 0) {

                Tools.toolFetch({
                    ruta: esEditar ? 'rt_drivin_vehiculo_editar' : 'rt_drivin_vehiculo_agregar',
                    method: 'POST',
                    body: {
                        ..._vehiculo.data,
                    },
                }, (response) => {
                    console.log(response)
                    if (response.estatus === 1) {
                        notie.alert({
                            type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `El vehículo ha sido agregado con exito`,
                            // stay: Boolean, // optional, default = false
                            time: 3, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']

                        })

                        setTimeout(() => {
                            window.location.href = '/vehiculos'
                        }, 2000);
                    }
                    if (response.estatus === 0) {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `No se ha podido agregar el vehículo`,
                            // stay: Boolean, // optional, default = false
                            time: 3, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }
                    if (response.estatus === 3) {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ya existe un vehículo con este nombre`,
                            // stay: Boolean, // optional, default = false
                            time: 3, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }
                    if (response.estatus == 5) {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: 'La hora de inicio debe ser menor a la hora de término',
                            // stay: Boolean, // optional, default = false
                            time: 3, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }
                    setIsUpdating(false);


                })
            }

        })


        // return



    }

    const actualizar = (id) => {
        setIsUpdating(true);

        const _vehiculo = Tools.getForm('formvehiculo', { claveEtiquetas: '.txtVacios', ignore: ['txt_telefono', 'txt_licencia', 'txt_desc', 'anio', 'modelo'] })

        if (_vehiculo.formIsEmpty) {
            setIsUpdating(false);

            let mensaje = `${_vehiculo.formIsEmpty ? _vehiculo.stringIsEmpty : ''}`
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${mensaje})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })

            return
        }

        Tools.toolFetch({
            ruta: 'rt_verificar_drv',
            method: 'POST',
            body: {
                ..._vehiculo.data,
                id,
                editar: 1,
            },
        }, (response) => {

            if (response.estatus == 1) {
                notie.confirm({
                    text: `Este conductor ya está asignado a otro vehículo. Si deseas asignarlo a este vehículo, se eliminará su vínculo con el vehículo actual. ¿Deseas continuar?`,
                    submitText: 'Sí, reasignar conductor',
                    cancelText: 'No, continuar editando',
                    submitCallback: function (value) {

                        Tools.toolFetch({
                            ruta: 'rt_drivin_vehiculo_editar',
                            method: 'POST',
                            body: {
                                ..._vehiculo.data,
                                id
                            },
                        }, (response) => {
                            console.log(response)
                            if (response.estatus === 1) {
                                notie.alert({
                                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                                    text: `El vehículo ha sido actualizado con exito`,
                                    // stay: Boolean, // optional, default = false
                                    time: 3, // optional, default = 3, minimum = 1,
                                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']

                                })
                            }
                            if (response.estatus === 'ERROR') {
                                return notie.alert({
                                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                                    // stay: Boolean, // optional, default = false
                                    time: 3, // optional, default = 3, minimum = 1,
                                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                                })
                            }

                            if (response.estatus == 0) {
                                return notie.alert({
                                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                                    text: 'No se pudo actualizar la información del vehículo. Por favor, inténtelo más tarde.',
                                    // stay: Boolean, // optional, default = false
                                    time: 3, // optional, default = 3, minimum = 1,
                                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                                })
                            }

                            if (response.estatus == 5) {
                                return notie.alert({
                                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                                    text: 'La hora de inicio debe ser menor a la hora de término',
                                    // stay: Boolean, // optional, default = false
                                    time: 3, // optional, default = 3, minimum = 1,
                                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                                })
                            }

                            setIsUpdating(false);
                        })

                    },
                    cancelCallback: function (value) {
                    setIsUpdating(false);
                    },
                })
            }

            if (response.estatus == 0) {
                Tools.toolFetch({
                    ruta: 'rt_drivin_vehiculo_editar',
                    method: 'POST',
                    body: {
                        ..._vehiculo.data,
                        id
                    },
                }, (response) => {
                    console.log(response)
                    if (response.estatus === 1) {
                        notie.alert({
                            type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `El vehículo ha sido actualizado con exito`,
                            // stay: Boolean, // optional, default = false
                            time: 3, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']

                        })
                    }
                    if (response.estatus === 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = false
                            time: 3, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }

                    if (response.estatus == 0) {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: 'No se pudo actualizar la información del vehículo. Por favor, inténtelo más tarde.',
                            // stay: Boolean, // optional, default = false
                            time: 3, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }

                    if (response.estatus == 5) {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: 'La hora de inicio debe ser menor a la hora de término',
                            // stay: Boolean, // optional, default = false
                            time: 3, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }

                    setIsUpdating(false);
                })
            }

        })


    }

    function validarNumeros(input) {
        input.target.value = input.target.value.replace(/[^0-9]/g, '');
    }

    useEffect(() => {
        let isSubscribed = true
        fetch('/rt_ctg_suc', {
            method: 'POST',
            headers: { "Content-Type": 'application/json' },
            body: JSON.stringify({})
        }).then((response) => response.json())
            .then(async (response) => {
                console.log('vehiculos', response)
                setCatalogos((s) => ({ ...s, ctgSuc: response.datos.ctgSuc }))
            })

        fetch('/rt_ctg_drv', {
            method: 'POST',
            headers: { "Content-Type": 'application/json' },
            body: JSON.stringify({})
        }).then((response) => response.json())
            .then(async (response) => {
                console.log('conductores', response)
                setCatalogos((s) => ({ ...s, ctgDrv: response.datos.ctgDrv }))
            })

        if (esEditar) {
            return Promise.all([
                fetch('/rt_drivin_vehiculo_detalle', {
                    method: 'POST',
                    headers: { "Content-Type": 'application/json' },
                    body: JSON.stringify({ vehiculo: params.id })
                }).then((response) => response.json())
                    .then(async (response) => {
                        console.log(response)
                        setVehiculo((s) => ({ ...s, ...response.datos.vehiculo }))
                    })


            ])
        }

        return () => isSubscribed = false
    }, [refreshPrimeraCarga])

    return (
        <Fragment>
            {esEditar ? <LoaderSeccion status={vehiculo} /> : ''}
            <Breadcrumb icon={Box} title={'Vehículos'} parent={['vehiculos', esEditar?params.id : 'nuevo']} />
            <div className="container-fluid">
                <div className='row'>
                    <form id='formvehiculo' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className="btn btn-regresar" type="button"
                                    onClick={(e) => {
                                        navigate('/vehiculos')
                                    }} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVO'} VEHÍCULO</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Datos generales del vehículo</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Nombre del vehículo<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control"
                                                autoComplete='off'
                                                id="nombre" name="nombre"
                                                type="text"
                                                maxLength={100}
                                                placeholder="Nombre del vehículo"
                                                defaultValue={vehiculo ? vehiculo.NOMBRE_VHC : ''}
                                                readOnly={esEditar ? true : false}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre del vehículo)</strong></span>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Carga máxima (Volumen)<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control"
                                                autoComplete='off'
                                                id="carga" name="carga"
                                                type="text"
                                                placeholder="Carga máxima"
                                                onChange={validarNumeros}
                                                maxLength={20}
                                                defaultValue={vehiculo ? vehiculo.CAPACIDAD_VHC : ''}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios carga" style={{ display: 'none' }}><strong>Falta completar (Carga máxima)</strong></span>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Sucursal<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Sucursal"
                                                placeholder="Seleccionar sucursal"
                                                id="sucursal"
                                                name="sucursal"
                                                tipoValue={1}
                                                datos={catalogos.ctgSuc}
                                                value={vehiculo ? vehiculo.ID_SUC_VHC : ''}
                                                onChange={(datos) => {
                                                    setVehiculo((s) => ({ ...s, ID_SUC_VHC: datos.item.clave }))
                                                }}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios sucursal" style={{ display: 'none' }}><strong>Falta completar (Sucursal)</strong></span>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Conductor<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Conductor"
                                                placeholder="Seleccionar conductor"
                                                id="conductor"
                                                name="conductor"
                                                tipoValue={1}
                                                datos={catalogos.ctgDrv}
                                                value={vehiculo ? vehiculo.ID_DRV_VHC : ''}
                                                onChange={(datos) => {
                                                    setVehiculo((s) => ({ ...s, ID_DRV_VHC: datos.item.clave }))
                                                }}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios conductor" style={{ display: 'none' }}><strong>Falta completar (Conductor)</strong></span>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Hora de inicio<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control digits"
                                                autoComplete='off'
                                                type="time"
                                                defaultValue={vehiculo ? vehiculo.HORA_INICIO_VHC : ''}
                                                placeholder='Hora de inicio'
                                                id="hora_inicio" name="hora_inicio"
                                            />
                                        </div>
                                        <span className="text-danger txtVacios hora_inicio" style={{ display: 'none' }}><strong>Falta completar (Hora de inicio)</strong></span>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Hora de término<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control digits"
                                                autoComplete='off'
                                                type="time"
                                                defaultValue={vehiculo ? vehiculo.HORA_FIN_VHC : ''}
                                                placeholder='Hora de término'
                                                id="hora_fin" name="hora_fin"
                                            />
                                        </div>
                                        <span className="text-danger txtVacios hora_fin" style={{ display: 'none' }}><strong>Falta completar (Hora de término)</strong></span>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Modelo</label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control"
                                                autoComplete='off'
                                                id="modelo" name="modelo"
                                                type="text"
                                                maxLength={100}
                                                placeholder="Modelo"
                                                defaultValue={vehiculo ? vehiculo.MODELO_VHC : ''}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios modelo" style={{ display: 'none' }}><strong>Falta completar (Modelo)</strong></span>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Año</label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control"
                                                id="anio" name="anio"
                                                placeholder='Año'
                                                maxLength={4}
                                                onInput={(e) => Tools.formatoNumber(e)}
                                                defaultValue={vehiculo ? vehiculo.ANIO_VHC : ''}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios anio" style={{ display: 'none' }}><strong>Falta completar (Año)</strong></span>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">Descripción</label>
                                        <div className="input-group">
                                            <textarea style={{ width: '100%' }} rows={5} maxLength={255} id='txt_desc' name='txt_desc' placeholder='Descripción' defaultValue={vehiculo ? vehiculo.DESCRIPCION_VHC : ''}></textarea>
                                        </div>
                                        <span className="text-danger txtVacios txt_desc" style={{ display: 'none' }}><strong>Falta completar (Descripción)</strong></span>
                                    </div>

                                    {esEditar ?
                                        <div className="col-md-12 mb-3">
                                            <div className="justify-content-end d-flex">
                                                <button
                                                    className="btn btn-purple d-flex align-items-center"
                                                    type="button"
                                                    onClick={(e) => actualizar(vehiculo.ID_VHC)}
                                                    disabled={isUpdating}
                                                >
                                                    {/* Actualizar conductor */}
                                                    {isUpdating ? 'Editando...' : 'Editar vehículo'}
                                                </button>
                                            </div>
                                        </div>
                                        : ''}

                                    {!esEditar ? <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            <button
                                                className="btn btn-purple d-flex align-items-center"
                                                type="button"
                                                onClick={(e) => guardar()}
                                                disabled={isUpdating}
                                            >
                                                {/* Guardar vehículo */}
                                                {isUpdating ? 'Guardando...' : 'Guardar vehículo'}

                                            </button>
                                        </div>
                                    </div> : ''}

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default