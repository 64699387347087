// import React,{useEffect} from 'react'
import Select from 'react-select/async-creatable'
import {components} from 'react-select'
import Tools from '../../js/tools'
import { useEffect, useState } from 'react'

const Default = ({
    ruta,
    name,
    id,
    label,
    clave_cps,
    value,
    onChange,
    placeholder,
    isDisabled,
    onValueChange
}) => {
    const [_value, _setValue] = useState(value)
    const filter = (inputValue) =>{
        return new Promise((resolve) => {
            if(inputValue.length){
                clave_cps = ''
            }
            Tools.toolFetch({
                ruta,
                method: 'POST',
                body: {b: inputValue, clave_cps},
            }, (response) => {
                resolve(response.datos? response.datos: response.result? response.result:[])
            })
        });
    }

    useEffect(() => {
        _setValue(value)
    }, [value])

    return <Select 
        id={id?`react-${id}`:''}
        value={_value?_value:null}
        placeholder={placeholder?placeholder:'Seleccionar o buscar'}
        isDisabled={isDisabled?isDisabled:false}
        cacheOptions
        defaultOptions
        loadOptions={filter}
        onChange={(datos) => {
            _setValue(datos)

            if(onChange){
                onChange(datos)
            }
            if (onValueChange) {
                onValueChange(datos) // Llama a la nueva prop onValueChange
            }
        }}
        components={{
            Input: (props) => {
                let value = props.selectProps.value?props.selectProps.value.value:''
                let span = document.querySelector(`.${id}`)
                if(value && span){
                    span.style['display'] = 'none'
                }
                return <components.Input {...props} data-value={value} id={id} name={name} data-label={label}/>
            }
        }}
    />
}

export default Default