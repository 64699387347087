import React, { Fragment } from 'react';
import { Truck } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import Tabla from '../common/tabla';
import { useNavigate } from 'react-router-dom';
import tools from '../../js/tools';

const Default = (props) => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <Breadcrumb icon={Truck} title={'Sucursales'} parent={['sucursales']}/>

            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">

                    <Tabla 
                        cargando={false}
                        htmlBtnPag={
                            <button
                                className="btn btn-purple btn-nuevo"
                                type="button"
                                onClick={() => {navigate('/sucursales/nuevo')}}
                            > Nueva sucursal
                            </button>}
                        headers={[
                            // {nombre: 'ID', texto: 'ID'},
                            {nombre: 'NOMBRE', texto: 'NOMBRE'},
                            {nombre: 'ESTADO', texto: 'ESTADO'},
                            {nombre: 'CIUDAD', texto: 'CIUDAD'},
                            // { nombre: 'Fecha', tipo: 'individual', texto: 'FECHA_ALTA_RAC_TEXT' },
                            {
                                nombre: 'DETALLE', texto: 'DETALLE', tipo: 'html',
                                html: (d) => {
                                    return <div>
                                        <button
                                            className="btn btn-outline-success"
                                            type="button"
                                            onClick={() => {
                                                navigate(`/sucursales/${tools.urlFormat(d.NOMBRE)}`);
                                            }}
                                        > <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg></button>
                                    </div>
                                }
                            }
                        ]}
                        totalField={'NR'} /* NR = número de resultados totales */
                        path={'rt_sucursales_registros'}
                        // refresh={esRefresh}
                        body={{}}
                        rowsPerPage={10}
                        paginadorTop={true}
                    >
                    </Tabla>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;