import React, { Fragment, useState } from 'react';
import EnviosServicios from './envios_servicios'
import EnviosSolicitud from './envios_solicitud'

const Default = ({
    servicio,
    setServicio,
    envio, 
    setEnvio,
    selectCliente,
    setSelectCliente,
    onChangeCliente,
    // selectSuc,
    // setSelectSuc
}) => {
    // const [esRefresh, setEsRefresh] = useState(false)
    const [esRefreshEnvio, setEsRefreshEnvio] = useState(false)

    return (
    <Fragment>
        <div className='row'>
            <div className="col-xl-7 col-md-7">
                <EnviosServicios 
                    setEsRefreshEnvio={setEsRefreshEnvio}
                    servicio={servicio}
                    setServicio={setServicio}
                    envio={envio} 
                    setEnvio={setEnvio}
                    selectCliente={selectCliente}
                    setSelectCliente={setSelectCliente}
                    onChangeCliente={onChangeCliente}
                    // selectSuc={selectSuc} 
                    // setSelectSuc={setSelectSuc}
                />
            </div>
            <div className="col-xl-5 col-md-5">
                <EnviosSolicitud 
                    esRefreshEnvio={esRefreshEnvio}
                    setEsRefreshEnvio={setEsRefreshEnvio}
                    servicio={servicio}
                    setServicio={setServicio}
                    envio={envio} 
                    setEnvio={setEnvio}
                />
            </div>
        </div>    
    </Fragment>
    )
}

export default Default;