import React, { Fragment, useState } from 'react';
import Tools from '../../js/tools'
import Breadcrumb from '../common/breadcrumbv2';
import { Send } from 'react-feather';
import EnviosPTV from './envios_ptv'
import EnviosNuevos from './envios_nuevos'

const Default = (props) => {
    const [servicio, setServicio] = useState({seccion: Tools.local.getObjectJson('nuevoEnvio')?'servicio':'servicio-ptv', datos: {}})
    const [envio, setEnvio] = useState({})
    const [selectCliente, setSelectCliente] = useState(Tools.local.getObjectJson('selectCliente') || null)
    // const [selectSuc, setSelectSuc] = useState(null)
    const [catalogos, setCatalogos] = useState({})
    let idEditar = Tools.local.getObjectJson('idEditar')

    const onChangeCliente = (datos) => {
        if(!datos){
            return
        }

        Tools.toolFetch({
            ruta: 'rt_clientes_dir_contacto',
            method: 'POST',
            body: {idCl: datos.value}
        }, (response) => {
            setCatalogos((s) => ({...s, ...response.datos}))
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Send} title={'Envíos'} parent={['envios']} />
            <div className="card" >
                <div className="card-body">
                    <div className='row'>
                        <div className="col-xl-12">
                            {servicio.seccion === 'servicio-ptv'?
                            <EnviosPTV
                                servicio={servicio} 
                                setServicio={setServicio}
                                envio={envio} 
                                setEnvio={setEnvio}
                                selectCliente={selectCliente}
                                setSelectCliente={setSelectCliente}
                                onChangeCliente={onChangeCliente}
                                idEditar={idEditar}
                                // selectSuc={selectSuc} 
                                // setSelectSuc={setSelectSuc}
                            />
                            :''}
                            {servicio.seccion === 'servicio'?
                            <EnviosNuevos
                                servicio={servicio}
                                setServicio={setServicio}
                                selectCliente={selectCliente}
                                setSelectCliente={setSelectCliente}
                                onChangeCliente={onChangeCliente}
                                catalogos={catalogos} 
                                setCatalogos={setCatalogos}
                                idEditar={idEditar}
                            />
                            :''}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;