import React, { Fragment, useState, useEffect } from 'react';
import Tools from '../../js/tools'
import Table from '../common/tabla'
import notie from 'notie'
import Select from '../common/select-basic'
import tools from '../../js/tools';
import Filtros from '../common/dropdown_simple'
import { Sliders } from 'react-feather'
import { FilterRemove } from '../common/button-icons'
import SelectFetch from '../common/select-fetch';

const Default = ({
    setEsRefreshEnvio,
    servicio,
    setServicio,
    envio, 
    setEnvio,
    selectCliente,
    setSelectCliente,
    onChangeCliente,
    // selectSuc,
    // setSelectSuc
}) => {
    const [esRefreshLoc, setEsRefreshLoc] = useState(false)
    const [selectSuc, setSelectSuc] = useState({})
    const [filtros, setFiltros] = useState({ cp: '' })
    const [catalogos, setCatalogos] = useState({
        ctgEdo: [],
        ctgCd: []
    })
    let timeout = 0

    const limpiarFiltro = () => {
        setFiltros({})
        setEsRefreshLoc((s) => !s)
        setCatalogos((s) => ({ ...s, ctgCd: [] }))
    }

    const onSelectEstado = ({ item }) => {
        Tools.toolFetch({
            ruta: 'rt_ctg_cd',
            method: 'POST',
            body: item
        }, (response) => {
            document.getElementById('txtCp').value = ''
            setFiltros((s) => ({
                ...s,
                cp: null,
                list_cd: null,
                list_edo: item.clave
            }))
            setEsRefreshLoc((s) => !s)
            setCatalogos((s) => ({
                ...s,
                ctgCd: response.datos
            }))
        })
    }

    const onSelectCd = (datos) => {
        document.getElementById('txtCp').value = ''
        setFiltros((s) => ({ ...s, cp: null, list_cd: datos.item.clave }))
        setEsRefreshLoc((s) => !s)
    }

    const buscarCp = (cp) => {
        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(() => {
            setFiltros((s) => ({ cp }))
            setEsRefreshLoc((s) => !s)
        }, 500)
    }

    const onChangeSuc = (datos) => {
        let item = datos.item
        setEsRefreshLoc((s) => !s)
        setSelectSuc(item)
        setFiltros({idSuc: item.value})
        Tools.local.addObjectJson('selectSuc', item)
    }

    const onNuevoProducto = (d) => {
        let cantidad = 1
        let prodServ = envio?envio.find((v) => v.servicio.datos.ID_PS == d.ID_PS && v.servicio.seccion === 'producto'):null
        if(prodServ){
            cantidad = prodServ.precio.cantidad
            cantidad += 1
        }

        Tools.toolFetch({
            ruta: 'rt_envio_precios',
            method: 'POST',
            body: {
                idPs: d.ID_PS,
                listSuc: selectCliente.value,
                txtCantidad: cantidad,
                cobZona: 0,
                txtPesoReal: 0,
                txtPesoVol: 0
            }
        }, (response) => {
            let datos = response.datos
            if(response.estatus === 'NO-PRECIO'){
                return notie.alert({ type: 'warning', text: `No hay precios asignados para este servicio`})
            }

            let envio = Tools.local.getObjectJson('envio')
            // Actualiza registro si ya existe
            if(prodServ){
                envio = envio.map((v) => {
                    let servicio = v.servicio
                    let precio = v.precio
                    if(servicio.seccion === 'producto' && d.ID_PS === servicio.datos.ID_PS){
                        precio.cantidad = cantidad
                        precio.precio = datos.montos.PRECIO_PS
                        precio.txtSubtotal = datos.montos.SUBTOTAL
                        precio.txtIva = datos.montos.IVA
                        precio.txtTotal = datos.montos.TOTAL
                        v.precio = precio
                        return v
                    }else{
                        return v
                    }
                })

                Tools.local.addObjectJson('envio', envio)
                setEnvio(envio)
                setEsRefreshEnvio((s) => !s)
                return 
            }

            // Nuevo registro
            let item = {
                servicio: {seccion: 'producto', datos: d},
                selectCliente: selectCliente,
                precio: {
                    cantidad,
                    precio: datos.montos.PRECIO_PS,
                    txtSubtotal: datos.montos.SUBTOTAL,
                    txtIva: datos.montos.IVA,
                    txtTotal: datos.montos.TOTAL
                },
            }

            Tools.local.addItemObjectJson('envio', item)
            setEnvio(Tools.local.getObjectJson('envio'))
            setEsRefreshEnvio((s) => !s)
        })
    }

    useEffect(() => {
        tools.toolFetch({
            ruta: 'rt_ctg_edo',
            method: 'POST',
            body: {}
        }, (response) => {
            setCatalogos((s) => ({ ...s, ctgEdo: response.datos }))
        })

        tools.toolFetch({
            ruta: 'rt_ctg_gpo_cob',
            method: 'POST',
            body: {}
        }, (response) => {
            setCatalogos((s) => ({ ...s, ctgGpoCob: response.datos.ctgGpoCob }))
        })

        tools.toolFetch({
            ruta: 'rt_ctg_suc',
            method: 'POST',
            body: {}
        }, (response) => {
            setCatalogos((s) => ({ ...s, ctgSuc: response.datos.ctgSuc }))
        })

        let _selectCliente = Tools.local.getObjectJson('selectCliente')
        let _selectSuc = Tools.local.getObjectJson('selectSuc')

        if(_selectCliente){
            setSelectCliente(_selectCliente)
        }

        if(_selectSuc){
            setSelectSuc(_selectSuc)
            setFiltros({idSuc: _selectSuc.value})

        }

        let _envio = Tools.local.getObjectJson('envio')
        setEnvio(_envio)
    }, [])

    useEffect(() => {
        setEsRefreshLoc((s) => !s)
    }, [])

    return (
        <Fragment>
            <div className="">
                <div className='row d-flex justify-content-between align-items-center'>
                    <div className="col-md-6 mb-2">
                        <SelectFetch
                            id={'listClientes'}
                            name={'listClientes'}
                            ruta='rt_ctg_clientes'
                            label={'Producto Servicio'}
                            isDisabled={envio?envio.length?true:false:false}
                            value={selectCliente}
                            placeholder={'Seleccionar ó buscar cliente'}
                            onChange={(datos) => {
                                setSelectCliente(datos)
                                onChangeCliente(datos)
                            }}
                        />
                    </div>
                    <div className="col-md-6 mb-2">
                        <Select
                            className="form-control form-control-md border border-dark"
                            data-label="sucursal"
                            placeholder="Seleccionar sucursal"
                            id="listSuc"
                            name="listSuc"
                            datos={catalogos.ctgSuc}
                            tipoValue={1}
                            disabled={envio?envio.length?true:false:false}
                            value={selectSuc.clave}
                            onChange={onChangeSuc}
                        />
                    </div>
                    <div className="col-md-12 d-flex justify-content-end align-items-center filtros-productos">
                        <Fragment>
                            <FilterRemove
                                className={'m-1'}
                                style={{ cursor: 'pointer', fontSize: 14 }}
                                onClick={() => limpiarFiltro()}
                            />
                            <Filtros
                                title={<Fragment><Sliders size={14} /> Filtros</Fragment>}
                                items={[
                                    {
                                        element:
                                            <div style={{ width: 300 }}>
                                                <label className="form-label">Filtrar por estado</label>
                                                <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Estado"
                                                    placeholder="Seleccionar Estado"
                                                    id="list_edo"
                                                    name="list_edo"
                                                    tipoValue={1}
                                                    value={filtros.list_edo ? filtros.list_edo : ''}
                                                    datos={catalogos.ctgEdo}
                                                    onChange={onSelectEstado}
                                                />
                                            </div>
                                    },
                                    {
                                        element:
                                            <div>
                                                <label className="form-label">Filtrar por municipio</label>
                                                <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Municipio"
                                                    placeholder="Seleccionar Municipio"
                                                    id="list_cd"
                                                    name="list_cd"
                                                    tipoValue={1}
                                                    value={filtros.list_cd ? filtros.list_cd : ''}
                                                    datos={catalogos.ctgCd}
                                                    onChange={onSelectCd}
                                                />
                                            </div>
                                    },
                                    {
                                        element:
                                            <div style={{ width: 300 }}>
                                                <label htmlFor="buscar">
                                                    Filtro por código postal
                                                </label>
                                                <input
                                                    id="txtCp"
                                                    name="txtCp"
                                                    type="text"
                                                    className="form-control"
                                                    // value={filtros.cp?filtros.cp:''}                                    
                                                    onKeyUp={(e) => {
                                                        buscarCp(e.target.value)
                                                    }}
                                                />
                                            </div>
                                    }
                                ]}
                            />
                        </Fragment>
                    </div>
                </div>
            </div>
            <div className='productsboxes'>
                <Table
                    sinBuscador={true}
                    noRutaDinamica={true}
                    cargando={false}
                    row={{
                        ignoreColumns: [],
                        onClick: (e, row) => {
                            // console.log(row)
                        }
                    }}
                    headers={[
                        {
                            nombre: 'IMG_PORTADA_URL',
                            tipo: 'img',
                            texto: 'IMG_PORTADA_URL'
                        },
                        {
                            nombre: 'NOMBRE_PS',
                            tipo: 'html',
                            texto: 'NOMBRE_PS',
                            html: (d) => {
                                return <p key={'NOMBRE_PS'} className='pt-3'>
                                    <strong>{d.NOMBRE_PS}</strong><br/>
                                    {d.TIPO_PS == 2?<strong>${d.PRECIO_FORMAT}</strong>:''}
                                </p>
                            }
                        },
                    ]}
                    modo={'card'}
                    modoProps={{
                        onClick: (d) => {
                            if(!selectCliente){
                                return notie.alert({ type: 'warning', text: `Para continuar es necesario seleccionar un cliente`, time: 2 })
                            }

                            // Servicio
                            if(d.TIPO_PS === 1){
                                setServicio((s) => ({seccion: 'servicio', datos: d}))
                            }

                            // Producto
                            if(d.TIPO_PS === 2){
                                onNuevoProducto(d)
                            }
                        }
                    }}
                    totalField={'NR'} /* NR = número de resultados totales */
                    path={'/rt_envio_prod_serv'}
                    refresh={esRefreshLoc}
                    body={{ ...filtros }}
                    rowsPerPage={20}
                    paginadorTop={true}
                >
                </Table>
            </div>
        </Fragment>
    );
};

export default Default;