import React, { Fragment, useEffect } from 'react';
import { UserPlus } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import Tabla from '../common/tabla';
import { useNavigate } from 'react-router-dom';

const Default = (props) => {
    /* ==================== HOOKS ==================== */

    /* ==================== CONSTANTES ==================== */
    const navigate = useNavigate();

    /* ==================== USE EFFECT ==================== */
    useEffect(() => {

    }, [])
    /* ==================== FUNCIONES ==================== */

    return (
        <Fragment>
            <Breadcrumb icon={UserPlus} title={'Usuarios'} parent={['usuarios-tpv']} />
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">
                        <Tabla
                            cargando={false}
                            htmlBtnPag={
                                <button
                                    className="btn btn-purple"
                                    type="button"
                                    onClick={() => {
                                        navigate('/usuarios-tpv/nuevo')
                                    }}
                                > Nuevo usuario
                                </button>}
                            headers={[
                                { nombre: 'NOMBRE COMPLETO', texto: 'NOMBRE' },
                                { nombre: 'CORREO', texto: 'CORREO' },
                                { nombre: 'TELÉFONO', texto: 'TELEFONO' },
                                { nombre: 'REGISTRO', texto: 'ALTA' },
                                {
                                    nombre: 'DETALLE', texto: 'Detalle', tipo: 'html',
                                    html: (d) => {
                                        return <div>
                                            <button
                                                className="btn btn-outline-success"
                                                type="button"
                                                onClick={() => {
                                                    navigate(`/usuarios-tpv/detalle/${d.ID}`);
                                                }}
                                            > <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg></button>
                                        </div>
                                    }
                                }
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'rt_tpv_usuarios_registros'}
                            // refresh={esRefresh}
                            body={{}}
                            rowsPerPage={10}
                            paginadorTop={true}
                        >
                        </Tabla>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;