import React, { Fragment } from 'react';
import Select from '../../common/select-basic'
import Tools from '../../../js/tools'
import notie from 'notie'
import { Mail } from 'react-feather'

const Default = ({
    formCliente,
    catalogos,
    setCatalogos,
    formDF,
    setFormDF
}) => {
    const onSelectEstado = (item) => {
        Tools.toolFetch({
            ruta: 'rt_ctg_cd',
            method: 'POST',
            body: item
        }, (response) => {
            setCatalogos((s) => ({
                ...s,
                ctgDfCd: response.datos
            }))

            setFormDF((s) => ({
                ...s,
                ID_EDO_DF: item.clave
            }))
        })
    }

    const guardarDf = () => {
        const _formDF = Tools.getForm('formDF', {claveEtiquetas: '.txtVacios', ignore: ['txt_num_int_rs']})   

        if(_formDF.formIsEmpty){
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_formDF.stringIsEmpty})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        Tools.toolFetch({
            ruta: formDF.form === 'nuevo'? 'rt_clientes_df_nuevo': 'rt_clientes_df_editar',
            method: 'POST',
            body: {
                id_cl: formCliente.ID_CL,
                id_df: formDF.ID_DF,
                ..._formDF.data
            },
        }, (response) => {
            if(response.estatus === 'ERROR'){
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if(response.estatus === 'EXISTE'){
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Los datos fiscales ya existe intente con otro nombre`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro de datos fiscales realizado con exito.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })

            if(formDF.form === 'nuevo'){
                setFormDF((s) => ({...s, form: 'tabla'}))
            }
        })
    }

    return (
        <Fragment>
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formDF' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className="btn btn-regresar" type="button"
                                    onClick={(e) => setFormDF((s) => ({...s, form: 'tabla'}))} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <h4><strong>{formDF.form === 'editar'? 'EDITAR': 'NUEVOS'} DATOS FISCALES</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <div className='row'>
                                            <div className="col-md-12 mb-2">
                                                <h6><strong>DATOS FISCALES</strong></h6>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationTooltip02">Razón Social<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <div className="input-group-prepend"></div>
                                                    <input className="form-control" 
                                                        autoComplete='off'
                                                        id="txt_rs" name="txt_rs" 
                                                        type="text" 
                                                        placeholder="Razón Social"
                                                        defaultValue={formDF ? formDF.RAZON_SOCIAL : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_rs" style={{display: 'none'}}><strong>Falta completar (Razón Social)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationTooltip02">R.F.C<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <div className="input-group-prepend"></div>
                                                    <input className="form-control" 
                                                        autoComplete='off'
                                                        id="txt_rfc" name="txt_rfc" 
                                                        type="text" 
                                                        placeholder="R.F.C"
                                                        defaultValue={formDF ? formDF.RFC : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_rfc" style={{display: 'none'}}><strong>Falta completar (R.F.C)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Regimen Fiscal<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Regimen Fiscal"
                                                    placeholder="Seleccionar Regimen Fiscal"
                                                    id="list_regimen_fiscal"
                                                    name="list_regimen_fiscal"
                                                    value={formDF.REGIMEN_FISCAL_DF}
                                                    datos={catalogos.ctgDfRegimenFiscal}
                                                    onChange={() => null}
                                                />
                                                </div>
                                                <span className="text-danger txtVacios list_regimen_fiscal" style={{display: 'none'}}><strong>Falta completar (Regimen Fiscal)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Uso CFDI<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Uso CFDI"
                                                    placeholder="Seleccionar Uso CFDI"
                                                    id="list_uso_cfdi"
                                                    name="list_uso_cfdi"
                                                    value={formDF.USO_CFDI_DF}
                                                    datos={catalogos.ctgDfUsoCfi}
                                                    onChange={() => null}
                                                />
                                                </div>
                                                <span className="text-danger txtVacios list_uso_cfdi" style={{display: 'none'}}><strong>Falta completar (Uso CFDI)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Correo<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><Mail /></span></div> */}
                                                    <input id='correo_rs' name='correo_rs' 
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Correo"
                                                        defaultValue={formDF ? formDF.CORREO : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios correo_rs" style={{display: 'none'}}><strong>Falta completar (Correo)</strong></span>
                                            </div>
                                            <div className="col-md-12 mb-2 mt-5">
                                                <h6><strong>DIRECCIÓN FISCAL</strong></h6>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Estado<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Estado"
                                                    placeholder="Seleccionar estado"
                                                    id="list_estado"
                                                    name="list_estado"
                                                    value={formDF.ID_EDO_DF}
                                                    datos={catalogos.ctgEdo}
                                                    onChange={(datos) => {
                                                        onSelectEstado(datos.item)
                                                    }}
                                                />
                                                </div>
                                                <span className="text-danger txtVacios list_estado" style={{display: 'none'}}><strong>Falta sleccionar (Estado)</strong></span>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Ciudad<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Ciudad"
                                                        placeholder="Seleccionar ciudad"
                                                        id="list_ciudad"
                                                        name="list_ciudad"
                                                        value={formDF.ID_CD_DF}
                                                        datos={catalogos.ctgDfCd}
                                                        onChange={({ item }) => {
                                                            setFormDF((s) => ({
                                                                ...s,
                                                                ID_CD_DF: item.clave
                                                            }))
                                                        }}
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios list_ciudad" style={{display: 'none'}}><strong>Falta sleccionar (Ciudad)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Colonia<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <div className="input-group-prepend"></div>
                                                    <input id='txt_colonia_rs' name='txt_colonia_rs' 
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Colonia"
                                                        defaultValue={formDF ? formDF.COLONIA : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_colonia_rs" style={{display: 'none'}}><strong>Falta completar (Código Postal)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Calle<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <div className="input-group-prepend"></div>
                                                    <input id='txt_calle_rs' name='txt_calle_rs' 
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Calle"
                                                        defaultValue={formDF ? formDF.CALLE : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_calle_rs" style={{display: 'none'}}><strong>Falta completar (Calle)</strong></span>
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label className="form-label">Número. Ext<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <div className="input-group-prepend"></div>
                                                    <input id='txt_num_ext_rs' name='txt_num_ext_rs' 
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Numero Exterior"
                                                        defaultValue={formDF ? formDF.NUM_EXT : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_num_ext_rs" style={{display: 'none'}}><strong>Falta completar (Número. Ext)</strong></span>
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label className="form-label">Número. Int</label>
                                                <div className="input-group input-gpack2">
                                                    <div className="input-group-prepend"></div>
                                                    <input id='txt_num_int_rs' name='txt_num_int_rs' 
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Numero Interior"
                                                        defaultValue={formDF ? formDF.NUM_INT : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_num_int_rs" style={{display: 'none'}}><strong>Falta completar (Número. Int)</strong></span>
                                            </div>
                                            <div className="col-sm-12 col-md-2 mb-3">
                                                <div className="form-group m-0">
                                                    <label className="form-label">C.P<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <input id="txt_cp" name='txt_cp' 
                                                        autoComplete='off'
                                                        className="form-control" placeholder="Código postal" type="number"
                                                        defaultValue={formDF ? formDF.CP : ''}
                                                    />
                                                    </div>
                                                </div>
                                                <span className="text-danger txtVacios txt_cp" style={{display: 'none'}}><strong>Falta completar (C.P)</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className='card-footer'>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="text-left">
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="justify-content-end d-flex">
                                            <button className="btn btn-purple d-flex align-items-center" type="button"
                                                onClick={(e) => guardarDf()} >Guardar Fiscales</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default