import React, { Fragment, useEffect, useState } from 'react';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
// import RightSidebar from './common/right-sidebar';
import Footer from './common/footer';
// import ThemeCustomizer from './common/theme-customizer'
import { ToastContainer } from 'react-toastify';
import Loader from './common/loader';
import { Outlet } from 'react-router-dom';
import Tools from '../js/tools'

const AppLayout = (props) => {
    const [perm, setPerm] = useState([])
    
    useEffect(() => {
        let isS = true
        Tools.toolFetch({
            ruta: 'rt_perm',
            method: 'POST',
            body: {},
        }, (response) => {  
            if(isS){
                setPerm(response.datos)
            }
        })
        return () => isS = false
    }, [])

    return (
        <Fragment>
            <Loader perm={perm}/>
            <div className="page-wrapper">
                <div className="page-body-wrapper">
                    <Header />
                    <Sidebar perm={perm}/>
                    {/* <RightSidebar /> */}
                    <div className="page-body">
                        <Outlet />
                    </div>
                    <Footer />
                    {/* <ThemeCustomizer /> */}
                </div>
            </div>
            <ToastContainer />
        </Fragment>
    );
}

export default AppLayout;