import React, { Fragment } from 'react';
import Select from '../../common/select-basic'
import Tools from '../../../js/tools'
import notie from 'notie'

const Default = ({
    formCliente,
    catalogos,
    setCatalogos,
    formDireccion,
    setFormDireccion
}) => { 
    const onSelectEstado = (item) => {
        Tools.toolFetch({
            ruta: 'rt_ctg_cd',
            method: 'POST',
            body: item
        }, (response) => {
            setCatalogos((s) => ({
                ...s,
                ctgDirCd: response.datos
            }))

            setFormDireccion((s) => ({
                ...s,
                ID_EDO_DIR: item.clave
            }))
        })
    }

    const guardarDireccion = () => {
        const _formDireccion = Tools.getForm('formDireccion', {claveEtiquetas: '.txtVacios', ignore: ['txt_num_int', 'txt_referencia', 'txt_latitud', 'txt_longitud', 'cel_contacto']})   

        if(_formDireccion.formIsEmpty){
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_formDireccion.stringIsEmpty})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        Tools.toolFetch({
            ruta: formDireccion.form === 'nuevo'? 'rt_clientes_dir_nuevo': 'rt_clientes_dir_editar',
            method: 'POST',
            body: {
                id_cl: formCliente.ID_CL,
                id_dir: formDireccion.ID_DIR,
                ..._formDireccion.data
            },
        }, (response) => {
            if(response.estatus === 'ERROR'){
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if(response.estatus === 'EXISTE'){
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `La dirección de entrega ya existe intente con otro nombre`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro de dirección de entrega realizado con exito.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })

            if(formDireccion.form === 'nuevo'){
                setFormDireccion((s) => ({...s, form: 'tabla'}))
            }
        })
    }
    
    return (
        <Fragment>
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formDireccion' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className="btn btn-regresar" type="button"
                                    onClick={(e) => setFormDireccion((s) => ({...s, form: 'tabla'}))} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <h4><strong>{formDireccion.form === 'editar'? 'EDITAR': 'NUEVA'} DIRECCIÓN DE ENTREGA</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <div className='row'>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Alias de dirección<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <input
                                                        className="form-control" 
                                                        type="text"
                                                        autoComplete='off'
                                                        id="txt_alias"
                                                        name='txt_alias' 
                                                        placeholder="Alias de dirección (Casa, Empresa, etc)"
                                                        defaultValue={formDireccion ? formDireccion.ALIAS : ''}
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_alias" style={{display: 'none'}}><strong>Falta completar (Alias de dirección)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Estado<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Estado"
                                                        placeholder="Seleccionar estado"
                                                        id="list_estado"
                                                        name="list_estado"
                                                        value={formDireccion.ID_EDO_DIR}
                                                        datos={catalogos.ctgEdo}
                                                        onChange={(datos) => {
                                                            onSelectEstado(datos.item)
                                                        }}
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios list_estado" style={{display: 'none'}}><strong>Falta sleccionar (Estado)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Ciudad<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Ciudad"
                                                        placeholder="Seleccionar ciudad"
                                                        id="list_ciudad"
                                                        name="list_ciudad"
                                                        value={formDireccion.ID_CD_DIR}
                                                        datos={catalogos.ctgDirCd}
                                                        onChange={({ item }) => {
                                                            setFormDireccion((s) => ({
                                                                ...s,
                                                                ID_CD_DIR: item.clave
                                                            }))
                                                        }}
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios list_ciudad" style={{display: 'none'}}><strong>Falta sleccionar (Ciudad)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Calle<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <input 
                                                        id="txt_calle_numero" name='txt_calle_numero' 
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Calle"
                                                        defaultValue={formDireccion ? formDireccion.CALLE : ''}
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_calle_numero" style={{display: 'none'}}><strong>Falta sleccionar (Calle)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-2 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Número Ext.<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <input 
                                                        id="txt_num_ext" name='txt_num_ext' 
                                                        autoComplete='off'
                                                        className="form-control" type="text" 
                                                        placeholder="Número Ext"
                                                        defaultValue={formDireccion ? formDireccion.NUM_EXT_DIR : ''}
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_num_ext" style={{display: 'none'}}><strong>Falta sleccionar (Número Ext)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-2 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Número Int.</label>
                                                    <div className="input-group input-gpack2">
                                                        <input id="txt_num_int" name='txt_num_int' 
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Número Int"
                                                        defaultValue={formDireccion ? formDireccion.NUM_INT_DIR : ''}
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_num_int" style={{display: 'none'}}><strong>Falta sleccionar (Número Int)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-2 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">C.P<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <input id="txt_cp" name='txt_cp' 
                                                        autoComplete='off'
                                                        className="form-control" placeholder="Código postal" type="number"
                                                        defaultValue={formDireccion ? formDireccion.CP : ''}
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_cp" style={{display: 'none'}}><strong>Falta sleccionar (C.P)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Colonia<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <input id="txt_colonia" name='txt_colonia' 
                                                        autoComplete='off'
                                                        className="form-control" type="text" placeholder="Colonia"
                                                        defaultValue={formDireccion ? formDireccion.COLONIA : ''}
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_colonia" style={{display: 'none'}}><strong>Falta sleccionar (Colonia)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Referencia</label>
                                                    <div className="input-group input-gpack2">
                                                        <input id="txt_referencia" name='txt_referencia' 
                                                        autoComplete='off'
                                                        className="form-control" type="text" 
                                                        placeholder="Referencia"
                                                        defaultValue={formDireccion ? formDireccion.REFERENCIA_DIR : ''}
                                                    />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_referencia" style={{display: 'none'}}><strong>Falta sleccionar (Referencia)</strong></span>
                                                </div>
                                            </div>
                                        </div>
                                        <h6 className="mb-3 mt-5"><strong>DATOS DE GEOLOCALIZACIÓN</strong></h6>
                                        <div className='row'>
                                            <div className="col-sm-12 col-md-12">
                                                <div className='row'>
                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Latitud</label>
                                                            <div className="input-group input-gpack2">
                                                                <input id="txt_latitud" name='txt_latitud' 
                                                                autoComplete='off'
                                                                className="form-control" type="text" placeholder="Latitud"
                                                                defaultValue={formDireccion ? formDireccion.LATITUD : ''}
                                                            />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Longitud</label>
                                                            <div className="input-group input-gpack2">
                                                                <input id="txt_longitud" name='txt_longitud' 
                                                                autoComplete='off'
                                                                className="form-control" type="text" placeholder="Longitud"
                                                                defaultValue={formDireccion ? formDireccion.LONGITUD : ''}
                                                            />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className='card-footer'>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="text-left">
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="justify-content-end d-flex">
                                            <button className="btn btn-purple d-flex align-items-center" type="button"
                                                onClick={(e) => guardarDireccion()} >Guardar Dirección</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default