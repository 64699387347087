import React, { Fragment, useState } from 'react';
import {useNavigate} from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnPDF } from '../common/table_buttons'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Clipboard } from 'react-feather';
import Filtros from '../common/dropdown_simple';
import { FilterRemove } from '../common/button-icons';
import { Sliders } from 'react-feather';
import Select from '../common/select-basic';
import SelectFetch from '../common/select-fetch';

const Default = (props) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const navigate = useNavigate()

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_prod_serv_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            console.log(response)
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Clipboard} title={'Corte de caja'} parent={['corte de caja']} />
            
            <div className="card" >
                <div className="card-body">
                    <div className='row'>
                        
                        <div className="col-md-12 d-flex justify-content-end align-items-center filtros-productos">
                            <Fragment>
                                <FilterRemove
                                    className={'m-1'}
                                    style={{ cursor: 'pointer', fontSize: 14 }}
                                    //onClick={() => limpiarFiltro()}
                                />
                                <Filtros
                                    title={<Fragment>
                                        <Sliders size={14} /> Filtros</Fragment>}
                                    items={[
                                        {
                                            element:
                                                <div style={{ width: 300 }}>
                                                    <label className="form-label">Filtrar Sucursal</label>
                                                    <Select
                                                         className="form-control form-control-md border border-dark"
                                                         data-label="Sucursal"
                                                         placeholder="Seleccionar Sucursal"
                                                         id="list_edo"
                                                         name="list_edo"
                                                         tipoValue={1}
                                                    />
                                                </div>
                                        },
                                        {
                                            element:
                                                <div>
                                                    <label className="form-label">Filtrar por entrada</label>
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Entrada"
                                                        placeholder="Seleccionar Entrada"
                                                        id="list_cd"
                                                        name="list_cd"
                                                        tipoValue={1}
                                                    />
                                                </div>
                                        },
                                        {
                                            element:
                                                <div style={{ width: 300 }}>
                                                    <label className="form-label">Filtrar por salida</label>
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Salida"
                                                        placeholder="Seleccionar Salida"
                                                        id="list_cd"
                                                        name="list_cd"
                                                        tipoValue={1}
                                                    />
                                                </div>
                                        },
                                        {
                                            element:
                                                <div style={{ width: 300 }}>
                                                    <label htmlFor="buscar">
                                                        Filtro por cantidad
                                                    </label>
                                                    <input
                                                        id="txtCp"
                                                        name="txtCp"
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                        }
                                    ]}
                                />
                            </Fragment>
                        </div>
                        <div className="col-xl-12">
                        <Table
                            noRutaDinamica={true}
                            cargando={false}
                            headers={[
                                {
                                    nombre: 'ENTRADA', tipo: 'html', texto: 'ENTRADA_CL', html: (d) => {
                                        return <div>#{d.ENTRADA_SOL}</div>
                                    }
                                },
                                {
                                    nombre: 'SALIDA', tipo: 'html', texto: 'SALIDA_CL', html: (d) => {
                                        return <div>{d.SALIDA_CL}</div>
                                    }
                                },
                                {
                                    nombre: 'TOTAL VENTAS', tipo: 'html', texto: 'TOTAL_VENTAS_CL', html: (d) => {
                                        return <div>{d.TOTAL_VENTAS_CL}</div>
                                    }
                                },
                                {
                                    nombre: 'TOTAL CONTADO', tipo: 'html', texto: 'TOTAL_CONTADO_SOL', html: (d) => {
                                        return <div>${d.TOTAL_CONTADO_TEXT}</div>
                                    }
                                },
                                {
                                    nombre: 'TOTAL DIFERENCIA', tipo: 'html', texto: 'TOTAL_DIFERENCIA_TEXT', html: (d) => {
                                        return <div>{d.TOTAL_DIFERENCIA_TEXT}</div>
                                    }
                                },
                                {
                                    nombre: 'RESPONSABLE', tipo: 'html', texto: 'RESPONSABLE_TEXT', html: (d) => {
                                        return <div>{d.RESPONSABLE_TEXT}</div>
                                    }
                                },
                                {
                                    nombre: 'SUCURSAL', tipo: 'html', texto: 'SUCURSAL_TEXT', html: (d) => {
                                        return <div>{d.SUCURSAL_TEXT}</div>
                                    }
                                },
                                {
                                    nombre: 'REPORTE', tipo: 'html', texto: 'Reporte',
                                    html: (d) => {
                                        return <div>
                                        <BtnPDF
                                            
                                        />
                                        
                                        </div>
                                    }
                                }
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'/'}
                            refresh={esRefresh}
                            body={{}}
                            rowsPerPage={20}
                            paginadorTop={true}
                        >
                        </Table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;